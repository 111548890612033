import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hotel-room-summary',
  templateUrl: './hotel-room-summary.component.html',
  styleUrls: ['./hotel-room-summary.component.scss']
})
export class HotelRoomSummaryComponent implements OnInit {
  roomData
  constructor() { }

    /**
   * 
   * @param i 
   * @returns 
   */
     counter(i) {
      return new Array(parseInt(i));
    }
  

  ngOnInit(): void {
    this.roomData = JSON.parse(localStorage.getItem('selectedRoomsData'));
  }

}
