import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  StripeService,
  StripeCardComponent,
  StripePaymentElementComponent,
} from "ngx-stripe";
import { switchMap } from "rxjs/operators";
import {
  Appearance,
  loadStripe,
  PaymentIntent,
  StripeCardElementOptions,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiCjwService } from "src/app/core/services/apicjw.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { apiEndPointsCjw } from "src/app/core/helpers/api-endpoints-cjw";
import { NgxSpinnerService } from "ngx-spinner";
import { Location, LocationStrategy } from "@angular/common";
import Swal from "sweetalert2";
import { CountdownComponent } from "ngx-countdown";
import { ApiService } from "src/app/core/services/api.service";
import { apiEndPoints } from "src/app/core/helpers/api-endpoints";

@Component({
  selector: "app-hotel-payment",
  templateUrl: "./hotel-payment.component.html",
  styleUrls: ["./hotel-payment.component.scss"],
})
export class HotelPaymentComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  @ViewChild(StripePaymentElementComponent)
  paymentElement: StripePaymentElementComponent;
  bookingData = JSON.parse(localStorage.getItem("bookingData"));
  paymentElementForm = this.fb.group({});

  elementsOptions: StripeElementsOptions = {
    locale: "en",
  };

  paying = false;
  sub: Subscription;
  appearance: Appearance = {
    theme: "stripe",
    labels: "above",
    variables: {
      fontLineHeight: "3",
      colorPrimary: "#673ab7",
      borderRadius: "3px",
    },
  };

  // get amount(amount) {
  //   // const amount = 10;
  //   return Number(amount) / 100;
  // }

  stripeTest: FormGroup;
  subscription: Subscription[] = [];
  amount;
  rateRecheckResponse;
  loading = true;
  paymentSuccess = false;
  userDetails;
  isTimerStart = false;
  timeOutConfig = {
    leftTime: 900,
    format: "mm:ss",
    demand: true,
  };
  selectedPaymentMethod = 1;
  currentAvailableBalance = null;
  accountData = [];
  selectedAccount;
  totalPrice;
  currency;
  requestTransactionResponse;
  otpBox = false;
  timeLeft: number;
  interval;
  isSmsExpired = false;

  @Output() changeStepFunction = new EventEmitter<string>();
  @ViewChild("cd", { static: false }) private countdown: CountdownComponent;
  constructor(
    private fb: FormBuilder,
    // private stripeService: StripeService,
    private httpClient: HttpClient,
    private spinner: NgxSpinnerService,
    private apiServiceCjw: ApiCjwService,
    private router: Router,
    private toastr: ToastrService,
    private location: Location,
    private apiService: ApiService,

  ) {
    location.replaceState("hotel-bookings/hotel-payment");
  }

  ngOnInit(): void {
    this.stripeTest = this.fb.group({
      name: ["", [Validators.required]],
      amount: ["", [Validators.required]],
    });
    this.checkRateKeys();
    this.isTimerStart = true;
    this.bookingData = JSON.parse(localStorage.getItem("bookingData"));
    this.userDetails = JSON.parse(localStorage.getItem("user"));
  }

  //** notify when timeout*/
  handleEvent(event) {
    if (event.action === "done") {
      Swal.fire({
        allowOutsideClick: false,
        title: "Payment Session Expiry",
        text: "Sorry, your payment session has expired. Please try again.",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#07253f",
        // cancelButtonColor: '#f46a6a',
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.value) {
          location.reload();
        }
      });
    }
  }

  /**
   *
   */
  checkRateKeys() {
    this.spinner.show();
    var data = JSON.parse(localStorage.getItem("rateRecheckPayload"));
    this.subscription.push(
      this.apiServiceCjw
        .apiAuthPost(apiEndPointsCjw.rateRecheck, data)
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.code == 1) {
              localStorage.setItem(
                "rateRecheckResponse",
                JSON.stringify(res.response)
              );
              // this.amount = this.calculateAmount(res.response.totalPayable);
              this.amount =res.response.totalPayable;
              this.rateRecheckResponse = JSON.parse(
                localStorage.getItem("rateRecheckResponse")
              );
              this.changePaymentMethod(1);
              this.loading = false;
            } else {
              this.toastr.error(res.response.message, "Error");
            }
          },
          (error) => {}
        )
    );
  }

  /**
   * Calculate price to send stripe
   * @param amount
   * @returns
   */
  calculateAmount(amount) {
    let a = Number.parseFloat(amount) * 100;
    return a.toString();
  }

  public apiPostStripe(data, url): any {
    var header = {
      headers: new HttpHeaders()
        .set(
          "Authorization",
          `Bearer sk_test_51LN8weBEPirKdfbW6pXQXrgTLF4jBrJvA8wT49MFqSKTxPh2pOyHtGl8Qazwe9LenVEUwF8OgAwOVFtrU0U11cxH00qOFneMGJ`
        )
        .set("Content-Type", `application/x-www-form-urlencoded`),
    };
    return this.httpClient.post(url, JSON.stringify(data), header);
  }

  // pay(): void {
  //   // if (this.stripeTest.valid) {
  //   // this.createPaymentIntent(this.stripeTest.get('amount').value)
  //   //   .pipe(
  //   //     switchMap((pi) =>
  //   this.paying = true;
  //   this.stripeService
  //     .confirmPayment({
  //       elements: this.paymentElement.elements,
  //       confirmParams: {
  //         payment_method_data: {
  //           billing_details: {
  //             name: this.userDetails.name,
  //             email: this.userDetails.email,
  //             phone: this.userDetails.mobile_number,
  //           },
  //         },
  //       },
  //       redirect: "if_required",
  //     })
  //     //   )
  //     // )
  //     .subscribe((result) => {
  //       if (result.error) {
  //         this.paying = false;
  //         // Show error to your customer (e.g., insufficient funds)
  //         // console.log(result.error.message);
  //         this.toastr.error(result.error.message, "Error");
  //         // this.createReference(result.error.payment_intent.id);
  //       } else {
  //         // The payment has been processed!
  //         if (result.paymentIntent.status === "succeeded") {
  //           this.paying = false;
  //           // this.expireStripeSession(result.paymentIntent.id).subscribe((e)=>{
  //           //   console.log(e);
  //           // })
  //           this.checkBooking(this.bookingData.booking_id);
  //           // Show a success message to your customer
  //         }
  //       }
  //     });
  // }

  /**
   *
   * @param bookingId
   * This  will send a api call continesly to check whether booking is confirmed.
   */
  checkBooking(bookingId) {
    this.spinner.show();
    var data = {
      booking_id: bookingId,
    };
    this.sub = Observable.interval(10000).subscribe((val) => {
      this.apiServiceCjw
        .apiAuthPost(apiEndPointsCjw.checkConfimation, data)
        .subscribe(
          (res) => {
            if (res.code == 1) {
              this.spinner.hide();
              this.sub.unsubscribe();
              if (res.code == 1) {
                this.paymentSuccess = true;
                setTimeout(() => {
                  // <<<---using ()=> syntax
                  this.countdown.stop();
                }, 0);
                this.isTimerStart = false;
                this.toastr.success(res.message, "Success");
              }
            } else if (res.code == 3) {
              this.spinner.hide();
              this.sub.unsubscribe();
              this.toastr.error(res.message, "Error");
            } else if (res.code == 0) {
              this.spinner.hide();
              this.sub.unsubscribe();
              this.toastr.error(res.message, "Error");
            }
          },
          (error) => {
            this.spinner.hide();
            this.sub.unsubscribe();
          }
        );
    });
  }

  /**
   * Expire the session of stripe payment
   */
  expireStripeSession(id) {
    var header = {
      headers: new HttpHeaders()
        .set(
          "Authorization",
          `Bearer sk_test_51LN8weBEPirKdfbW6pXQXrgTLF4jBrJvA8wT49MFqSKTxPh2pOyHtGl8Qazwe9LenVEUwF8OgAwOVFtrU0U11cxH00qOFneMGJ`
        )
        .set("Content-Type", `application/x-www-form-urlencoded`),
    };
    var data =
      "amount=" +
      this.amount +
      "&currency=aud&payment_method_types[]=card&description=" +
      this.bookingData.booking_id;
    const formData = new FormData();
    // formData.append('amount', this.calculateAmount(amount));
    // formData.append('currency', 'usd');
    // formData.append('payment_method_types[]', 'card');
    return this.httpClient.post(
      "https://api.stripe.com/v1/payment_intents/" + id + "/cancel",
      header
    );
  }

  /**
   *
   * @param reference
   */
  // createReference(id){
  //   this.spinner.show();
  //   let fileName = sessionStorage.getItem('fileName');
  //   var data = {
  //     'payment_ref': id,
  //     'booking_id': this.bookingData.booking_id,
  //     'payment_id': this.bookingData.payment_id,
  //     'fileName':fileName
  //   }
  //   this.subscription.push(this.apiServiceCjw.apiAuthPost(apiEndPointsCjw.createReference, data).subscribe(res => {
  //     this.spinner.hide();
  //     if (res.code == 1) {
  //       this.paymentSuccess = true;
  //       this.toastr.success(res.message,'Success')
  //     } else {
  //       this.bookingData.payment_id = res.response.payment_id;
  //       this.toastr.error(res.message, "Error")
  //     }
  //   }, error => {
  //   }))
  // }

  /**
   *
   * @param amount
   * Create payment intent
   */
  createPaymentIntentV2(amount) {
    this.createPaymentIntent(amount).subscribe((pi) => {
      this.elementsOptions.clientSecret = pi.client_secret;
      this.createPaymentIntentApi(pi.id);
    });
  }

  /**
   *
   * @param amount
   * @returns
   */
  createPaymentIntentApi(paymentRef) {
    this.spinner.show();
    let fileName = sessionStorage.getItem("fileName");
    var data = {
      payment_ref: paymentRef,
      booking_id: this.bookingData.booking_id,
      payment_id: this.bookingData.payment_id,
      fileName: fileName,
    };
    this.subscription.push(
      this.apiServiceCjw
        .apiAuthPost(apiEndPointsCjw.createPaymentIntent, data)
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.code == 1) {
              setTimeout(() => {
                // <<<---using ()=> syntax
                this.countdown.begin();
              }, 0);
              // this.paymentSuccess = true;
              // this.toastr.success(res.message,'Success')
            } else {
              // this.bookingData.payment_id = res.response.payment_id;
              this.toastr.error(res.message, "Error");
            }
          },
          (error) => {}
        )
    );
  }

  createPaymentIntent(amount: number): Observable<PaymentIntent> {
    var header = {
      headers: new HttpHeaders()
        .set(
          "Authorization",
          `Bearer sk_test_51LN8weBEPirKdfbW6pXQXrgTLF4jBrJvA8wT49MFqSKTxPh2pOyHtGl8Qazwe9LenVEUwF8OgAwOVFtrU0U11cxH00qOFneMGJ`
        )
        .set("Content-Type", `application/x-www-form-urlencoded`),
    };
    var data =
      "amount=" +
      this.amount +
      "&currency=" +
      this.rateRecheckResponse.currency +
      "&payment_method_types[]=card&description=" +
      this.bookingData.booking_id;
    const formData = new FormData();
    return this.httpClient.post<PaymentIntent>(
      "https://api.stripe.com/v1/payment_intents",
      data,
      header
    );
  }

  /**
   * This will trigger when user click on new search
   */
  onNewSearch() {
    sessionStorage.clear();
    var steps = {
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      step5: false,
    };
    sessionStorage.setItem("steps-completed", JSON.stringify(steps));
    sessionStorage.setItem("step", "1");
    sessionStorage.setItem("activeStep", "1");
    this.changeStepFunction.next("id");
  }

  /**
   * This will triggers when user click on change payment method
   * @param type 
   */
  changePaymentMethod(type){
    this.selectedPaymentMethod = type;
    if(type == 1){
      this.getInqbaytorAccountData();
    }else if(type == 2){
    }
  }


  /**
 * Get inqbaytor pay accounts data
 */
  getInqbaytorAccountData() {
    this.spinner.show();
    this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.getInqPayAllowedBalance, {}).subscribe(res => {
      this.spinner.hide();
      if (res.code == 1) {
        this.accountData = [];
        // if (this.bookingType != '1') {
          this.accountData = res.data;
          const foundIndex = this.accountData.findIndex((x) => x.currency == 'AUD');
          if(foundIndex > 0){
            this.accountData.splice(foundIndex,1);
          }
          this.accountData.forEach(element => {
            if (element.primary == 1) {
              this.selectedAccount = element.account_id.toString();
              this.currentAvailableBalance = element.accountBalance;
            }
          });
      }
    },error=>{
    })
    )
  }

  /**
   * This function will triggers when user select the account
   * @param value 
   */
  onAccountChange(value) {
    this.selectedAccount = value;
    this.getBalance();
  }

 //**Get api balance from api */
  getBalance(){
    this.accountData.forEach(element => {
      if (element.account_id == this.selectedAccount) {
        this.currentAvailableBalance = element.accountBalance;
      }
    });    
  }

  //*Initiate payment from api
  onPay(){
    if(+this.currentAvailableBalance >= +this.amount){
      let promoDetails = JSON.parse(localStorage.getItem('promoDetails'));
      this.spinner.show();
      var data = {
        "booking_id":this.bookingData.booking_id,
        "pay_id" : this.selectedAccount,
        "payment_method_id": promoDetails[0].type[0].id,
      }
      this.subscription.push(this.apiService.apiAuthPost(apiEndPointsCjw.initiatePayement, data).subscribe(res => {
        this.spinner.hide()
        if (res.code == 1) {
          this.requestTransactionResponse = res;
          this.startTimer(60);
          this.otpBox = true;
        }else{
          this.toastr.error(res.message,"Error")
        }
      },error=>{
      })
      )
    }else{
      this.toastr.error("Your account balance is insufficient","Error")
    }    
  }

  //Timer for otp dialog
  startTimer(time) {
    this.timeLeft = time;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.clearTimer();
        this.isSmsExpired = true;
      }
    }, 1000)
  }

  /**
   * Clear interval
   */
  clearTimer() {
    clearInterval(this.interval);
  }    

}
