import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { concat, Observable, of, Subject, Subscription, of as observableOf } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe, formatDate } from '@angular/common';
import { apiEndPoints } from 'src/app/core/helpers/api-endpoints';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiCjwService } from 'src/app/core/services/apicjw.service';
import { apiEndPointsCjw } from 'src/app/core/helpers/api-endpoints-cjw';
import * as moment from 'moment';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Location } from '@angular/common';
import { FlightSearchService } from 'src/app/pages/flight-management/create-booking/flight-search/flight-search.service';
@Component({
  selector: 'app-hotel-search',
  templateUrl: './hotel-search.component.html',
  styleUrls: ['./hotel-search.component.scss']
})
export class HotelSearchComponent implements OnInit {

  breadCrumbItems: Array<{}>;
  leavingFromValues: [];
  leavingFromNotFoundtext = "Type to search";
  leavingFromLoading = false;
  leavingFromInput = new Subject<string>();
  flightSearchId;
  isOnewaySelected = false;


  leavingToValues: [];
  leavingToLoading = false;
  leavingToInput = new Subject<string>();
  leavingToNotFoundtext = "Type to search";

  hoveredDate: NgbDate;
  fromNGDate: NgbDate;
  toNGDate: NgbDate;

  model: NgbDateStruct;
  date: { year: number, month: number };

  isOneway = false;
  isReturn = true;
  selectedRadio;
  minDate: NgbDate

  searchHotelForm: FormGroup;
  travelersForm: FormGroup;
  optForm: FormGroup;

  typesubmit: boolean;
  travelerssubmit: boolean;
  smsSubmit: boolean;

  constructor(
    private calendar: NgbCalendar,
    private apiServiceCjw: ApiCjwService,
    private apiService: ApiService,
    private flightSearchService: FlightSearchService,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private location: Location,
  ) {
    this.minDate = calendar.getToday();
    location.replaceState('hotel-bookings/hotel-search')
  }

  @Input() fromDate: Date;
  @Input() toDate: Date;
  @Output() dateRangeSelected: EventEmitter<{}> = new EventEmitter();
  // @Output() someEvent = new EventEmitter<string>();
  // @Output() resetEvent = new EventEmitter<string>();
  @Output() changeIndex = new EventEmitter<string>();

  @ViewChild('dp', { static: true }) datePicker: any;
  @ViewChild('otpmodel', { static: false }) private otpmodel;

  hidden: boolean = true;
  selected: any;
  subscription: Subscription[] = [];
  leavingFrom;
  origin = '';
  destination = '';
  adultsCount = 1;
  childCount = 0;
  roomCount = 1;
  childrenAge = [];
  selectedAge = [];
  numberOfNights = "";

  ngOnInit(): void {
    this.breadCrumbItems = [
      {
        label: 'Dashboard',
        onclick: '/dashboard'
      },
      { label: 'Flight-Search', active: true }
    ];
    this.generateForm();
    this.checkAvailability();
    this.fetchPreviousData();
    // this.fetchTypeheadData()
  }

  // fetchTypeheadData(){
  //   let ori = JSON.parse(localStorage.getItem('flight-search-origin'));
  //   let des =  JSON.parse(localStorage.getItem('flight-search-destination'));
  //   if(ori !=)
  //   this.destination =
  //   // this.changeLeavingFrom(origin);
  //   // this.changeLeavingTo(des)
  // }

  checkAvailability() {
    let cjwToken = localStorage.getItem('access-token1');
    if (cjwToken == null) {
      this.spinner.show();
      this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.sentOtp, {}).subscribe(res => {
        this.spinner.hide();
        if (res.code == 1) {
          setTimeout(() => {
            this.openModalSMS(this.otpmodel);
          });
        }
      }, error => {
      }))
    }
  }

  /**
   * 
   * @param i 
   * @returns 
   */
   counter(i) {
    return new Array(parseInt(i));
  }  


  /**
   * Generate flight search form
   */
  generateForm() {
    /**
     * Type validation form
     */
    this.searchHotelForm = this.formBuilder.group({
      whereTo: [null, [Validators.required]],
      dateRange: ['', [Validators.required]],
      travelersDetails: [[''], [Validators.required]]
    },
    );

    this.travelersForm = this.formBuilder.group({
      adults: ['', [Validators.required]],
      children: ['', [Validators.required]],
      room: ['', [Validators.required]]
    });

    this.optForm = this.formBuilder.group({
      smsCode: ['', [Validators.required]],
    })

    this.travelersForm.patchValue({
      "adults": 1,
      "children": 0,
      "room": 1
    });

    this.searchHotelForm.patchValue({
      "travelersDetails": 1 + " Traveller(s), " + "1 Room(s)",
    });

    // this.adultsCount == 1;
    // this.childCount == 0;
    // this.infantCount == 0;
  }

  /**
   * Returns the type validation form
   */
  get type() {
    return this.searchHotelForm.controls;
  }

  /**
   * Returns the type validation form
   */
  get smstype() {
    return this.optForm.controls;
  }


  /**
  * Returns the type validation form
  */
  get travelers() {
    return this.travelersForm.controls;
  }

  fetchPreviousData() {
    let tempdata = localStorage.getItem('hotel-search');
    if (tempdata != undefined && tempdata != null) {
      let data = JSON.parse(localStorage.getItem('hotel-search'));
      var d = new Date();
      if (Date.parse(data.check_in) >= d.getDate()) {
        this.selected = data.check_in + '/' + data.check_out;
        let departure = new Date(data.check_in);
        let arrival = new Date(data.check_out);
        var timeDiff = Math.abs(arrival.getTime() - departure.getTime());
        this.numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24)).toString();
        this.fromNGDate = new NgbDate(departure.getFullYear(), departure.getMonth() + 1, departure.getDate())
        this.toNGDate = new NgbDate(arrival.getFullYear(), arrival.getMonth() + 1, arrival.getDate())
      }
      this.searchHotelForm.patchValue({
        "whereTo":JSON.parse(localStorage.getItem('hotel-search-destination'))
      });
      this.travelersForm.patchValue({
        "adults": data.adultCount,
        "children": data.childCount,
        "room": data.roomCount
      });
      this.adultsCount = data.adultCount,
      this.childCount = data.childCount,
      this.roomCount = data.roomCount,
      this.selectedAge = data.age,
      this.searchHotelForm.patchValue({
        "travelersDetails": (data.adultCount + data.childCount) + " Traveller(s), " + data.roomCount + " Room(s)",
      })
    }
  }

  testing() {
    this.hidden = true;
  }

  /**
   * 
   * @param event This will get the suggestion data when user searching 
   */
  changeWhereToFrom(event) {
    var term = event.term
    if (term != null && term.length > 1) {
      this.leavingToNotFoundtext = "No items found"
    } else if (term != null && term.length == 0) {
      this.leavingToNotFoundtext = "Type to search"
    } else {
      this.leavingToNotFoundtext = "Type 3 or more characters"
    }
    if (term != null && term.length > 2) {
      this.leavingFromLoading = true;
      var data = {
        char: term
      }
      this.subscription.push(this.apiServiceCjw.apiAuthPost(apiEndPointsCjw.getCities, data).subscribe(res => {
        this.spinner.hide();
        // var items = res;
        // var tempItems = [];
        // var tempItems1 = [];
        // items = tempItems;
        this.leavingFromValues = [];
        this.leavingFromValues = res;
        this.leavingFromLoading = false;
      }, error => {
      }))
    } else {
      this.leavingFromValues = [];
    }

    // var term = event.term;
    // if(event == 'focus'){
    //       let ori = JSON.parse(localStorage.getItem('flight-search-origin'));
    //       if(ori != null){
    //         term = ori.key;
    //       }else{
    //         term = ""
    //       }
    // }
    // if(term.length > 1){
    //   this.leavingFromNotFoundtext = "No items found"
    // }else if(term.length == 0){
    //   this.leavingFromNotFoundtext = "Type to search"
    // }else{
    //   this.leavingFromNotFoundtext = "Type 3 or more characters"
    // } 
    // if(term.length > 2){
    //   let tempitems = [];
    //   var items = tempitems['dataAirport'];
    //   // if(this.searchFlightForm.value.destination ) {
    //   for(let i=0; i<items.length;i++){
    //       if(items[i]['city'] != ""){
    //           items[i]['key'] = items[i]['airport_name']+", "+items[i]['city']+", "+items[i]['country']+" ("+items[i]['airport_code']+")";
    //       }else{
    //           items[i]['key'] = items[i]['airport_name']+", "+items[i]['country']+" - All Airports ("+items[i]['airport_code']+")";
    //       }
    //   }
    //   var tempItems = [];
    //   var tempItems1 = [];
    //   tempItems = items.filter(x => x['airport_code'].toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
    //   tempItems1 = items.filter(x => x['key'].toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
    //   tempItems1.forEach(element =>{
    //     if(tempItems.findIndex((item) => item.id === element.id) < 0) {
    //       tempItems.push(element);
    //     }
    //   })
    //   items = tempItems;
    //   this.leavingFromValues = [];
    //   this.leavingFromValues = items;     
    // }else{
    //   this.leavingFromValues = [];
    // }
  }

  changeLeavingTo(event) {
    this.leavingToLoading = true;
    var term = event.term;
    if (event == 'focus') {
      let des = JSON.parse(localStorage.getItem('flight-search-destination'));
      if (des != null) {
        term = des.key
      } else {
        term = ""
      }
    }
    if (term.length > 1) {
      this.leavingToNotFoundtext = "No items found"
    } else if (term.length == 0) {
      this.leavingToNotFoundtext = "Type to search"
    } else {
      this.leavingToNotFoundtext = "Type 3 or more characters"
    }
    if (term.length > 2) {
      let tempitems = [];
      var items = tempitems['dataAirport'];
      // if(this.searchFlightForm.value.destination ) {
      for (let i = 0; i < items.length; i++) {
        if (items[i]['city'] != "") {
          items[i]['key'] = items[i]['airport_name'] + ",  " + items[i]['city'] + ", " + items[i]['country'] + " (" + items[i]['airport_code'] + ")";
        } else {
          items[i]['key'] = items[i]['airport_name'] + ", " + items[i]['country'] + " - All Airports (" + items[i]['airport_code'] + ")";
        }
      }
      var tempItems = [];
      var tempItems1 = [];
      tempItems = items.filter(x => x['airport_code'].toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
      tempItems1 = items.filter(x => x['key'].toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
      tempItems1.forEach(element => {
        if (tempItems.findIndex((item) => item.id === element.id) < 0) {
          tempItems.push(element);
        }
      })
      items = tempItems;
      this.leavingToValues = [];
      this.leavingToValues = items;
    } else {
      this.leavingToValues = [];
    }
    this.leavingToLoading = false;
  }

  // selectedCityId;
  // searchLeavingTo(term: string, item: any){
  //   // if(term.length > 2){
  //     let tempitems = require('./airplaneDetail.json');
  //     this.leavingToValues = tempitems.dataAirport;
  //     item = this.leavingToValues;
  //     // if(this.searchFlightForm.value.destination ) {
  //         for(let i=0; i<item.length;i++){
  //             if(item[i]['city'] != ""){
  //               item[i]['key'] = item[i]['airport_name']+","+item[i]['city']+","+item[i]['country']+" ("+item[i]['airport_code']+")";
  //             }else{
  //               item[i]['key'] = item[i]['airport_name']+","+item[i]['country']+" - All Airports ("+item[i]['airport_code']+")";
  //             }
  //         }

  //         return item.filter(x => x['key'].toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
  //   // }

  //       // this.leavingToValues = [];
  //       // this.leavingToValues = items;
  //     // }
  //   // this.leavingToValues = concat(
  //   //     of([]), // default items
  //   //     this.leavingToInput.pipe(
  //   //         distinctUntilChanged(),
  //   //         tap(() => this.leavingToLoading = true),
  //   //         switchMap(term => this.flightSearchService.getLeavingfromData(term).pipe(
  //   //             catchError(() => of([])), // empty list on error
  //   //             tap(() => this.leavingToLoading = false)
  //   //         ))
  //   //     )
  //   // );
  // }

  onSelectOrigin(event) {
    console.log(event)
  }

  toggleWithGreeting(popover, greeting: string, language: string) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open({ greeting, language });
    }
  }

  clearleavingFromValues() {
    this.leavingFromValues = [];
    this.leavingFromNotFoundtext = "Type to search"
  }

  clearleavingToValues() {
    this.leavingToValues = [];
    this.leavingToNotFoundtext = "Type to search"
  }

  /**
 * on date selected
 * @param date date object
 */
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromNGDate = date;
      this.fromDate = new Date(date.year, date.month - 1, date.day);
      this.selected = '';
      this.toNGDate = null;
    } else if (this.fromDate && !this.toDate && date.after(this.fromNGDate)) {
      this.toNGDate = date;
      this.toDate = new Date(date.year, date.month - 1, date.day);
      this.hidden = true;

      const format = 'yyyy-MM-dd';
      const locale = 'en-US';
      const pipe = new DatePipe('en-US');
      var formattedDateFrom = pipe.transform(this.fromDate, format);
      var formattedDateTo = pipe.transform(this.toDate, format);
      // var formattedDateFrom = formatDate(this.fromDate.toLocaleDateString(), format, locale);
      // var formattedDateTo = formatDate(this.toDate.toLocaleDateString(), format, locale);
      this.selected = formattedDateFrom + '/' + formattedDateTo;

      this.dateRangeSelected.emit({ fromDate: this.fromDate, toDate: this.toDate });
      var date1 = new Date(this.fromDate);
      var date2 = new Date(this.toDate);
      var timeDiff = Math.abs(date2.getTime() - date1.getTime());
      this.numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24)).toString();
      this.fromDate = null;
      this.toDate = null;
      // this.fromNGDate = null;
      // this.toNGDate = null;

    } else {
      this.fromNGDate = date;
      this.fromDate = new Date(date.year, date.month - 1, date.day);
      this.selected = '';
    }
  }
  /**
   * Is hovered over date
   * @param date date obj
   */
  isHovered(date: NgbDate) {
    return this.fromNGDate && !this.toNGDate && this.hoveredDate && date.after(this.fromNGDate) && date.before(this.hoveredDate);
  }

  /**
   * @param date date obj
   */
  isInside(date: NgbDate) {
    return date.after(this.fromNGDate) && date.before(this.toNGDate);
  }

  /**
   * @param date date obj
   */
  isRange(date: NgbDate) {
    return date.equals(this.fromNGDate) || date.equals(this.toNGDate) || this.isInside(date) || this.isHovered(date);
  }

  /**
   * Select the today
   */
  selectToday() {
    this.model = this.calendar.getToday();
  }

  /**
 * Open modal
 * @param content modal content
 */
  openModal(content: any) {
    this.travellersInvalid = false;
    this.adultsCount = this.travelersForm.value.adults;
    this.childCount = this.travelersForm.value.children;
    this.modalService.open(content, { windowClass: 'modal-holder' });
  }

  closeModal() {
    this.travelersForm.patchValue(
      {
        "adults": this.adultsCount,
        "children": this.childCount,
        "infant": this.roomCount,
      }
    );
    this.modalService.dismissAll();
  }

  /**
   * Type validation form submit data
   */
  typeSubmit() {
    this.typesubmit = true;
    if (this.searchHotelForm.valid) {
      var data;
      this.spinner.show();
      let dateArray = this.searchHotelForm.value.dateRange.split("/");
      data = {
        "adultCount": this.adultsCount,
        "childCount": this.childCount,
        "check_in": dateArray[0],
        "age":this.selectedAge,
        "destination_code": this.searchHotelForm.value.whereTo.code,
        "destination_name": this.searchHotelForm.value.whereTo.name,
        "roomCount": this.roomCount,
        "check_out": dateArray[1],
        "type": this.searchHotelForm.value.whereTo.type,
      }
      let searchData = data;
      searchData['noOfNights'] = this.numberOfNights;
      localStorage.setItem('hotel-search', JSON.stringify(searchData));
      localStorage.setItem('hotel-search-destination',JSON.stringify(this.searchHotelForm.value.whereTo))
      // localStorage.setItem('flight-search-origin', JSON.stringify(this.searchHotelForm.value.origin));
      // localStorage.setItem('flight-search-destination', JSON.stringify(this.searchHotelForm.value.destination));
      var steps = {
        "step1": true,
        "step2": false,
        "step3": false,
        "step4": false,
        "step5": false
      }
      sessionStorage.setItem('steps-completed', JSON.stringify(steps));
      sessionStorage.setItem('step', '2');
      sessionStorage.setItem('activeStep', '2');
      sessionStorage.setItem('step2Response', null);
      this.changeIndex.next();
      this.spinner.hide();
      // this.router.navigateByUrl('flightmanagement/flight-results')
    }


    // sessionStorage.setItem('flight-search',JSON.stringify(data));
    // sessionStorage.setItem('step','2');
    // // this.router.navigateByUrl('flightmanagement/flight-results')
    // this.someEvent.next('click');
    // this.spinner.hide();
    // this.router.navigateByUrl("flightmanagement/flight-results")
    // this.subscription.push(this.apiServiceCjw.apiAuthPost(apiEndPointsCjw.getSearchResults,data).subscribe(res => {
    //     this.spinner.hide();
    //     if(res.code == 1){
    //       this.router.navigateByUrl("flightmanagement/flight-results")
    //     }else{
    //       this.toastr.error('', res.message);
    //     }
    //   },error=>{
    //  })) 
  }


  onOutsideClick() {
    this.hidden = true;
  }

  travellersInvalid = false;
  /**
   * 
   */
  travellersSubmit() {
    this.travelerssubmit = true;
    if (this.travelersForm.valid) {
      if(this.travelersForm.value.children > 0){
        var existingItem = this.selectedAge.find(x => x == 0);
        if(existingItem != null){
          this.toastr.error("Please select all children age",'Error')
        }else{
          var tempTotal = (this.travelersForm.value.adults + this.travelersForm.value.children);
          if (tempTotal > 9) {
            this.travellersInvalid = true;
          } else {
            this.adultsCount = this.travelersForm.value.adults;
            this.childCount = this.travelersForm.value.children;
            this.roomCount = this.travelersForm.value.room;
            this.searchHotelForm.patchValue({
              "travelersDetails": tempTotal + " Traveller(s), " + this.roomCount + " Room(s)",
            })
            this.modalService.dismissAll();
          }
        }
      }else{
        var tempTotal = (this.travelersForm.value.adults + this.travelersForm.value.children);
        if (tempTotal > 9) {
          this.travellersInvalid = true;
        } else {
          this.adultsCount = this.travelersForm.value.adults;
          this.childCount = this.travelersForm.value.children;
          this.roomCount = this.travelersForm.value.room;
          this.searchHotelForm.patchValue({
            "travelersDetails": tempTotal + " Traveller(s), " + this.roomCount + " Room(s)",
          })
          this.modalService.dismissAll();
        }
      }
    }
  }

  /**
   *children decrement
   */
  childrentDecrement() {
    var tempChildren = this.travelersForm.value.children
    if (tempChildren > 0) {
      this.travellersInvalid = false;
      tempChildren = tempChildren - 1;
      this.selectedAge.pop();
      this.travelersForm.patchValue({ 'children': tempChildren })
    }
  }

  /**
   *children increment
   */
  childrentIncrement() {
    var tempChildren = this.travelersForm.value.children
    if (tempChildren < 5) {
      this.travellersInvalid = false;
      tempChildren = tempChildren + 1;
      for(var c = 0; c < tempChildren ;c++){
        if(this.selectedAge.length < tempChildren){
          this.selectedAge.push(0);
        }
      }
      this.travelersForm.patchValue({ 'children': tempChildren })
    }
    console.log(this.selectedAge);
  }

  /**
   *adult decrement
   */
  adultDecrement() {
    var tempAdults = this.travelersForm.value.adults
    var tempInfants = this.travelersForm.value.infant
    if (tempAdults > 1) {
      this.travellersInvalid = false;
      tempAdults = tempAdults - 1
      if (tempInfants > tempAdults) {
        this.travelersForm.patchValue({ 'infant': tempAdults })
      }
      this.travelersForm.patchValue({ 'adults': tempAdults })
    }
  }

  /**
   *adult increment
   */
  adultIncrement() {
    var tempAdults = this.travelersForm.value.adults
    if (tempAdults < 9) {
      this.travellersInvalid = false;
      tempAdults = tempAdults + 1
      this.travelersForm.patchValue({ 'adults': tempAdults })
    }
  }


  /**
  *infant decrement
  */
  infantDecrement() {
    var tempinfant = this.travelersForm.value.infant
    if (tempinfant > 0) {
      this.travellersInvalid = false;
      tempinfant = tempinfant - 1
      this.travelersForm.patchValue({ 'infant': tempinfant })
    }
  }
  /**
  *room increment
  */
  roomsIncrement() {
    var temproom = this.travelersForm.value.room
    if (temproom < 5) {
      this.travellersInvalid = false;
      temproom = temproom + 1
      this.travelersForm.patchValue({ 'room': temproom })
    }
  }

  /**
  *room decrement
  */
  roomsDecrement() {
    var tempnroom = this.travelersForm.value.room
    if (tempnroom > 1) {
      this.travellersInvalid = false;
      tempnroom = tempnroom - 1
      this.travelersForm.patchValue({ 'room': tempnroom })
    }
  }
  /**
  *infant increment
  */
  infantIncrement() {
    var tempinfant = this.travelersForm.value.infant
    if (tempinfant < this.travelersForm.value.adults) {
      this.travellersInvalid = false;
      tempinfant = tempinfant + 1
      this.travelersForm.patchValue({ 'infant': tempinfant })
    }
  }

  onOtpSubmit() {
    this.smsSubmit = true;
    if (this.optForm.valid) {
      this.spinner.show();
      let user = JSON.parse(localStorage.getItem('user'));
      var data = {
        'password': this.optForm.value.smsCode,
        'phone_number': user.mobile_number
      }
      this.subscription.push(this.apiServiceCjw.apiAuthPost(apiEndPointsCjw.smsVerify, data).subscribe(res => {
        this.spinner.hide();
        if (res.code == 1) {
          localStorage.setItem('access-token1', res.other.acToken);
          this.modalService.dismissAll();
        } else {
          this.toastr.error('Invalid code entered', 'Error')
        }
      }, error => {
      }))
    }
  }

  /**
  * Open modal
  * @param otpmodel modal content
  */
  openModalSMS(otpmodel: any) {
    this.modalService.open(otpmodel, { windowClass: 'modal-holder', backdrop: 'static', keyboard: false });
  }

  onCloseSmsModel(otpmodel: any) {
    this.modalService.dismissAll();
    this.router.navigateByUrl('/dashboard');
  }

  /**
   * On child age changed
   */
  onChange(i){
    // this.childrenAge[i] = this.selectedAge[i];
    // console.log(this.childrenAge);
  }

}