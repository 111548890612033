import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxGalleryAnimation, NgxGalleryComponent, NgxGalleryImage, NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { apiEndPointsCjw } from 'src/app/core/helpers/api-endpoints-cjw';
import { ApiCjwService } from 'src/app/core/services/apicjw.service';
import { Location, LocationStrategy } from '@angular/common';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-hotel-book',
  templateUrl: './hotel-book.component.html',
  styleUrls: ['./hotel-book.component.scss']
})
export class HotelBookComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];
  galleryOptionRooms: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  hotelDetails;
  subscription: Subscription[] = [];
  promoDetails;
  promoloading = false;
  selectedPromo = 1;
  selectedPromoIndex = 0;
  selectedPromoTypeIndex = 0;
  promoTypeDesc;
  active = 2;
  hotelFacilityIcon = [];
  roomData = [];
  roomImages = [];
  roomLoading = false;
  roomCount = 8;
  selectedRoomsData = [];
  finalSelectedRoomCount = 0;
  finalSelectedTotalAmount = 0;
  hotelLoading = false;
  searchedData;
  images = [];

  @Output() changeIndex = new EventEmitter<string>();
  @Output() changeStepFunction = new EventEmitter<string>();
  @ViewChild('gallery', {static: false}) gallery: NgxGalleryComponent;  
  //reference to "myCarousel"
  @ViewChild('myCarousel') myCarousel: NgbCarousel;

  @ViewChild(NgxGalleryComponent) ngxGalleryComponent;

  constructor(
    private spinner: NgxSpinnerService,
    private apiServiceCjw: ApiCjwService,
    private router: Router,
    private toastr: ToastrService,
    private location: Location,
    private changeDetectorRefs: ChangeDetectorRef,
    config: NgbCarouselConfig
  ) {
    location.replaceState('hotel-bookings/hotel-book'),
    config.interval = 20000
  }

  changeStep() {
    this.changeStepFunction.next('id');
  }  

  ngOnInit(): void {
    var steps = JSON.parse(sessionStorage.getItem('steps-completed'));
    if (!steps.step3) {
      this.getRoomDetails();
    } else {
      this.fetchStoredData();
    }
    // this.getRoomDetails();
    this.getHotelDetails();
    // this.getPromo();
    this.galleryOptions = [
      {
        arrowPrevIcon: 'fa fa-arrow-circle-left',
        arrowNextIcon: 'fa fa-arrow-circle-left',
        closeIcon: 'fa fa-window-close'
      },
      {
        width: '100%',
        height: '600px',
        thumbnailsColumns: 8,
        thumbnailsRows: 1,
        imageAnimation: NgxGalleryAnimation.Slide,
        thumbnailsPercent: 20,
        thumbnailsMargin: 10,
        thumbnailMargin: 10,
        // thumbnailsRemainingCount:true,
        // thumbnailsArrows:true,
        // image:false,
        // preview: false,
      },
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 10,
        thumbnailMargin: 10,
        thumbnailsColumns: 8,
        thumbnailsRows: 1,
        // thumbnailsRemainingCount:true
        // image:false,
        // preview: false,
      },
      {
        breakpoint: 400,
        image:false,
        preview: false
      },
    ];



    ///*
    this.galleryOptionRooms = [
      {
        width: '100%',
        height: '250px',
        thumbnailsColumns: 6,
        thumbnailsRows: 1,
        imageAnimation: NgxGalleryAnimation.Slide
      },
      {
        breakpoint: 800,
        width: '100%',
        height: '250px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
      },
      {
        breakpoint: 400,
        preview: false,
      },
    ];
    this.searchedData = JSON.parse(localStorage.getItem('hotel-search'));
  }

  onChangeImage(){
    // this.myCarousel.select('slideId-'+this.ngxGalleryComponent.selectedIndex)
    // setTimeout(() => { this.myCarousel.select('slideId-'+this.ngxGalleryComponent.selectedIndex) });
  }

  /**
   * Fetch stored data
   */
  fetchStoredData(){
    this.roomData = JSON.parse(localStorage.getItem('roomData'));
    this.roomData.forEach((e, i) => {
      let tempSelectedRoomCount = 0;
      let tempSelectedRoomPrice = 0;
      tempSelectedRoomCount = this.roomData[i].rates.map((item) => Number.parseInt(item.selectedRoomCount)).reduce((acc, curr) => acc + curr, 0);
      tempSelectedRoomPrice = this.roomData[i].rates.map((item) => Number.parseInt(item.selectedRoomPrice)).reduce((acc, curr) => acc + curr, 0);
      this.finalSelectedRoomCount = this.finalSelectedRoomCount + tempSelectedRoomCount;
      this.finalSelectedTotalAmount = this.finalSelectedTotalAmount + tempSelectedRoomPrice;
    })
    this.roomLoading = false;
  }

  /**
   * Star filter build
   * @param i 
   * @returns 
   */
  counter(i) {
    return new Array(parseInt(i));
  }

  /**
 * room filter build
 * @param i 
 * @returns 
 */
  roomCounter(i: number) {
    return new Array(i);
  }


  /**
   * This will get the hotel details
   */
  getHotelDetails() {
    this.hotelLoading = true;
    let hotelSearchId = JSON.parse(sessionStorage.getItem('hotelSearchId'));
    let hotelCode = JSON.parse(localStorage.getItem('selectedHotelCode'))
    this.subscription.push(this.apiServiceCjw.apiAuthGet(apiEndPointsCjw.getHotelDetails + '?hotelCode=' + hotelCode + '&hotel_search_id=' + hotelSearchId).subscribe(res => {
      if (res.code == 1) {
        this.hotelDetails = res.response.hotel;
        localStorage.setItem('hotelDetails', JSON.stringify(this.hotelDetails));
        this.hotelFacilityIcon = res.response.facilityicon;
        let tempImageArray = [];
        this.hotelDetails.images.forEach(element => {
          let imageBaseUrl = 'https://photos.hotelbeds.com/giata/bigger/'
          tempImageArray.push(
            {
              "small": imageBaseUrl + element.path,
              "medium": imageBaseUrl + element.path,
              "big": imageBaseUrl + element.path,
            }
          )
          this.images.push(imageBaseUrl+element.path);
        });
        this.galleryImages = tempImageArray;
        this.hotelLoading = false;
        // this.gallery.moveThumbnailsLeft();
      } else {
        this.toastr.error(res.message, "Error");
        this.hotelLoading = false;
      }
    }, error => {
    })
    )
  }

  /**
   * Get promo cards,
   */
  getPromo() {
    this.promoloading = true;
    let hotelSearchId = JSON.parse(sessionStorage.getItem('hotelSearchId'));
    let promoDetails = JSON.parse(localStorage.getItem('promoDetails'));
    let sessionIndex = JSON.parse(sessionStorage.getItem('sessionIndex'));
    let hotelCode = JSON.parse(localStorage.getItem('selectedHotelCode'))
    var pricingData = {
      "hotelCode":[hotelCode.toString()],
      "hotel_search_id":hotelSearchId,
      "promo_code": promoDetails[0].pid+'~0',
      "sessionIndex": sessionIndex,
      "type": "r",
      "source":"TG"
    }
    this.subscription.push(this.apiServiceCjw.apiAuthPost(apiEndPointsCjw.getPromoPrices,pricingData).subscribe(res => {
      if (res.code == 1) {
        let priceData = res['response'];
        this.roomData.forEach((e, i) => {
          e.rates.forEach((rate, a) => {
            this.roomData[i].rates[a]['originalPrice'] = priceData[this.roomData[i]['code']][a]['originalPrice'];
            this.roomData[i].rates[a]['discountPrice'] = priceData[this.roomData[i]['code']][a]['discountPrice'];
          })
        });         
      } else {
        this.toastr.error(res.message, "Error")
      }
      this.promoloading = false;
      // this.gallery.moveThumbnailsLeft();
    }, error => {
    })
    )
  }

  onPromo(id, i) {
    this.selectedPromo = id;
    this.selectedPromoIndex = i;
    this.selectedPromoTypeIndex = 0;
  }


  onPromoType(id, i) {
    this.selectedPromoTypeIndex = i;
  }

  /**
   * Get room details
   */
  getRoomDetails() {
    this.roomLoading = true;
    let search = JSON.parse(localStorage.getItem('hotel-search'));
    let fileName = sessionStorage.getItem('fileName');
    let hotelSearchId = JSON.parse(sessionStorage.getItem('hotelSearchId'));
    let hotelCode = JSON.parse(localStorage.getItem('selectedHotelCode'))
    var date1 = new Date(search.check_in);
    var date2 = new Date(search.check_out);
    var timeDiff = Math.abs(date2.getTime() - date1.getTime());
    var numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));

    var data = {
      "destination_code": hotelCode.toString(),
      "adultCount": search.adultCount,
      "childCount": search.childCount,
      "type": 'hotel',
      "check_in": search.check_in,
      "check_out": search.check_out,
      "roomCount": search.roomCount,
      "age": search.age,
      "hotel_search_id": hotelSearchId,
      "filename": fileName,
      "nights": numberOfNights,
      "source": "TG",
    }
    this.subscription.push(this.apiServiceCjw.apiAuthPost(apiEndPointsCjw.getRoomDetails, data).subscribe(res => {
      if (res.code == 1) {
        this.roomLoading = false;
        this.roomData = res.response.result;
        this.roomData.forEach((e, i) => {
          e.rates.forEach((rate, a) => {
            this.roomData[i].rates[a]['availableRoomCount'] = this.roomData[i].rates[a]['allotment'];
            this.roomData[i].rates[a]['selectedRoomCount'] = 0;
            this.roomData[i].rates[a]['selectedRoomPrice'] = 0;
          })
          let tempImages = [];
          e.roomimages.forEach((image) => {
            tempImages.push(
              {
                "small": "https://photos.hotelbeds.com/giata/bigger/" + image,
                "medium": "https://photos.hotelbeds.com/giata/bigger/" + image,
                "big": "https://photos.hotelbeds.com/giata/bigger/" + image,
              }
            )
          })
          this.roomImages.push(tempImages);
        });
        this.getPromo();
        // this.gallery.moveThumbnailsLeft();
      } else {
        this.toastr.error(res.message, "Error")
      }
    }, error => {
    })
    )
  }

  /**
   * 
   */
  onRoomChange(selectedValue, roomIndex, rateIndex) {
    this.roomData[roomIndex].rates[rateIndex]['selectedRoomCount'] = Number.parseInt(selectedValue);
    this.roomData[roomIndex].rates[rateIndex]['selectedRoomPrice'] = this.roomData[roomIndex].rates[rateIndex].discountPrice * Number.parseInt(selectedValue);
    this.finalSelectedRoomCount = 0;
    this.finalSelectedTotalAmount = 0;

    this.roomData.forEach((e, i) => {
      let tempSelectedRoomCount = 0;
      let tempSelectedRoomPrice = 0;
      tempSelectedRoomCount = this.roomData[i].rates.map((item) => Number.parseInt(item.selectedRoomCount)).reduce((acc, curr) => acc + curr, 0);
      tempSelectedRoomPrice = this.roomData[i].rates.map((item) => Number.parseInt(item.selectedRoomPrice)).reduce((acc, curr) => acc + curr, 0);
      this.finalSelectedRoomCount = this.finalSelectedRoomCount + tempSelectedRoomCount;
      this.finalSelectedTotalAmount = this.finalSelectedTotalAmount + tempSelectedRoomPrice;
    })
  }

  /**
   * 
   */
  onClickOnSelect(selectedValue, roomIndex, rateIndex) {
    let totalSelectedRoomCount;
    totalSelectedRoomCount = this.roomData[roomIndex].rates.map((item) => Number.parseInt(item.selectedRoomCount)).reduce((acc, curr) => acc + curr, 0);
    let availableRoomCount;
    availableRoomCount = (this.roomData[roomIndex].rates[rateIndex]['allotment']) - (totalSelectedRoomCount - Number.parseInt(selectedValue));
    this.roomData[roomIndex].rates[rateIndex]['availableRoomCount'] = availableRoomCount
  }

  /**
   * On booking 
   */
  onBooking() {
    this.selectedRoomsData = [];
    this.roomData.forEach((room, i) => {
      let selectedRates = [];
      this.roomData[i].rates.forEach((rate, r) => {
        if (rate.selectedRoomCount > 0) {
          selectedRates.push(rate)
        }
      });
      var data = {
        "code": room.code,
        "name": room.name,
        "rates": selectedRates,
      }
      if (data.rates.length > 0) {
        this.selectedRoomsData.push(data);
      }
    })
    console.log(this.selectedRoomsData);
    console.log(this.roomData);
    localStorage.setItem('roomData',JSON.stringify(this.roomData));
    if (this.selectedRoomsData.length > 0) {
      this.checkRateKeys();
    } else {
      this.toastr.error("Please select one or more room", "Error")
    }
  }

  /**
   * This will return the  rate comment if available
   */
  checkRateKeys() {
    this.spinner.show();
    let tempRateKeys = [];
    // this.roomData = JSON.parse(localStorage.getItem('selectedRoomsData'));
    let fileName = sessionStorage.getItem('fileName');
    let promoDetails = JSON.parse(localStorage.getItem('promoDetails'));
    this.roomData.forEach((e) => {
      e.rates.forEach(r => {
        var tempData = {
          "rateKey": r.rateKey,
          "noOfRooms": r.selectedRoomCount,
        }
        if(r.selectedRoomCount > 0){
          tempRateKeys.push(tempData)
        }
      });
    });
    var data = {
      "hotel_serach_id" : JSON.parse(sessionStorage.getItem('hotelSearchId')),
      "sessionIndex":JSON.parse(sessionStorage.getItem('sessionIndex')),
      "fileName": fileName,
      "promoId": promoDetails[0].pid,
      "methodId": promoDetails[0].type[0].id,
      "rateKeys": tempRateKeys,
    }
    localStorage.setItem('rateRecheckPayload',JSON.stringify(data))
    this.subscription.push(this.apiServiceCjw.apiAuthPost(apiEndPointsCjw.rateRecheck, data).subscribe(res => {
      this.spinner.hide();
      if (res.code == 1) {
        localStorage.setItem('rateRecheckResponse',JSON.stringify(res.response))
        this.selectedRoomsData.forEach((e,index)=>{
          var existingItem = res.response.rooms.find(x => x.code == e.code);
          if(existingItem != null){
            existingItem.rates.forEach((a,rateIndex)=>{
              this.selectedRoomsData[index].rates.forEach((b,rateIndex1)=>{
                if(this.selectedRoomsData[index].rates[rateIndex1]['rateKey'] == existingItem.rates[rateIndex].rateKey){
                  this.selectedRoomsData[index].rates[rateIndex1]['rateComment']= a.rateComments;
                }
              })
            });
            // existingItem = this.consolidatorPcc.find((x => x.id == this.reissueForm.value.pcc));
          }
        });
        // res.response.rooms.forEach((e,index)=>{
        //   e.rates.forEach((a,rateIndex)=>{
        //     this.selectedRoomsData[index].rates[rateIndex]['rateComment']= a.rateComments;
        //   })
        // });
        console.log(this.selectedRoomsData);
        localStorage.setItem('selectedRoomsData', JSON.stringify(this.selectedRoomsData));
        var steps = {
          "step1": true,
          "step2": true,
          "step3": true,
          "step4": false,
          "step5": false
        }
        sessionStorage.setItem('steps-completed', JSON.stringify(steps))
        sessionStorage.setItem('step', '4');
        sessionStorage.setItem('activeStep', '4');
        sessionStorage.setItem('step3Response', null);
        this.changeIndex.next();   
        // this.router.navigateByUrl('hotel-bookings/hotel-book');
        // localStorage.setItem('selectedHotelCode',hotelCode);
      } else {
        this.toastr.error(res.response.message,"Error");
      }
    }, error => {
    })
    )
  }

}
