import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';   
import { GlobalConstants, isOverdraftAvailable } from '../helpers/globals';

@Injectable({providedIn: 'root'})
export class GlobalService {

  constructor(private myGlobals:GlobalConstants) {
    console.log(myGlobals.isOverdraftAvailable);
  }

 itemValue = new BehaviorSubject(this.theItem);
 quotesValueStatus = new BehaviorSubject(this.QuotesValue);
 overdraftStatus = new BehaviorSubject(this.overdraftStatusValue);
 reissueStatus = new BehaviorSubject(this.reissueStatusValue);
 refundCount = new BehaviorSubject(this.refundCountValue);
 reIssueCount = new BehaviorSubject(this.reissueCountValue);
 accounts = new BehaviorSubject(this.inqbaytorPayAccount);
 badgeStatus = new BehaviorSubject(this.badgeStatusValue);
 admStatus = new BehaviorSubject(this.admAccountValue);
 ticketPassStatus = new BehaviorSubject(this.TicketPassStatusValue);
 mobileNumberCode = new BehaviorSubject(this.MobileCodeUpdate);
 regionStatus =  new BehaviorSubject(this.regionAvailable);
 insuranceStatus = new BehaviorSubject(this.InsuranceStatusValue);
 quotesStatus = new BehaviorSubject(this.QuotesStatusValue);
 menuItems = new BehaviorSubject(this.MenuItemValue);
 isReloadTopBar = new BehaviorSubject(this.isReloadTopBarValue);
 cardPaymentStatus = new BehaviorSubject(this.CardPaymentStatusValue);
 nameValueStatus = new BehaviorSubject(this.NameValue);
 roleValueStatus = new BehaviorSubject(this.RoleValue);
 pendingCount = new BehaviorSubject(this.pendingCountValue);



  set theItem(value) {
    this.itemValue.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('notifyCount', value);
  }
  get theItem() {
    return localStorage.getItem('notifyCount');
  }

////////////////////////

  set badgeStatusValue(value) {
    this.badgeStatus.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('isBadgesAvailable', value);
    // this.myGlobals.isOverdraftAvailable = value;
  }
  get badgeStatusValue() {
    return localStorage.getItem('isBadgesAvailable');
  }


/////////////////////

  set overdraftStatusValue(value) {
    this.overdraftStatus.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('isOverdraftAvailable', value);
    // this.myGlobals.isOverdraftAvailable = value;
  }
  get overdraftStatusValue() {
    return localStorage.getItem('isOverdraftAvailable');
  }
/////////////////////
  set reissueStatusValue(value) {
    this.reissueStatus.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('isReissueAvailable', value);
    // this.myGlobals.isOverdraftAvailable = value;
  }
  get reissueStatusValue() {
    return localStorage.getItem('isReissueAvailable');
  }

/////////////////////
  set refundCountValue(value) {
    this.refundCount.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('refundCount', value);
  }

  get refundCountValue() {
    return localStorage.getItem('refundCount');
  }

/////////////////////
  set reissueCountValue(value) {
    this.reIssueCount.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('reissueCount', value);
  }

  get reissueCountValue() {
    return localStorage.getItem('reissueCount');
  }

/////////////////////
  set inqbaytorPayAccount(value) {
    this.accounts.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('inqbaytorPayAccount', value);
  }

  get inqbaytorPayAccount() {
    return localStorage.getItem('inqbaytorPayAccount');
  }

/////////////////////
  set admAccountValue(value) {
    this.accounts.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('admAccount', value);
  }

  get admAccountValue() {
    return localStorage.getItem('admAccount');
  }

  //////////////////////
  set TicketPassStatusValue(value) {
    this.ticketPassStatus.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('isTicketPassAvailable', value);
    // this.myGlobals.isOverdraftAvailable = value;
  }
  get TicketPassStatusValue() {
    return localStorage.getItem('isTicketPassAvailable');
  }  
  //////////////////////

  set InsuranceStatusValue(value) {
    this.insuranceStatus.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('isInsuranceAvailable', value);
    // this.myGlobals.isOverdraftAvailable = value;
  }
  get InsuranceStatusValue() {
    return localStorage.getItem('isInsuranceAvailable');
  }  



  //////////////////////
  set MobileCodeUpdate(value) {
    this.mobileNumberCode.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('mobileCode', value);
    // this.myGlobals.isOverdraftAvailable = value;
  }
  get MobileCodeUpdate() {
    return localStorage.getItem('mobileCode');
  }  

  //////////////////
  set regionAvailable(value) {
    this.regionStatus.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('regionAvailable', JSON.stringify(value));
    // this.myGlobals.isOverdraftAvailable = value;
  }
  get regionAvailable() {
    return JSON.parse(localStorage.getItem('regionAvailable'));
  }    

  //////////////////////
  set QuotesValue(value) {
    this.quotesValueStatus.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('quotesCount', value);
    // this.myGlobals.isOverdraftAvailable = value;
  }
  get QuotesValue() {
    return localStorage.getItem('quotesCount');
  }  

  ////////////////////
  set QuotesStatusValue(value) {
    this.quotesStatus.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('isQuoteAvailable', value);
    // this.myGlobals.isOverdraftAvailable = value;
  }
  get QuotesStatusValue() {
    return localStorage.getItem('isQuoteAvailable');
  }  

  //////////////////////
  set MenuItemValue(value) {
    this.menuItems.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('a-routes', value);
    // this.myGlobals.isOverdraftAvailable = value;
  }
  get MenuItemValue() {
    return localStorage.getItem('a-routes');
  } 

  ////////////////
  set CardPaymentStatusValue(value) {
    this.cardPaymentStatus.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('isCpayAvailable', value);
    // this.myGlobals.isOverdraftAvailable = value;
  }
  get CardPaymentStatusValue() {
    return localStorage.getItem('isCpayAvailable');
  }  

  //////////////////////
  set isReloadTopBarValue(value) {
    this.isReloadTopBar.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('isReloadTopBarValue', value);
    // this.myGlobals.isOverdraftAvailable = value;
  }
  get isReloadTopBarValue() {
    return localStorage.getItem('isReloadTopBarValue');
  } 

  //////////////////////
  set NameValue(value) {
    this.nameValueStatus.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('fullName', value);
    // this.myGlobals.isOverdraftAvailable = value;
  }
  get NameValue() {
    return localStorage.getItem('fullName');
  } 

  //////////////////////
  set RoleValue(value) {
    this.roleValueStatus.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('roleValue', value);
    // this.myGlobals.isOverdraftAvailable = value;
  }
  get RoleValue() {
    return localStorage.getItem('roleValue');
  } 


  set pendingCountValue(value) {
    this.pendingCount.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('pendingCount', value);
  }

  get pendingCountValue() {
    return localStorage.getItem('pendingCount');
  }


}
