// import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { Router } from '@angular/router';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { NgxSpinnerService } from 'ngx-spinner';
// import { ToastrService } from 'ngx-toastr';
// import { ApiService } from 'src/app/core/services/api.service';
// import { ApiCjwService } from 'src/app/core/services/apicjw.service';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {Observable, Subscription } from 'rxjs';
import { apiEndPointsCjw } from 'src/app/core/helpers/api-endpoints-cjw';
import { ApiCjwService } from 'src/app/core/services/apicjw.service';
import { Location, LocationStrategy } from '@angular/common';
import 'rxjs/add/observable/interval';


@Component({
  selector: 'app-hotel-passengers',
  templateUrl: './hotel-passengers.component.html',
  styleUrls: ['./hotel-passengers.component.scss']
})
export class HotelPassengersComponent implements OnInit {

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  searchData;
  hotelData;
  subscription: Subscription[] = [];
  sub:Subscription;
  @Output() changeIndex = new EventEmitter<string>();
  rateRechekResponse;
  @Output() changeStepFunction = new EventEmitter<string>();


  roomData = [];
  passengerForm: FormGroup;
  activeIds: string[] = ['psg-0'];
  formsubmit: Boolean = false;
  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private apiServiceCjw: ApiCjwService,
    // private apiService:ApiService,
    // private router:Router,
    // private location: Location,
    // private modalService: NgbModal,
    // private http: HttpClient,    
    private location: Location,
  ) {
    location.replaceState('hotel-bookings/hotel-passengers')
    this.passengerForm = this.fb.group({
      passengerFormList: this.fb.array([])
    })
  }

  changeStep() {
    this.changeStepFunction.next('id');
  }  


  ngOnInit(): void {
    this.getReleventData();
    this.searchData = JSON.parse(localStorage.getItem('hotel-search'));
    this.hotelData = JSON.parse(localStorage.getItem('hotelDetails'));
    this.rateRechekResponse = JSON.parse(localStorage.getItem('rateRecheckResponse'));
  }

  /**
   * Primary  passenger form will generate from this
   * @returns 
   */
  primaryPassengerFormAddField(): FormGroup {
    return this.fb.group({
      title: ['', [Validators.required]],
      givenName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]+$')]],
      surname: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]+$')]],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required]],
    });
  }

  /**
   * Secondary passenger form will generate from this
   * @returns 
   */
  passengerFormAddField(): FormGroup {
    return this.fb.group({
      title: [''],
      givenName: [''],
      surname: [''],
    });
  }

  /**
   * Child passenger form will generate from this
   * @returns 
   */
  childFormAddField(): FormGroup {
    return this.fb.group({
      title: ['', [Validators.required]],
      givenName: ['', [Validators.required]],
      surname: ['', [Validators.required]],
      age: ['', [Validators.required]]
    });
  }

  passengerFormData(): FormArray {
    return this.passengerForm.get('passengerFormList') as FormArray;
  }

  /**
   * 
   * @param i 
   * @returns 
   */
  counter(i) {
    return new Array(parseInt(i));
  }


  /**
   * Get relevent data to build up the form
   */
  getReleventData() {
    this.roomData = JSON.parse(localStorage.getItem('selectedRoomsData'));
    this.roomData.forEach((room, t) => {
      this.roomData[t].rates.forEach((rate, r) => {
        this.roomData[t].rates[r]['selectedRoomData'] = [];
        for (let i = 0; i < rate.selectedRoomCount; i++) {
          this.roomData[t].rates[r]['selectedRoomData'].push([])
          for (let j = 0; j < (Number.parseInt(rate.maxAdult)); j++) {
            if (j == 0) {
              this.passengerFormData().push(this.primaryPassengerFormAddField());
            } else {
              this.passengerFormData().push(this.passengerFormAddField());
            }
            var data = {
              "index": this.passengerFormData().length - 1,
              "type": "Adult",
              "paxNo":j+1,
            }
            this.roomData[t].rates[r]['selectedRoomData'][i].push(data)
          }
          for (let j = 0; j < (Number.parseInt(rate.maxchild)); j++) {
            this.passengerFormData().push(this.childFormAddField());
            var data = {
              "index": this.passengerFormData().length - 1,
              "type": "Child",
              "paxNo":j+1,
            }
            this.roomData[t].rates[r]['selectedRoomData'][i].push(data)
          }
        }
      });
    })
    // this.checkRateKeys()
  }


  onChange() {
    console.log(this.passengerForm);
  }

  onPassengersSubmit() {
    this.formsubmit = true
    if (this.passengerForm.valid) {
      // console.log(this.roomData);
      // console.log(this.passengerFormData());
      this.spinner.show();
      let incrementedIndex = 0;
      let tempPassengers = [];
      let tempRates = [];
      let tempRooms = [];
      this.roomData.forEach((room, t) => {
        tempRates = [];
        this.roomData[t].rates.forEach((rate, r) => {
          tempPassengers = [];
          for (let i = 0; i < rate.selectedRoomCount; i++) {
            for (let j = 0; j < (Number.parseInt(rate.maxAdult) + Number.parseInt(rate.maxchild)); j++) {
              if (this.passengerFormData().value[incrementedIndex].title != '') {
                let tempPassenger = this.passengerFormData().value[incrementedIndex];
                if (j == 0) {
                  let checkTypeNumber = tempPassenger.phoneNumber;
                  if (checkTypeNumber != 'string') {
                    tempPassenger['phoneNumber'] = tempPassenger.phoneNumber == null || tempPassenger.phoneNumber == "" ? '' : tempPassenger.phoneNumber.e164Number;
                  }
                }
                if (incrementedIndex == 0) {
                  tempPassenger['isPrime'] = 1;
                } else {
                  tempPassenger['isPrime'] = 0;
                }

                if (this.passengerFormData().value[incrementedIndex].hasOwnProperty('age')) {
                  tempPassenger['type'] = 'CH';
                } else {
                  tempPassenger['type'] = 'AD'
                }
                tempPassenger['roomId'] = i + 1;
                tempPassengers.push(tempPassenger);
              }
              incrementedIndex = incrementedIndex + 1;
            }
          }
          tempRates.push(
            {
              rateKey: rate.rateKey,
              board_code: rate.boardCode,
              board_name: rate.boardName,
              no_of_rooms: rate.selectedRoomCount,
              rate_comment: rate.rateComment,
              rate_type: rate.rateClass,
              passengers: tempPassengers,
            }
          )
        });
        tempRooms.push(
          {
            room_code: room.code,
            room_name: room.name,
            rates: tempRates
          }
        )
      })

      let hotelSearchId = JSON.parse(sessionStorage.getItem('hotelSearchId'));
      let fileName = sessionStorage.getItem('fileName');
      let promoDetails = JSON.parse(localStorage.getItem('promoDetails'));
      let sessionIndex = JSON.parse(sessionStorage.getItem('sessionIndex'))
      var finalData = {
        hotel_search_id: hotelSearchId,
        check_in: this.searchData.check_in,
        check_out: this.searchData.check_out,
        hotel_code: this.hotelData.code,
        hotel_name: this.hotelData.name,
        hotel_star: this.hotelData.starRating,
        sessionIndex: sessionIndex,
        promoId: promoDetails[0].pid,
        methodId: promoDetails[0].type[0].id,
        remark: "",
        filename: fileName,
        rooms: tempRooms
      }
      console.log(finalData);
      sessionStorage.setItem('bookingDetails', JSON.stringify(finalData));
      this.subscription.push(this.apiServiceCjw.apiAuthPost(apiEndPointsCjw.bookingDetails, finalData).subscribe(res => {
        if (res.code == 1) {
          this.spinner.hide();
          localStorage.setItem('bookingData',JSON.stringify(res.response))
          if(res.response.is_check == null){
            this.checkBooking(res.response.booking_id);
          }else{
            var steps = {
              "step1": true,
              "step2": true,
              "step3": true,
              "step4": true,
              "step5": false
            }
            sessionStorage.setItem('steps-completed', JSON.stringify(steps))
            sessionStorage.setItem('step', '5');
            sessionStorage.setItem('activeStep', '5');
            sessionStorage.setItem('step5Response', null);
            this.changeIndex.next();   
          }
        } else {
          this.spinner.hide();
          this.toastr.error(res.message, "Error");
        }
      }, error => {
        this.spinner.hide();
      }))
    }
  }
  /**
   * 
   * @param bookingId 
   * This  will send a api call continesly to check whether booking is confirmed.
   */
  checkBooking(bookingId){
    this.spinner.show();
    var data = {
      "booking_id": bookingId
    }

    this.sub = Observable.interval(10000).subscribe((val)=>{
      this.apiServiceCjw.apiAuthPost(apiEndPointsCjw.checkConfimation,data).subscribe(res => {
        if (res.code == 1 || res.code == 4) {
          this.spinner.hide();
          this.sub.unsubscribe();
          var steps = {
            "step1": true,
            "step2": true,
            "step3": true,
            "step4": true,
            "step5": false
          }
          sessionStorage.setItem('steps-completed', JSON.stringify(steps))
          sessionStorage.setItem('step', '5');
          sessionStorage.setItem('activeStep', '5');
          sessionStorage.setItem('step5Response', null);
          this.changeIndex.next();   
        } else if(res.code == 3){
          this.spinner.hide();
          this.sub.unsubscribe();
          this.toastr.error(res.message, "Error");
        } else if(res.code == 0){
          this.spinner.hide();
          this.sub.unsubscribe();
          this.toastr.error(res.message, "Error");          
        }
      }, error => {
        this.spinner.hide();
        this.sub.unsubscribe();
      })   
    })
  }
}


