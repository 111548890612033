<ngx-spinner></ngx-spinner>
<div class="container-fluid">
  <app-page-title title="Hotel Payment"></app-page-title>
  <!-- <div *ngIf="isTimerStart" class="col d-flex justify-content flex-end mb-2" style="justify-content: flex-end;">
    <div class="text-white bg-orange p-2">Remaining time for issuing : <countdown #cd [config]="timeOutConfig"
            (event)="handleEvent($event)"></countdown>
    </div>
  </div> -->
  <div class="card">
    <div class="card-body">
      <ngb-alert [dismissible]="false" type="primary" class="text-center text-white bg-blue" *ngIf="paymentSuccess">
        <p>Your booking was created successfully.</p>
        <p>Booking Reference : {{bookingData.booking_id}}</p>
        <p class="font-size-18 mb-0 pb-0"></p>
        <div class="row d-flex justify-content-center">
          <div class="col-md-2 mt-3 p-3 text-center">
            <button style="background-color: #5cb85c; color: white;" type="submit"
              class="btn btn-primary me-1">
              Booking List
            </button>
            <!-- <span type="submit text-blue font-size-12" style="text-decoration: underline; cursor: pointer;">
                 Issue Ticket
             </span> -->
          </div>
          <div class="col-md-2 mt-3 p-3 text-center">
            <button style="background-color: white;color: black;" type="submit" (click)="onNewSearch()"
              class="btn btn-primary me-1">
              New Search
            </button>
          </div>
        </div>
      </ngb-alert>
      <!-- <div class="row d-flex justify-content-between mb-2" *ngIf="!loading && !paymentSuccess">
        <div class="col-md-12 p-3">
          <div [formGroup]="paymentElementForm">
            <ng-container *ngIf="elementsOptions?.clientSecret">
              <ngx-stripe-payment [appearance]="appearance" [clientSecret]="elementsOptions?.clientSecret">
              </ngx-stripe-payment>
            </ng-container>
            <div class="mt-3 col-12 d-flex justify-content-end">
              <button (click)="pay()" type="button" class="btn btn-primary me-1">
                <span *ngIf="!paying">
                  PAY {{rateRecheckResponse.currency}} <span *ngIf="amount">{{rateRecheckResponse.totalPayable | number
                    :
                    '1.2-2'}}</span>
                </span>
                <span *ngIf="paying">PROCESSING...</span>
              </button>
            </div>
          </div>
        </div>
      </div> -->
      <div class="row m-0 p-0 mb-2">
          <div class="form-check col-3">
              <input name="radio" checked (change)="changePaymentMethod(1)" class="form-check-input" type="radio" id="payment-1" [value]="1">
              <label class="form-check-label optionsBrand" for="payment-1">
                  Wallet
              </label>
          </div>
          <div class="form-check col-3">
              <input name="radio" (change)="changePaymentMethod(2)" class="form-check-input" type="radio" id="payment-2" [value]="2">
              <label class="form-check-label optionsBrand" for="payment-2">
                Request For Approval
              </label>
          </div>
      </div> 
      <div class="card" *ngIf="selectedPaymentMethod == 1">
        <div class="card-body" *ngIf="accountData.length > 0 && !otpBox">
            <div class="col-md-12 d-flex" style="justify-content: space-between;">
                <div class="col-md-7">
                </div>
                <div class="col-md-4" style="align-items: flex-end;">
                    <ngb-alert [dismissible]="false" type="primary" class="text-center" style="font-weight: 600;">
                        Amount payable is {{currency}} {{amount | number : '1.2-2'}}
                    </ngb-alert>
                </div>
            </div>
            <div class="mb-3 mt-3 col-12 d-flex" style="justify-content: space-between;align-items:flex-end;">
                <div class="col-md-7" style="align-items: flex-end;">
                    <div class="alert-success" *ngIf="currentAvailableBalance != null && currentAvailableBalance > 0">
                        Your available balance is LKR {{currentAvailableBalance | number : '1.2-2'}}
                    </div>
                    <div class="alert-danger"
                        *ngIf="currentAvailableBalance != null && currentAvailableBalance <= 0">
                        Your available balance is LKR {{currentAvailableBalance | number : '1.2-2'}}
                    </div>                         
                </div>
                <div class="col-md-4">
                    <div *ngIf="accountData.length > 0">
                        <h6>Select Account<span class="r-star"> *</span></h6>
                        <select class="form-select" placeholder="Select Account" [(ngModel)]="selectedAccount"
                            (change)="onAccountChange($event.target.value)">
                            <option value="" disabled selected>Select Account</option>
                            <option *ngFor="let acc of accountData" value="{{acc.account_id}}">
                                T{{acc.account_id.toString(16)}} - {{acc.account_type}} ({{acc.currency}})</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="mb-3 mb-0 col-12 d-flex"
                style="justify-content: flex-end;">
                <button type="submit"  (click)="onPay()" class="btn btn-primary btn-theme me-1">
                    <span>Continue</span>
                </button>
            </div>
        </div>
        <div class="card-body" *ngIf="otpBox">
            <p class="m-0 p-0">Please enter the 5-digit code which was sent to you by SMS to verify your
                authenticity. OTP Ref {{requestTransactionResponse.otp_id}}</p>
            <!-- <p *ngIf="!isSmsExpired" class="font-size-13">You can request for a new verification code in
                <span class="text-purple">{{timeLeft}}</span> seconds.</p> -->
            <a *ngIf="isSmsExpired" class="text-purple" href="javascript:void(0);"
                (click)="onResendVerificationCode()">Resend verification code</a>
            <form [formGroup]="smsVerifyForm" (ngSubmit)="smsVerifyFormSubmit()">
                <div class="row">
                    <div class="col-sm-6">
                        <label for="horizontal-firstname-input" class="col-form-label">Verification
                            Code<span class="r-star"> *</span></label>
                        <input #smsfocus type="text" formControlName="smsCode"
                            [ngClass]="{'is-invalid': smsSubmit && smsForm.smsCode.errors}"
                            class="form-control" placeholder="Enter Code" />
                        <div *ngIf="smsSubmit && smsForm.smsCode.errors" class="invalid-feedback">
                            <span *ngIf="smsForm.smsCode.errors.required">This value is required.</span>
                            <span *ngIf="smsForm.smsCode.errors?.minlength">This value is invalid.</span>
                        </div>
                    </div>
                    <!-- <div class="col-sm-6">
                        Resend Code
                    </div> -->
                </div>
                <div class="mb-3 mb-0 col-12 d-flex"
                    style="justify-content: flex-end;">
                    <button type="submit" class="btn btn-primary btn-theme me-1">
                        <span>Continue</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div *ngIf="selectedPaymentMethod == 2" class="mb-3 mt-3 col-12 d-flex card">
        <div class="card-body">
            <p>Send a approval request to authorized user.</p>
            <div class="mt-2 mb-0 col-12 d-flex card-body" style="justify-content: flex-end;">
                <button style="margin-left: 12px;" (click)="onRequestForApproval()"
                    class="btn btn-primary btn-theme me-1">
                    Continue
                </button>
            </div>
        </div>
    </div> 
      <app-hotel-booking-summary class="mt-2"></app-hotel-booking-summary>
      <app-hotel-room-summary class="mt-2"></app-hotel-room-summary>
    </div>
  </div>
</div>