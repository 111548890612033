import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Observable } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiCjwService {
  constructor(
    private httpClient:HttpClient,
    private spinner: NgxSpinnerService) 
    {
    // sessionStorage.setItem('access-token1','eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjZiYmFjMTY3YjUyYmNlZDAwYjQ2N2MzODRiMjMwNDE0M2ZmNWI2NDVlY2Y3NThkMDNkNTFmYTQ4ODY4NWNmYzZhNmI2ZDI1ZjU5MDI3MGVmIn0.eyJhdWQiOiIxIiwianRpIjoiNmJiYWMxNjdiNTJiY2VkMDBiNDY3YzM4NGIyMzA0MTQzZmY1YjY0NWVjZjc1OGQwM2Q1MWZhNDg4Njg1Y2ZjNmE2YjZkMjVmNTkwMjcwZWYiLCJpYXQiOjE2MDU4NTE2NTMsIm5iZiI6MTYwNTg1MTY1MywiZXhwIjoxNjM3Mzg3NjUzLCJzdWIiOiIzMSIsInNjb3BlcyI6W119.L4lEQjiuzKood24GXKgcr83OcU4nxDk8Uix4ko31QGSTWcOFU4o13ZrLzok4hwyY1TjpmJWaXHE5T7cNRWyf9_CsXkkmJXWSj0fPTCrLI7uVs3DAriole19BLLCHEQHUcjY_F7VsxClQHN_tiqosPvCsuOLxNyjKixSxaMgE-PoJFfO6vYqrmcYqAVXL9PWV5zqAffdLjZcbq65pHEJqZlc2Z5-a5N5l6mbdoZzDrcm97ZYb8Ka_dlaRJjw4Zrg8fEDMQwAt9056MyMXh9E7RQ2eE-hVplPa2CKti6BDNau9eyyBYbDe3BBxU8wQKuy6GWAt9UPXwstqIWQcW9puMbhtrhsAExrNBLz0tOdu9FpyA-hsjCJHwvg0Cq_wYgdioMAEryZgOVZxsMbhpsPpM5K3GkqS5KjUstC5CVk3xX79j-ODozYhGj4AB1J4u_6f5tDYKxonmSuumr4IUC1tudMA250cngcLrzCgEZNOp-g9woW9do9b9iYqLjrwbj-4F7P9Cvh_xQ3acmUf8gD5EMq061reWavrWY6azWsB3eVq01UrRJgyUehgipYb-22a_-i4CbkAqmjoHM9wltunl1vB48OoAnoBR7P7cd-tdpdtjcrGPc1JAommkV-Hfft-LR1_yquLSWqyT9ALqtEoYhqBBBVrO1nL0AG65Ik4CY4');
   }

  // constructor(private httpClient:HttpClient) { 
  //   sessionStorage.setItem('access-token1','eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjM2YTg5NjE3Yjg5MjdkMjZmNTIzNGYxMTA1M2NlODViM2FmZGM3NDU0NzU2NWU4ZWNkMDhhNDM0M2RiN2M4YTJmYWY3YTMyZDRmYjA5NDVhIn0.eyJhdWQiOiIxIiwianRpIjoiMzZhODk2MTdiODkyN2QyNmY1MjM0ZjExMDUzY2U4NWIzYWZkYzc0NTQ3NTY1ZThlY2QwOGE0MzQzZGI3YzhhMmZhZjdhMzJkNGZiMDk0NWEiLCJpYXQiOjE2MDYxOTY5NTUsIm5iZiI6MTYwNjE5Njk1NSwiZXhwIjoxNjM3NzMyOTU1LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.Rqj_Ss96LVSZpiWl9C79cZ4KW2rwSiIqnXlZzbBZq4a3J7o3e6m7ANzyazbI3XpkAIYseNfYsfVbH3aZ13uhndnWOVqZkN5Y-hOwg75XY9SaHfGCZQ7HtXz9tzl5zLbEwiv-n3EtnT2eqD8KwiaE1UqwdqFuC8RXSd8JN8mHvfZg0u2k4W76jTEA3xuIiPWYTmtIJsLsFlumcVitt6Ycgd7k5bdtNiQZ8jO3PMVFnOzzqAeH6NnyqjF8v6VVP0pDG1j6USy261nhNw_mUy75ERcw3wqNQ-ptV9yA3cUt9RAImjcx2GERl1sSoLG-Qt4bYih2RRIfxAQzMYkZCEbjU00aV8NPcYgNOX5H6N8q5n3hRUYt7bppX_UgXu-BpCBLRUDdc4swTQwh7qB8ZbIL6QZV04sAewkYafsAs-JrPL0rAnkSXHt74VqKqYZdS7QueuQYB4ps-i5NsRG4OThOcUZSA8e0lBoO04B0Pjsm3flSY-nyQjNs5THH0FgKaww-epVSrKEBeRnQycTl7yKSitqUXs54o0RfiVuyQWZQYxZ4ffCZV63JSmHhv7T2ozueyNkZCHu96f38fQWZpsVyTDsqxQs3ppx0luVYsnLBXoioC0_EGAaz3chGJCGvviBhfpGBcB7FWxqpLLJ56cCCjvoQAOIS7P93FpTjk3kXX9w');
  // }

  public apiAuthPost(url,data):any{
    let finalUrl;
    if(localStorage.getItem('country') == "Australia"){
      if (url.indexOf(environment.base_url_cjw) > -1) {
        finalUrl = url.replace(environment.base_url_cjw,environment.base_url_cjw_au)
      }else{
        finalUrl = url;
      }
    }else{
      finalUrl = url;
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${localStorage.getItem('access-token1')}`)
        .set('Content-Type',`application/json`)
        .set('Access-Control-Allow-Origin',`*`)
    }
    // if(data != null){
      return this.httpClient.post(finalUrl,JSON.stringify(data),header);
    // }else{
    //   return this.httpClient.post(url,header);
    // }
  }

  public apiAuthGet(url):any{
    let finalUrl;
    if(localStorage.getItem('country') == "Australia"){
      if (url.indexOf(environment.base_url_cjw) > -1) {
        finalUrl = url.replace(environment.base_url_cjw,environment.base_url_cjw_au)
      }else{
        finalUrl = url;
      }
    }else{
      finalUrl = url;
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${localStorage.getItem('access-token1')}`)
        .set('Content-Type',`application/json`)
        .set('Access-Control-Allow-Origin',`*`)
    }
    return this.httpClient.get(finalUrl,header);
  }

  public apiGet(url):any{
    let finalUrl;
    if(localStorage.getItem('country') == "Australia"){
      if (url.indexOf(environment.base_url_cjw) > -1) {
        finalUrl = url.replace(environment.base_url_cjw,environment.base_url_cjw_au)
      }else{
        finalUrl = url;
      }
    }else{
      finalUrl = url;
    }
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type',`application/json`)
        .set('Access-Control-Allow-Origin',`*`)
    }
    return this.httpClient.get(finalUrl,header);
  }

  public apiPost(data,url):any{
    let finalUrl;
    if(localStorage.getItem('country') == "Australia"){
      if (url.indexOf(environment.base_url_cjw) > -1) {
        finalUrl = url.replace(environment.base_url_cjw,environment.base_url_cjw_au)
      }else{
        finalUrl = url;
      }
    }else{
      finalUrl = url;
    }
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type',`application/json`)
        .set('Access-Control-Allow-Origin',`*`)
    }
    return this.httpClient.post(finalUrl,JSON.stringify(data),header);
  }  


  apiGetIp(finalUrl) : Observable<any> {
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${localStorage.getItem('access-token1')}`)
        .set('Content-Type',`application/json`)
        .set('Access-Control-Allow-Origin',`*`)
    }    
    return this.httpClient.get(finalUrl)
      .pipe(
        timeout(4000)
      );
  }

  apiGetIp1(finalUrl) : Observable<any> {   
    return this.httpClient.get(finalUrl,{responseType:'text'})
  }

}
