
<div class="row">
    <div class="col-xl-3 col-lg-4">
        <div class="lines shine"></div>
        <div class="lines shine"></div>
        <div class="lines shine"></div>  

        <div class="lines shine mt-3"></div>
        <div class="lines shine"></div>
        <div class="lines shine"></div>  

        <div class="lines shine mt-3"></div>
        <div class="lines shine"></div>
        <div class="lines shine"></div>        
    </div>
    <div class="col-xl-9 col-lg-8">
        <div class="box shine"></div>
        <div class="box shine"></div>
        <div class="box shine"></div>
        <div class="box shine"></div>
        <div class="box shine"></div>   
    </div>
</div>
