<!-- ========== Left Sidebar Start ========== -->

<div class="vertical-menu">

  <!-- LOGO -->
  <div class="navbar-brand-box">
    <a [routerLink]="['/']" class="logo logo-dark">
      <span class="logo-sm">
        <img src="assets/images/logo-sm.png" alt="" height="22">
      </span>
      <span class="logo-lg theme-logo">
        ticket gadget
        <!-- <img src="assets/images/logo-dark.png" alt="" height="30"> -->
      </span>
    </a>

    <a [routerLink]="['/']" class="logo logo-light">
      <span class="logo-sm">
        <img src="assets/images/logo-sm.png" alt="" height="22">
      </span>
      <span class="logo-lg">
        <img src="assets/images/logo-light.png" alt="" height="30">
      </span>
    </a>
  </div>
  <button type="button" class="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
    (click)="toggleMobileMenu($event)">
    <i class="fa fa-fw fa-bars"></i>
  </button>
  <ngx-simplebar class="h-100 sidebar-menu-scroll" #componentRef *ngIf="!isCondensed">
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </ngx-simplebar>

  <ngx-simplebar class="sidebar-menu-scroll" #componentRef *ngIf="isCondensed">
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </ngx-simplebar>


  <!-- <ngx-simplebar class="h-100" #componentRef>
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </ngx-simplebar> -->

  <!-- <div id="sidebar-menu">
    <ngx-simplebar class="h-100" *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </ngx-simplebar>
    <ngx-simplebar *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </ngx-simplebar>
    <div class="clearfix"></div>
  </div> -->

</div>

<ng-template #contentTemplate>

  <!--- Sidemenu -->
  <div id="sidebar-menu" *ngIf="!loading">
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
      <ng-container *ngFor="let item of menuItems">
        <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate}}</li>
        <!-- Layouts menu -->
        <li *ngIf="item.isLayout">
          <a href="javascript: void(0);" class="has-arrow">
            <i class="uil-window-section"></i>
            <span> {{'MENUITEMS.FLIGHTS.TEXT' | translate}}</span>
          </a>
          <ul class="sub-menu" aria-expanded="false">
            <li><a (click)="changeLayout('horizontal')" href="javascript: void(0);"> {{
                'MENUITEMS.FLIGHTS.LIST.ISSUETICKET' | translate}} </a></li>
            <li><a (click)="darkSidebar()" href="javascript: void(0);">{{ 'MENUITEMS.FLIGHTS.LIST.TICKETLIST' |
                translate }} </a></li>
            <li><a (click)="compactSidebar()" href="javascript: void(0);">{{ 'MENUITEMS.FLIGHTS.LIST.BOOKINGQUEUE' |
                translate}} </a></li>
          </ul>
        </li>
        <!-- end Layout menu -->

        <!--Australia Menu Start-->
        <!-- <li *ngIf="!item.isTitle && !item.isLayout && countryName =='AU'">
          <a *ngIf="hasItems(item) && (item.id == 7 || item.id == 12 || item.id == 16 || item.id == 32 || item.id == 33 || item.id == 37)" href="javascript:void(0);" class="is-parent"
            [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
            <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
            <span> {{ item.label | translate}}</span>
            <span class="badge rounded-pill bg-{{item.badge.variant}} float-end" *ngIf="item.badge">{{item.badge.text |
              translate}}</span>
          </a>

          <a id="dashboard" [routerLink]="item.link" *ngIf="!hasItems(item) && (item.id == 6 || item.id == 19 || item.id == 32 || item.id == 33 || item.id == 37)" class="side-nav-link-ref" routerLinkActive="active">
            <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
            <span> {{ item.label | translate}}</span> 
            <span class="badge rounded-pill bg-{{item.badge.variant}} float-end" *ngIf="item.badge">{{item.badge.text |
              translate}}</span>
          </a>
          <a id="dashboard" [routerLink]="item.link" *ngIf="!hasItems(item) && isQuotes && item.id == 40 && item.id != 21  && item.id != 34 && item.id != 38 && item.id != 39" class="side-nav-link-ref" routerLinkActive="active">
            <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
            <span> {{item.label | translate}}</span>
            <span class="badge rounded-pill bg-{{item.badge.variant}} float-end" *ngIf="item.badge">{{item.badge.text |
              translate}}</span>
          </a>
          <ul *ngIf="hasItems(item) && (item.id == 7 || item.id == 12 || item.id == 16)" class="sub-menu" aria-expanded="false">
            <li *ngFor="let subitem of item.subItems">
              <a *ngIf="subitem.parentId==22 && !hasItems(subitem)" [routerLink]="[subitem.link,subitem.param]"
                class="side-nav-link-ref" [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                {{ subitem.label | translate}}
                <span *ngIf="subitem.id == 25 && refundCount != 0"  class="badge rounded-pill bg-danger float-end">
                  {{refundCount}}
                </span>
              </a>
              <a [routerLink]="subitem.link" *ngIf="subitem.parentId!=22 && !hasItems(subitem) && subitem.id != 8 && subitem.id != 14 && subitem.id != 11" class="side-nav-link-ref"
                [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                {{ subitem.label | translate}}
              </a>
              <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                [attr.data-parent]="subitem.parentId">
                {{ subitem.label | translate}}
              </a>
              <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                <li *ngFor="let subSubitem of subitem.subItems">
                  <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link" routerLinkActive="active"
                    class="side-nav-link-ref">
                    {{ subSubitem.label | translate }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li> -->
        <!--Australia Menu End-->


        <li *ngIf="!item.isTitle && !item.isLayout">
          <a *ngIf="hasItems(item) && checkAvailableMenuItem(item)" href="javascript:void(0);" class="is-parent"
            [ngClass]="{'has-arrow': (!item.badge || (item.id == 22 && refundCount == 0) || (item.id == 12 && pendingCount == 0)), 'has-dropdown': item.badge}">
            <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
            <span> {{ item.label | translate}}</span>
            <span class="badge rounded-pill bg-{{item.badge.variant}} float-end" *ngIf="item.badge">{{item.badge.text |
              translate}}</span>
            <span *ngIf="item.id == 22 && refundCount != 0"  class="badge rounded-pill bg-danger float-end">
              {{refundCount}}
            </span>
            <span *ngIf="item.id == 12 && pendingCount != 0"  class="badge rounded-pill bg-danger float-end">
              {{pendingCount}}
            </span>
          </a>

          <a [routerLink]="item.link" *ngIf="!hasItems(item) && menuList && menuList.includes(item.link)" class="side-nav-link-ref" routerLinkActive="active">
            <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
            <span> {{ item.label | translate}}</span>
            <span class="badge rounded-pill bg-{{item.badge.variant}} float-end" *ngIf="item.badge">{{item.badge.text |
              translate}}</span>
              <span class="badge rounded-pill bg-danger float-end" *ngIf="item.id == 39 && isCritical">Critical</span>
          </a>

          <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
              <li *ngFor="let subitem of item.subItems">
                <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem) && checkAvailableSubMenuItem(subitem)" class="side-nav-link-ref"
                  [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                  {{ subitem.label | translate}}
                  <span *ngIf="subitem.id == 25 && refundCount != 0"  class="badge rounded-pill bg-danger float-end">
                    {{refundCount}}
                  </span>
                  <span *ngIf="subitem.id == 70 && pendingCount != 0"  class="badge rounded-pill bg-danger float-end">
                    {{pendingCount}}
                  </span>                 
                </a>
                <!-- <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                  [attr.data-parent]="subitem.parentId">
                  {{ subitem.label | translate}}
                </a>
                <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                  <li *ngFor="let subSubitem of subitem.subItems">
                    <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link" routerLinkActive="active"
                      class="side-nav-link-ref">
                      {{ subSubitem.label | translate }}
                    </a>
                  </li>
                </ul> -->
              </li>
          </ul>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>
<!-- Sidebar -->

<!-- Left Sidebar End -->