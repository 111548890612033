<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body full-width row d-flex justify-content-around">
          <div class="main-div">
            <!-- [ngClass]="{1 : 'circle-active', 2 : 'circle-default', 3 : 'circle-success'}[status]" -->
            <div (click)="onStepHeaderClick('1')" [ngClass]="stepsCompleted.step1 == true ? 'circle-success' : ( currentStep == '1' || activeStep == '1' ?  'circle-active':'circle-default')">
              <span>Step 1</span>
            </div>
            <span>Search</span>
          </div>

          <div class="default-line"></div>

          <div class="main-div">
            <div (click)="onStepHeaderClick('2')" [ngClass]="stepsCompleted.step2 == true ? 'circle-success' : ( currentStep == '2' || activeStep == '2'  ? 'circle-active':'circle-default')">
              <span>Step 2</span>
            </div>
            <span>Hotels</span>
          </div>

          <div class="default-line"></div>

          <div class="main-div">
            <div (click)="onStepHeaderClick('3')" [ngClass]="stepsCompleted.step3 == true ? 'circle-success' : ( currentStep == '3' || activeStep == '3' ? 'circle-active':'circle-default')">
              <span>Step 3</span>
            </div>
            <span>Rooms</span>
          </div>

          <div class="default-line"></div>

          <div class="main-div">
            <div (click)="onStepHeaderClick('4')" [ngClass]="stepsCompleted.step4 == true ? 'circle-success' : ( currentStep == '4'  || activeStep == '4' ? 'circle-active':'circle-default')">
              <span>Step 4</span>
            </div>
            <span>Book</span>
          </div>

          <div class="default-line"></div>

          <div class="main-div">
            <div (click)="onStepHeaderClick('5')" [ngClass]="stepsCompleted.step5 == true ? 'circle-success' : ( currentStep == '5' || activeStep == '5' ? 'circle-active':'circle-default')">
              <span>Step 5</span>
            </div>
            <span>Confirmation</span>
          </div>
        </div>

        <app-hotel-payment (changeStepFunction) = "onStepHeaderClick('1')" (changeIndex) = "changeIndex()" *ngIf="currentStep == '5'"></app-hotel-payment>
      
        <app-hotel-passengers (changeStepFunction) = "onStepHeaderClick('1')" (changeIndex) = "changeIndex()" *ngIf="currentStep == '4'"></app-hotel-passengers>
        
        <app-hotel-book (changeStepFunction) = "onStepHeaderClick('1')" (changeIndex) = "changeIndex()" *ngIf="currentStep == '3'"></app-hotel-book>
        
        <app-hotel-results (changeStepFunction) = "onStepHeaderClick('1')" (changeIndex) = "changeIndex()" *ngIf="currentStep == '2'"></app-hotel-results>

        <app-hotel-search  (changeIndex) = "changeIndex()" *ngIf="currentStep == '1'"></app-hotel-search>


        <!-- <div class="card-body full-width">
          <ng-wizard [config]="wizardConfig" (stepChanged)="stepChanged($event)">
            <ng-wizard-step  [title]="'Step 1'" [description]="'Search'" [canEnter]="isValidTypeBoolean" [state]="step1States">
              <ng-template [ngTemplateOutlet]="Search"></ng-template>
            </ng-wizard-step>

            <ng-wizard-step [title]="'Step 2'" [description]="'Results'" [state]="step2States">
              <ng-template [ngTemplateOutlet]="Results"></ng-template>
            </ng-wizard-step>

            <ng-wizard-step [title]="'Step 3'" [description]="'Review'" [state]="step3States">
              <ng-template [ngTemplateOutlet]="Review"></ng-template>
            </ng-wizard-step>

            <ng-wizard-step [title]="'Step 4'" [description]="'Book'"  [canEnter]="canEnter" [state]="step4States">
              <ng-template [ngTemplateOutlet]="Booking"></ng-template>
            </ng-wizard-step>

            <ng-wizard-step [title]="'Step 5'" [description]="'Confirmation'" [state]="step5States">
              <ng-template [ngTemplateOutlet]="Reference"></ng-template>
            </ng-wizard-step>
          </ng-wizard>
        </div> -->
      </div>
    </div>
  </div>  
</div>

<ng-template #Reference>
  <app-flight-pay *ngIf="currentStep == '5'"  (resetEvent)="resetWizard($event)" (someEvent)="showNextStep($event)"></app-flight-pay>
</ng-template>

<ng-template #Booking>
  <app-flight-passengers *ngIf="currentStep == '4'"  (resetEvent)="resetWizard($event)" (someEvent)="showNextStep($event)"></app-flight-passengers>
</ng-template>

<ng-template #Review>
  <app-flight-review *ngIf="currentStep == '3'"  (resetEvent)="resetWizard($event)" (someEvent)="showNextStep($event)"></app-flight-review>
</ng-template>

<ng-template #Results>
  <app-flight-result *ngIf="currentStep == '2'" (resetEvent)="resetWizard($event)" (someEvent)="showNextStep($event)"></app-flight-result>
</ng-template>

<!-- <ng-template #Search> -->
<!-- </ng-template> -->

<ng-template #confirmDetail>
  <div class="tab-pane" id="confirm-detail">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center">
          <div class="mb-4">
            <i class="mdi mdi-check-circle-outline text-success display-4"></i>
          </div>
          <div>
            <h5>Confirm Detail</h5>
            <p class="text-muted">If several languages coalesce, the grammar of the resulting</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>