import { environment } from "src/environments/environment";

const base_url = environment.base_url_cjw;
const base_url_ndc = environment.base_url_ndc
const base_url_fits_air = environment.base_url_fits_air
const base_url_sq_ndc = environment.base_url_sq_ndc
const base_url_ek_ndc = environment.base_url_ek_ndc
const base_url_g9 = environment.base_url_g9


const base_url_hotel = environment.base_url_hotel;

export const apiEndPointsCjw = {
    //flightSection
    smsVerify:`${base_url}auth/login/tg`,
    getInstanceId:`${base_url}save/client/data`,
    getSearchResults:`${base_url}tg/search/results`,
    getFlightDataById:`${base_url}selected/fare/result`,
    getDataBySelectedIndex:`${base_url}tg/send/reprice/request`,
    onBookingValidate:`${base_url}send/booking/tg/validate`,
    onBookingValidate2:`${base_url}send/booking/tg/validate/v2`,
    getPaymentMethod:`${base_url}tg/payment/methods`,
    onPaymentValidate:`${base_url}tg/initiate/payment`,
    checkBookingStatus:`${base_url}check/booking/status`,

    ///NDC 
    getSearchResultsNdc:`${base_url_ndc}tg/low/fare/search/request`,
    getFlightDataByIdNdc:`${base_url_ndc}selected/fare/result`,
    getDataBySelectedIndexNdc:`${base_url_ndc}tg/offer/price/request`,
    onBookingValidateNdc:`${base_url_ndc}order/create/request`,   

    ///FitsAir
    getSearchResultsFitsAir:`${base_url_fits_air}tg/low/fare/search/request`,
    getFlightDataByIdFitsAir:`${base_url_fits_air}selected/fare/result`,
    getDataBySelectedIndexFitsAir:`${base_url_fits_air}tg/offer/price/request`,
    onBookingValidateFitsAir:`${base_url_fits_air}order/create/request`,      

    ///SQ-NDC Policy endpoints
    getSearchResultsSqNdc:`${base_url_sq_ndc}tg/low/fare/search/request`,
    getFlightDataByIdSqNdc:`${base_url_sq_ndc}selected/fare/result`,
    getDataBySelectedIndexSqNdc:`${base_url_sq_ndc}tg/offer/price/request`,
    onBookingValidateSqNdc:`${base_url_sq_ndc}order/create/request`,  
    
    ///EK-NDC Policy endpoints
    getSearchResultsEkNdc:`${base_url_ek_ndc}tg/low/fare/search/request`,
    getFlightDataByIdEkNdc:`${base_url_ek_ndc}selected/fare/result`,
    getDataBySelectedIndexEkNdc:`${base_url_ek_ndc}tg/offer/price/request`,
    onBookingValidateEkNdc:`${base_url_ek_ndc}order/create/request`,

    ///EK-NDC Policy endpoints
    getSearchResultsG9:`${base_url_g9}tg/low/fare/search/request`,
    getFlightDataByIdG9:`${base_url_g9}selected/fare/result`,
    getDataBySelectedIndexG9:`${base_url_g9}tg/offer/price/request`,
    onBookingValidateG9:`${base_url_g9}order/create/request`,

    
    //hotelSection
    getCities:`${base_url_hotel}getcities`,
    getHotelSearchResults:`${base_url_hotel}search`,
    getPromo:`${base_url_hotel}getPromoTG`,
    hotelFilters:`${base_url_hotel}hotelFilters`,
    getPromoPrices:`${base_url_hotel}getPromop`,
    getFilterdData:`${base_url_hotel}hotel/filter`,
    getHotelDetails:`${base_url_hotel}hotelDetails`,
    getRoomDetails:`${base_url_hotel}roomMultipleSearch`,
    rateRecheck:`${base_url_hotel}rateRecheck_v2`,
    bookingDetails:`${base_url_hotel}hotel/v2/booking-details`,
    createReference:`${base_url_hotel}hotel/v2/payment-process`,
    checkConfimation:`${base_url_hotel}hotel/booking/check-confirmation`,
    createPaymentIntent:`${base_url_hotel}hotel/v2/payment-inital`,
    initiatePayement:`${base_url_hotel}hotel/v3/payment-inital`
}