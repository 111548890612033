<div id="snackbar" (click)="refreshDashboard()" [ngClass]="isShowUpdate == true ? 'snackbarShow' : 'snackbarHide'"  style="font-size: 13px;">
  A new version of the dashboard is  available.
  <a class="btn btn-primary btn-theme" style="font-size: 10px;padding: 7px;">REFRESH</a>
  <i class="uil-times" style="padding-left: 5px;padding-top: 18px; font-size: 20px;cursor: pointer;"></i>
</div>
<header id="page-topbar">
  <div>
    <div class="row m-0 p-3 banner" *ngIf="showBanner">
      <div style="width: 10%;" class="close text-left" (click)="onClickOnClose()">
        <i class="fas fa-times"></i>
      </div>
      <div style="width: 20%;">
        <img src="../../../assets/images/logo-sm.png" alt="logo-sm.png" class="img-fluid" width="45" height="45">
      </div>
      <div style="width: 50%;">
        <p class="m-0">Ticket Gadget</p>
        <p class="m-0" style="font-size: 11px;color: rgb(194, 192, 192);">{{priceDesc}}</p>
      </div>
      <div style="width: 20%;" class="text-right">
        <button class="btn view-btn" (click)="onViewClick()">View</button>
      </div>
    </div>   
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a routerLink="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="assets/images/logo-sm.png" alt="" height="22">
            </span>
            <span class="logo-lg img-height">
              <img src="assets/images/logo-dark.png" alt="">
            </span>
          </a>
  
          <a routerLink="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="assets/images/logo-sm.png" alt="" height="22">
            </span>
            <span class="logo-lg img-height">
              <img src="assets/images/logo-light.png" alt="" height="30">
            </span>
          </a>
        </div>
  
        <button type="button"  class="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn" id="vertical-menu-btn"
          (click)="toggleMobileMenu($event)">
          <i class="fa fa-fw fa-bars"></i>
        </button>
  
        <!-- App Search-->
        <!-- <form class="app-search d-none d-lg-block">
          <div class="position-relative">
            <input type="text" class="form-control" placeholder="{{'HEADER.SEARCH' | translate}}">
            <span class="uil-search"></span>
          </div>
        </form> -->
  
      </div>
  
      <div class="d-flex" *ngIf="user != undefined && isReloadTopBar">
  
        <!-- <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
          <button type="button" class="btn header-item noti-icon waves-effect" id="page-header-search-dropdown"
            data-toggle="dropdown" aria-haspopup="true" ngbDropdownToggle aria-expanded="false">
            <i class="mdi mdi-magnify"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu
            aria-labelledby="page-header-search-dropdown">
  
            <form class="p-3">
              <div class="form-group m-0">
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                  <div class="input-group-append">
                    <button class="btn btn-primary btn-theme" type="submit"><i class="mdi mdi-magnify"></i></button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div> -->
  
        <div *ngIf="actions?.regionChange" class="dropdown d-inline-block language-switch" ngbDropdown>
          <button type="button" class="btn header-item waves-effect" id="page-header-user-dropdown" ngbDropdownToggle>
            <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16"><span
            class="ms-1">{{countryName}}</span>
            <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
            <span *ngIf="flagvalue === undefined" class="ms-1">{{countryName}}</span>
            <i class="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <!-- item-->
            <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
              (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
              <img src="{{item.flag}}" alt="user-image" class="me-1" height="12">
              <span class="align-middle">{{item.text}}</span>
            </a>
            <!-- item-->
          </div>
        </div>
  
        <!-- <div class="dropdown d-none d-lg-inline-block ms-1" ngbDropdown>
          <button type="button" class="btn header-item noti-icon waves-effect" ngbDropdownToggle>
            <i class="uil-apps"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end" ngbDropdownMenu>
            <div class="px-lg-2">
              <div class="row no-gutters">
                <div class="col">
                  <a class="dropdown-icon-item" href="javascript: void(0);">
                    <img src="assets/images/brands/github.png" alt="Github">
                    <span>GitHub</span>
                  </a>
                </div>
                <div class="col">
                  <a class="dropdown-icon-item" href="javascript: void(0);">
                    <img src="assets/images/brands/bitbucket.png" alt="bitbucket">
                    <span>Bitbucket</span>
                  </a>
                </div>
                <div class="col">
                  <a class="dropdown-icon-item" href="javascript: void(0);">
                    <img src="assets/images/brands/dribbble.png" alt="dribbble">
                    <span>Dribbble</span>
                  </a>
                </div>
              </div>
  
              <div class="row no-gutters">
                <div class="col">
                  <a class="dropdown-icon-item" href="javascript: void(0);">
                    <img src="assets/images/brands/dropbox.png" alt="dropbox">
                    <span>Dropbox</span>
                  </a>
                </div>
                <div class="col">
                  <a class="dropdown-icon-item" href="javascript: void(0);">
                    <img src="assets/images/brands/mail_chimp.png" alt="mail_chimp">
                    <span>Mail Chimp</span>
                  </a>
                </div>
                <div class="col">
                  <a class="dropdown-icon-item" href="javascript: void(0);">
                    <img src="assets/images/brands/slack.png" alt="slack">
                    <span>Slack</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> -->
  
        <!-- <div class="dropdown d-none d-lg-inline-block ms-1">
          <button type="button" class="btn header-item noti-icon waves-effect" data-toggle="fullscreen"
            (click)="fullscreen()">
            <i class="uil-minus-path"></i>
          </button>
        </div> -->

        <!-- <div class="dropdown d-none d-lg-inline-block ms-1">
          <button type="button" routerLink="/notifications" class="btn header-item noti-icon waves-effect">
            <i class="fas fa-bell"></i>
            <span class="inner">{{notifyCount}}</span>
          </button>
        </div>  -->
        
        <div class="dropdown d-inline-block"  (openChange)="onExpanded($event)"  ngbDropdown #notify="ngbDropdown" >
          <button type="button" class="btn header-item noti-icon waves-effect" id="page-header-notifications-dropdown"
            ngbDropdownToggle>
            <i class="uil-bell"></i>
            <span *ngIf="notifyCount > 0" class="badge bg-danger rounded-pill">{{notifyCount}}</span>
          </button>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
            aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
            <div class="p-3">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="m-0 font-size-16">{{ 'HEADER.NOTIFICATIONS.TITLE' | translate}}</h6>
                </div>
                <div class="col-auto">
                  <!-- <a href="javascript: void(0);" class="small" (click)="markAllAsRead()">{{ 'HEADER.NOTIFICATIONS.VIEW_ALL' | translate}}</a> -->
                </div>
              </div>
            </div>
            <ngx-simplebar style="position: relative; height: 300px;">
              <div *ngIf="!loading">
                <div class="text-reset notification-item w-100" *ngFor="let item of notification;let i = index" (click)="changeNotificationStatus(item.id,i); $event.stopPropagation()">
                  <div class="media" [ngClass]="item.notification_inbox_status != 1?'unread':'read'">
                    <div class="avatar-xs me-1" style="align-self:center;display: flex;align-items: center;">
                      <span class="rounded-circle font-size-16">
                        <div *ngIf="item.notification_inbox_status != 1" class="circle">
                        </div>
                        <!-- <img style="height: 35px;width: 35px;" src="../../../assets/images/logo-sm.png"/> -->
                      </span>
                    </div>
                    <div class="media-body w-100">
                      <h6 class="mt-0 mb-1">{{item.title}}</h6>
                      <div class="font-size-12 text-muted">
                        <p class="mb-1">{{item.notificationBody}}</p>
                        <p class="mb-0" style="text-align: end;"><i class="mdi mdi-clock-outline"></i> {{item.created_at | dateAgo}}</p>
                      </div>                      
                    </div>
                  </div>
                  <hr class="m-0 p-0" style="color: grey;">
                </div>
              </div>
              <div *ngIf="loading">
                <img height="250"  src="../../../assets/images/notify-loading.gif"/>
              </div>
              <div *ngIf="!loading && notification.length == 0" class="text-center">
                <img height="250"  src="../../../assets/images/no-notify.png"/>
                <p style="font-size: 12;font-weight: 600;">Stay tuned! No notification yet.</p>
              </div>
            </ngx-simplebar>
            <div class="p-2 border-top d-grid" *ngIf="remainingNotifyCount > 0">
              <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)" (click)="getPaginationDataByApi()">
                <i class="uil-arrow-circle-right me-1"></i> {{ 'HEADER.NOTIFICATIONS.LOAD_MORE' | translate}}
              </a>
            </div>
          </div>
        </div>

        <div class="dropdown d-lg-inline-block ms-1" *ngIf="actions?.cart && isQuotes">
          <button routerLink="/profile" type="button" class="btn header-item noti-icon waves-effect" data-toggle="fullscreen"
            (click)="onQuotes()">
            <i class="uil-file-alt"></i>
            <span *ngIf="quotesCount > 0" [ngClass]="{'reel': isZoomLoading}" class="badge bg-danger rounded-pill">{{quotesCount}}</span>
          </button>
        </div>

        <div class="dropdown d-inline-block" ngbDropdown>
          <button type="button" class="btn header-item waves-effect" ngbDropdownToggle id="page-header-user-dropdown">
            <!-- <img class="rounded-circle header-profile-user" src="assets/images/logo-sm.png" alt="Header Avatar"> -->
            <span class="d-none d-xl-inline-block ms-1 fw-medium font-size-15">{{userName}}</span>
            <span><i class="uil-angle-down d-xl-inline-block font-size-15"></i></span>
            <p class="user-role">({{userRole}})</p>
          </button>
          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <!-- item-->
            <a *ngIf="countryName != 'Australia'" routerLink="/profile" class="dropdown-item" href="javascript: void(0);">
              <i class="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
              <span class="align-middle">{{ 'HEADER.LOGIN.PROFILE' | translate}}</span>
            </a>
            <a *ngIf="actions?.user" routerLink="/secondary-user" class="dropdown-item" href="javascript: void(0);">
              <i class="uil uil-users-alt font-size-18 align-middle me-1 text-muted"></i>
              <span class="align-middle">Users</span>
            </a>             
            <a *ngIf="countryName != 'Australia' && actions?.pccs" routerLink="/pcc" class="dropdown-item" href="javascript: void(0);">
              <i class="uil uil-wallet font-size-18 align-middle me-1 text-muted"></i>
              <span class="align-middle">{{ 'HEADER.LOGIN.PCC' | translate}}</span>
            </a>  
            <a *ngIf="isBadgeEnable && countryName != 'Australia' && actions?.Badges" routerLink="/badges" class="dropdown-item d-block" href="javascript: void(0);">
              <i class="uil uil-award font-size-18 align-middle me-1 text-muted"></i>
              <span class="align-middle">Badges</span>
            </a>
            <a class="dropdown-item" href="javascript: void(0);" (click)="logout()">
              <i class="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
              <span class="align-middle">{{ 'HEADER.LOGIN.LOGOUT' | translate}}</span>
            </a>
          </div>
        </div>
  
        <!-- <div class="dropdown d-inline-block">
          <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="toggleRightSidebar()">
            <i class="uil-cog"></i>
          </button>
        </div> -->
  
      </div>
    </div>    
  </div>
</header>

<ng-template #fb role="document" let-modal>
  <div class="modal-header">
      <button type="button" class="btn-close" aria-hidden="true" (click)="onCloseSmsModel()"></button>
  </div>
  <div class="modal-body" style="text-align: center;height: 500px;padding: 1px!important;">
      <div style="position: relative; width: 100%;height: 900px; padding-top: 56.25%;">
          <iframe [src]='fbUrl' style="border:none;overflow:scroll; width:100%; height:100%; position:absolute; top: 0; left: 0;" scrolling="yes" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
          </div>
  </div>
</ng-template>