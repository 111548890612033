<div class="accordionpage">
    <ngb-accordion #acc="ngbAccordion" [closeOthers]="true">
        <ngb-panel id="static-1">
            <ng-template ngbPanelTitle>
                <div class="row">
                    <div class="col-6 d-flex align-items-center text-left" style="justify-content: flex-start;">
                        <h6>Room Summary</h6>
                    </div>
                    <div class="col-6 d-flex align-items-center text-right" style="justify-content: flex-end;">
                        <i class="fas fa-chevron-down"></i>
                    </div>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <div *ngFor="let room of roomData" class="mt-2">
                    <p class="mb-0 boardName">{{room.name}}</p>
                    <div *ngFor="let rate of room.rates" class="d-flex justify-content-between p-2">
                        <div class="roomSummary">
                            <span class="content">{{rate.boardName}} x {{rate.selectedRoomCount}}</span>
                        </div>
                        <div>
                            <span *ngFor="let s of counter(rate.maxAdult); let i = index"
                                style="padding-left: 5px;font-weight: 400;">
                                <span *ngIf="rate.maxAdult != 0"><i class="fas fa-user" style="color: #f1734f;"></i></span>
                            </span>
                            <span *ngFor="let s of counter(rate.maxchild); let i = index"
                            style="padding-left: 5px;font-weight: 400;">
                            <span *ngIf="rate.maxchild != 0"><i class="fas fa-user"
                                    style="color: #f1734f;font-size: 9px;"></i></span>
                            </span> 
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>
