import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 6,
        label:'Dashboard',
        icon: 'uil-home-alt',
        link:'/dashboard',
    },
    {
        id: 40,
        label: 'Quotes List',
        icon: 'uil-money-bill',
        link: '/quotes-list',
    },
    {
        id: 7,
        label: 'Flight Tickets',
        icon: 'uil-ticket',
        subItems: [
            {
                id: 8,
                label: 'Issue Flight Ticket',
                link: '/ticketmanagement/issue-ticket',
                parentId: 7
            },
            {
                id: 9,
                label: 'Flight Ticket List',
                link: '/ticketmanagement/issue-ticket-list',
                parentId: 7
            },
            {
                id: 11,
                label: 'Ticket Documents',
                link: '/ticketmanagement/ticket-documents',
                parentId: 7
            },
        ]
    },
    {
        id: 12,
        label: 'Flight Bookings',
        icon: 'uil-search',
        badge:true,
        subItems: [
            {
                id: 13,
                label: 'Flight Search',
                link: '/flightmanagement/flight-search',
                parentId: 11
            },
            {
                id: 14,
                label: 'Advanced Flight Search',
                link: '/flightmanagement/advance-flight-search',
                parentId: 11
            },
            {
                id: 15,
                label: 'Flight Booking List',
                link: '/flightmanagement/flight-booking-list',
                parentId: 11
            },
            {
                id: 70,
                label: 'Pending Approval List',
                link: '/flightmanagement/request-for-approval-list',
                parentId: 11,
                badge:true,
            },
        ]
    },
    {
        id: 40,
        label: 'Travel Insurance',
        icon: 'uil-shield-check',
        subItems: [
            {
                id: 41,
                label: 'Get Quote/Insurance',
                link: '/insurancemanagement/insurance-search',
                parentId: 40
            },
            {
                id: 42,
                label: 'Insurance List',
                link: '/insurancemanagement/insurance-list',
                parentId: 40
            },
            {
                id: 43,
                label: 'Pending Approval List',
                link: '/insurancemanagement/pending-approval-list',
                parentId: 40
            },
        ]
    },
    {
        id: 112,
        label: 'Hotel Bookings',
        icon: 'uil-search',
        subItems: [
            {
                id: 113,
                label: 'Hotel Search',
                link: '/hotelmanagement/hotel-search',
                parentId: 112
            },
        ]        
    },
    {
        id: 16,
        label: 'Reports',
        icon: 'uil-document-info',
        subItems: [
            {
                id: 17,
                label: 'Transactions',
                link: '/reportmanagement/transaction',
                parentId: 15
            },
            {
                id: 1018,
                label: 'Transaction Overview',
                link: '/reportmanagement/transaction-overview',
                parentId: 15
            },
        ]
    },
    {
        id: 19,
        label: 'Top Up',
        icon: 'uil-money-bill',
        link: '/topup',
    },
    {
        id: 39,
        label: 'ADM',
        icon: 'uil-usd-square',
        link:'/adm',
        badge:true
    },    
    {
        id: 20,
        label: 'Card Payments',
        icon: 'uil-card-atm',
        link: '/card-payment',
    },
    {
        id: 21,
        label:'Overdraft',
        icon: 'uil-0-plus',
        link:'/overdraft',
    },
    {
        id: 22,
        label: 'Refunds',
        icon: 'uil-dollar-alt',
        badge:true,
        subItems: [
            {
                id: 23,
                label: 'All Refunds',
                link: "/refund-requests/all",
                param:'all',
                parentId: 22
            },
            {
                id: 24,
                label: 'Open',
                link: '/refund-requests/open',
                param:'open',
                parentId: 22
            },
            {
                id: 25,
                label: 'Awaiting User Approval',
                link: '/refund-requests/awaiting-user-approval',
                param:'awaiting-user-approval',
                parentId: 22
            },
            {
                id: 26,
                label: 'Approved',
                link: '/refund-requests/approved',
                param:"approved",
                parentId: 22
            },
            {
                id: 27,
                label: 'Awaiting Airline Settlement',
                link: '/refund-requests/awaiting-airline-settlement',
                param:'awaiting-airline-settlement',
                parentId: 22
            },
            {
                id: 28,
                label: 'On-hold',
                link: '/refund-requests/on-hold',
                param:'on-hold',
                parentId: 22
            },
            {
                id: 29,
                label: 'Resolved',
                link: '/refund-requests/resolved',
                param:'resolved',
                parentId: 22
            },
            {
                id: 30,
                label: 'Declined',
                link: '/refund-requests/declined',
                param:'declined',
                parentId: 22
            },          
            {
                id: 31,
                label: 'Expired',
                link: '/refund-requests/expired',
                param:'expired',
                parentId: 22
            },     
        ]
    },
    {
        id: 32,
        label: 'Fees',
        icon: 'uil-clipboard-notes',
        link:'/fees'
    },
    {
        id: 33,
        label: 'Announcements',
        icon: 'uil-notes',
        link:'/announcement'
    },
    {
        id: 34,
        label: 'Reissue Quotation',
        icon: 'uil-file-contract-dollar',
        subItems: [
            {
                id: 35,
                label: 'Reissue Quotation',
                link: '/reissue-quote',
                parentId: 34
            },
            {
                id: 36,
                label: 'Reissue Quotation List',
                link: '/reissue-requests',
                parentId: 34
            },            
        ]
    },    
    {
        id: 38,
        label: 'Reissues',
        icon: 'uil-file-contract-dollar',
        subItems:[
            {
                id: 201,
                label: 'Request Reissue Quote',
                link:'/reissue-quotation/quote',
                parentId: 38
            },
            {
                id: 202,
                label: 'All Reissues',
                link: "/reissue-quotation-list/all",
                param:'all',
                parentId: 38
            },
            {
                id: 203,
                label: 'Open',
                link: '/reissue-quotation-list/open',
                param:'open',
                parentId: 38
            },
            {
                id: 204,
                label: 'In Progress',
                link: '/reissue-quotation-list/in-progress',
                param:'open',
                parentId: 38
            },
            {
                id: 205,
                label: 'Awaiting User Approval',
                link: '/reissue-quotation-list/awaiting-user-approval',
                param:'awaiting-user-approval',
                parentId: 38
            },
            {
                id: 206,
                label: 'Approved',
                link: '/reissue-quotation-list/approved',
                param:"approved",
                parentId: 22
            },
            {
                id: 207,
                label: 'Resolved',
                link: '/reissue-quotation-list/resolved',
                param:'resolved',
                parentId: 38
            },
            {
                id: 208,
                label: 'Declined',
                link: '/reissue-quotation-list/declined',
                param:'declined',
                parentId: 38
            },          
            {
                id: 209,
                label: 'Expired',
                link: '/reissue-quotation-list/expired',
                param:'expired',
                parentId: 38
            },            
        ]
    },
    {
        id: 37,
        badge:true,
        label: 'FAQs',
        icon: 'uil-cloud-question',
        link:'/faq'
    },
];

