<ngx-spinner></ngx-spinner>
<div class="container-fluid">
    <div class="card" (click)="changeStep()" style="cursor: pointer;">
        <div class="row m-0 p-2">
            <div class="col-md-4">
                <i class="fas fa-map-marker-alt"></i> {{searchData.destination_name}}
            </div>
            <div class="col-md-4">
                <span>
                    <i class="fas fa-calendar-alt"></i> {{searchData.check_in}}
                </span>
                -
                <span>
                    <i class="fas fa-calendar-alt"></i> {{searchData.check_out}}
                </span>
            </div>
            <div class="col-md-3">
                <span>
                    <i class="fas fa-male"></i> {{searchData.adultCount}}
                </span>
                <span>
                    <i class="fas fa-child"></i> {{searchData.childCount}}
                </span>
                <span>
                    <i class="fas fa-bed"></i> {{searchData.roomCount}}
                </span>
            </div>
            <div class="col-md-1 d-flex justify-content-end text-orange">
                <i class="far fa-edit font-size-20"></i>
            </div>
        </div>
    </div>
    <app-page-title title="Hotel Passengers"></app-page-title>

    <div class="row">
        <div class="card col-md-12">
            <div class="card-body">
                <form class="repeater mb-2" [formGroup]="passengerForm">
                    <div *ngFor="let room of roomData; let i = index">
                        <h5>{{room.name}}</h5>
                        <div *ngFor="let rate of room.rates; let a = index" class="p-3">
                            <!-- <p>Room# <span>{{a+1}}</span></p> -->
                            <div *ngFor="let room of rate.selectedRoomData; let b = index">
                                <ngb-alert *ngIf="b == 0" [dismissible]="false" type="primary" style="font-size: 10px;">
                                    {{rate.rateComment}}
                                </ngb-alert>
                                <ngb-alert *ngIf="b == 0" [dismissible]="false" type="primary" style="font-size: 10px;">
                                    Cancellation Policy : {{rate.cancellationPoliciesText}}
                                </ngb-alert>
                                <!-- <p class="rateComment" *ngIf="b == 0"><span>{{rate.rateComment}}</span></p>
                                <p class="rateComment" *ngIf="b == 0"><span>Cancellation Policy : {{rate.cancellationPoliciesText}}</span></p> -->
                                <p><span>{{rate.boardName}}</span></p>
                                <div *ngFor="let passenger of room; let c = index" formArrayName="passengerFormList">
                                    <div class="accordionpage">
                                        <ngb-accordion #acc="ngbAccordion" [closeOthers]="closeOther"
                                            [activeIds]="activeIds">
                                            <ngb-panel>
                                                <ng-template ngbPanelTitle class="header">
                                                    <div class="row">
                                                        <div class="col-6 d-flex align-items-center text-left"
                                                            style="justify-content: flex-start;">
                                                            <h6>{{passenger.type}} {{passenger.paxNo}} <span class="required-Text" *ngIf="c==0 || passenger.type =='Child'"> (Required)</span></h6>
                                                        </div>
                                                        <div class="col-6 d-flex align-items-center text-right"
                                                            style="justify-content: flex-end;">
                                                            <i class="fas fa-chevron-down"></i>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template ngbPanelContent>
                                                    <div [formGroupName]="passenger.index">
                                                        <div class="row">
                                                            <div class="mb-3 col-md-4">
                                                                <label>Title<span class="r-star" *ngIf="c==0 || passenger.type =='Child'"> *</span></label>
                                                                <select *ngIf="passenger.type !='Child'" (change)="onChange()" class="form-select"
                                                                    formControlName="title"
                                                                    [ngClass]="{'is-invalid': formsubmit && passengerFormData().controls[passenger.index].get('title').errors}"
                                                                    placeholder="Select Title">
                                                                    <option value="" disabled selected>Select Title
                                                                    </option>
                                                                    <option value="Mr">Mr</option>
                                                                    <option value="Mrs">Mrs</option>
                                                                    <option value="Ms">Ms</option>
                                                                    <option value="Miss">Miss</option>
                                                                </select>
                                                                <select *ngIf="passenger.type =='Child'" (change)="onChange()" class="form-select"
                                                                    formControlName="title"
                                                                    [ngClass]="{'is-invalid': formsubmit && passengerFormData().controls[passenger.index].get('title').errors}"
                                                                    placeholder="Select Title">
                                                                    <option value="" disabled selected>Select Title
                                                                    </option>
                                                                    <option value="Miss">Miss</option>
                                                                    <option value="Mstr">Mstr</option>
                                                                </select>
                                                                <div *ngIf="formsubmit && passengerFormData().controls[passenger.index].get('title').errors"
                                                                    class="invalid-feedback">
                                                                    <span
                                                                        *ngIf="passengerFormData().controls[passenger.index].get('title').errors.required">This
                                                                        value is required.</span>
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-4">
                                                                <label>Given Names (Other Names)<span class="r-star" *ngIf="c==0 || passenger.type =='Child'">
                                                                        *</span></label>
                                                                <input style="text-transform:uppercase" type="text"
                                                                    class="form-control" placeholder="Enter Given Names"
                                                                    formControlName="givenName"
                                                                    [ngClass]="{'is-invalid': formsubmit && passengerFormData().controls[passenger.index].get('givenName').errors}" />
                                                                <div *ngIf="formsubmit && passengerFormData().controls[passenger.index].get('givenName').errors"
                                                                    class="invalid-feedback">
                                                                    <span
                                                                        *ngIf="passengerFormData().controls[passenger.index].get('givenName').errors.required">This
                                                                        value is required.</span>
                                                                    <span
                                                                        *ngIf="passengerFormData().controls[passenger.index].get('givenName').errors.pattern">This
                                                                        value should be a valid given name.</span>
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-4">
                                                                <label>Surname<span class="r-star" *ngIf="c==0 || passenger.type =='Child'"> *</span></label>
                                                                <input style="text-transform:uppercase" type="text"
                                                                    class="form-control" placeholder="Enter Surname"
                                                                    formControlName="surname"
                                                                    [ngClass]="{'is-invalid': formsubmit && passengerFormData().controls[passenger.index].get('surname').errors}" />
                                                                <div *ngIf="formsubmit && passengerFormData().controls[passenger.index].get('surname').errors"
                                                                    class="invalid-feedback">
                                                                    <span
                                                                        *ngIf="passengerFormData().controls[passenger.index].get('surname').errors.required">This
                                                                        value is required.</span>
                                                                    <span
                                                                        *ngIf="passengerFormData().controls[passenger.index].get('surname').errors.pattern">This
                                                                        value should be a valid surname.</span>
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-4"
                                                                *ngIf="passenger.type =='Adult' && passengerFormData().controls[passenger.index].get('email')">
                                                                <label>Email<span class="r-star" *ngIf="c==0 || passenger.type =='Child'"> *</span></label>
                                                                <input type="text" class="form-control"
                                                                    placeholder="Enter Email" formControlName="email"
                                                                    [ngClass]="{'is-invalid': formsubmit && passengerFormData().controls[passenger.index].get('email').errors}" />
                                                                <div *ngIf="formsubmit && passengerFormData().controls[passenger.index].get('email').errors"
                                                                    class="invalid-feedback">
                                                                    <span
                                                                        *ngIf="passengerFormData().controls[passenger.index].get('email').errors.required">This
                                                                        value is required.</span>
                                                                    <span
                                                                        *ngIf="passengerFormData().controls[passenger.index].get('email').errors.email">Email
                                                                        must be a valid email address.</span>
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-4"
                                                                *ngIf="passenger.type =='Adult' && passengerFormData().controls[c].get('phoneNumber')">
                                                                <label>Phone Number<span class="r-star" *ngIf="c==0 || passenger.type =='Child'">
                                                                        *</span></label>
                                                                <ngx-intl-tel-input [enableAutoCountrySelect]="true"
                                                                    [preferredCountries]="['lk']"
                                                                    [enablePlaceholder]="true"
                                                                    [searchCountryFlag]="true"
                                                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                                                    [maxLength]="15" [phoneValidation]="true"
                                                                    [separateDialCode]="true"
                                                                    [numberFormat]="PhoneNumberFormat.National"
                                                                    formControlName="phoneNumber" style="width: 100%;"
                                                                    [ngClass]="{'is-invalid': formsubmit && passengerFormData().controls[passenger.index].get('phoneNumber').errors}"
                                                                    [phoneValidation]="true">
                                                                </ngx-intl-tel-input>
                                                                <div *ngIf="formsubmit && passengerFormData().controls[passenger.index].get('phoneNumber').errors"
                                                                    class="invalid-feedback">
                                                                    <span
                                                                        *ngIf="passengerFormData().controls[passenger.index].get('phoneNumber').errors">This
                                                                        value is invalid.</span>
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-4" *ngIf="passenger.type =='Child'">
                                                                <label>Age<span class="r-star"> *</span></label>
                                                                <select (change)="onChange()" class="form-select"
                                                                    formControlName="age"
                                                                    [ngClass]="{'is-invalid': formsubmit && passengerFormData().controls[passenger.index].get('age').errors}"
                                                                    placeholder="Select Title">
                                                                    <option value="" disabled selected>Select Age
                                                                    </option>
                                                                    <option
                                                                        *ngFor="let option of counter(12);let p = index"
                                                                        [value]="p+1">
                                                                        {{p+1}}
                                                                    </option>
                                                                </select>
                                                                <div *ngIf="formsubmit && passengerFormData().controls[passenger.index].get('age').errors"
                                                                    class="invalid-feedback">
                                                                    <span
                                                                        *ngIf="passengerFormData().controls[passenger.index].get('age').errors">This
                                                                        value is invalid.</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </ngb-panel>
                                        </ngb-accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <app-hotel-booking-summary class="mt-2"></app-hotel-booking-summary>
                    <app-hotel-room-summary class="mt-2"></app-hotel-room-summary>
                    <div class="mt-3 col-12 d-flex justify-content-end">
                        <button type="button" (click)="onPassengersSubmit()" class="btn btn-primary btn-theme e-1">
                            Proceed
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <!-- <div class="col-md-1">

        </div> -->
        <!-- <div class="col-md-4" style="margin-left:20px;">
            <app-hotel-booking-summary></app-hotel-booking-summary> -->
        <!-- <div class="card">
                <div class="card-body">
                    <div class="heading">
                        <h5 class="m-0 p-0">Booking Summary</h5>
                    </div>
                    <div class="mb-3 mb-0 col-12 d-flex mt-3" style="justify-content: space-between;">
                        <span>
                            Hotel Name
                        </span>
                        <span>
                           {{hotelData.name}}
                        </span>
                    </div>
                    <div class="mb-3 mb-0 col-12 d-flex mt-3" style="justify-content: space-between;">
                        <span>
                            Star Rating
                        </span>
                        <span>
                            {{hotelData.categorySimpleCode}}
                        </span>
                    </div>
                    <div class="mb-3 mb-0 col-12 d-flex mt-3" style="justify-content: space-between;">
                        <span>
                            Check - In
                        </span>
                        <span>
                            {{searchData.check_in}}
                        </span>
                    </div>
                    <div class="mb-3 mb-0 col-12 d-flex mt-3" style="justify-content: space-between;">
                        <span>
                            Check - Out
                        </span>
                        <span>
                            {{searchData.check_out}}
                        </span>
                    </div>
                    <div class="mb-3 mb-0 col-12 d-flex mt-3" style="justify-content: space-between;">
                        <span>
                            No of Night
                        </span>
                        <span>
                        </span>
                    </div>
                    <div class="mb-3 mb-0 col-12 d-flex mt-3" style="justify-content: space-between;">
                        <span>
                            Original Price
                        </span>
                        <span>
                            {{rateRechekResponse.totalOriginalPrice}}
                        </span>
                    </div>
                    <div class="mb-3 mb-0 col-12 d-flex mt-3" style="justify-content: space-between;">
                        <span>
                            Discount
                        </span>
                        <span>
                            {{rateRechekResponse.discountPrice}}
                        </span>
                    </div>
                    <div class="mb-3 mb-0 col-12 d-flex mt-3" style="justify-content: space-between;">
                        <span>
                            Sub Total Price
                        </span>
                        <span>
                            {{rateRechekResponse.subtotalPrice}}
                        </span>
                    </div>
                    <div class="mb-3 mb-0 col-12 d-flex mt-3" style="justify-content: space-between;">
                        <span>
                            Total Price
                        </span>
                        <span>
                            {{rateRechekResponse.totalPayable}}
                        </span>
                    </div>
                </div>
            </div> -->
        <!-- <app-hotel-room-summary></app-hotel-room-summary> -->
        <!-- <div class="card">
                <div class="card-body">
                    <div class="heading">
                        <h5 class="m-0 p-0">Room Summary</h5>
                    </div>
                    <div *ngFor="let room of roomData" class="mt-2">
                        <p class="mb-0 boardName">{{room.name}}</p>
                        <div *ngFor="let rate of room.rates" class="d-flex justify-content-between p-2">
                            <div class="roomSummary">
                                <span class="content">{{rate.boardName}} x {{rate.selectedRoomCount}}</span>
                            </div>
                            <div>
                                <span *ngFor="let s of counter(rate.maxAdult); let i = index"
                                    style="padding-left: 5px;font-weight: 400;">
                                    <span *ngIf="rate.maxAdult != 0"><i class="fas fa-user"
                                            style="color: #f1734f;"></i></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        <!-- </div> -->
    </div>
</div>