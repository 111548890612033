import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthenticationService } from '../services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { redirectUrls } from './redirect-url';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private spinner: NgxSpinnerService,
        private router: Router,
        private toastr: ToastrService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                var valueCj = error.url.includes(environment.valueCj);
                var valueJ9 = error.url.includes(environment.valueJ9);
                var valueFz = error.url.includes(environment.valueFz);
                var valueWy = error.url.includes(environment.valueWy);
                var valueSq = error.url.includes(environment.valueSq);
                var isEventUrl = error.url.includes(environment.isEventUrl);
                if(!isEventUrl){
                    if(!valueCj && !valueJ9 && !valueFz && !valueWy && !valueSq){
                        if (error.status === 401) {
                            this.toastr.error("Unauthorized!","Error")
                            sessionStorage.clear();
                            localStorage.clear();
                            const url = redirectUrls.redirectLogin;
                            window.location.href = url;
                        }
                        if (error.status === 404) {
                            this.toastr.error("Not Found, Please try again later !","Error")
                        }  
                        if (error.status === 500) {
                            this.toastr.error("Internal Server Error")
                        }
                        if(error.status === 0 || error.status === 503){
                            this.toastr.error("Service unavailable, please try again later.")
                        }                        
                    }else{
                        if (error.status === 404) {
                        }                
                    }
                    const err = error.error.message || error.statusText;
                    return throwError(err);  
                }
            }),
            map(event => {
                if (event instanceof HttpResponse) {
                    let dateTime = new Date();
                    // if(!event.url.includes('events.inqbaytor')){
                    localStorage.setItem('latestApiDateTime',dateTime.toUTCString());
                    // }
                } else {

                }
                return event;
            }));
    }
}
