import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIModule } from './ui/ui.module';
import { DateAgoPipe } from '../pages/dashboards/notifications/date-ago.pipe';
import { HotelBookComponent } from '../pages/hotel-management/hotel-booking/hotel-book/hotel-book.component';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';

@NgModule({
  declarations: [
    DateAgoPipe,
    // HotelBookComponent
  ],
  exports:[
    DateAgoPipe,
    // HotelBookComponent
    // NgxGalleryModule,
  ],
  imports: [
    CommonModule,
    UIModule,
    // NgxGalleryModule,
  ],
})

export class SharedModule { }
