<div class="accordionpage">
    <ngb-accordion #acc="ngbAccordion" [closeOthers]="true">
        <ngb-panel id="static-1">
            <ng-template ngbPanelTitle>
                <div class="row">
                    <div class="col-6 d-flex align-items-center text-left" style="justify-content: flex-start;">
                        <h6>Booking Summary</h6>
                    </div>
                    <div class="col-6 d-flex align-items-center text-right" style="justify-content: flex-end;">
                        <i class="fas fa-chevron-down"></i>
                    </div>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <div class="mb-3 mb-0 col-12 d-flex mt-3" style="justify-content: space-between;">
                    <span>
                        Hotel Name
                    </span>
                    <span>
                        {{hotelData.name}}
                    </span>
                </div>
                <div class="mb-3 mb-0 col-12 d-flex mt-3" style="justify-content: space-between;">
                    <span>
                        Star Rating
                    </span>
                    <span>
                        <p class="mb-0"> <span *ngFor="let s of counter(hotelData.starRating); let i = index"
                            style="padding-left: 5px;font-weight: 400;">
                            <span *ngIf="star != 0"><i class="fas fa-star"
                                    style="color: #F19C4F;"></i></span>
                            </span>
                        </p>
                    </span>
                </div>
                <div class="mb-3 mb-0 col-12 d-flex mt-3" style="justify-content: space-between;">
                    <span>
                        Check - In
                    </span>
                    <span>
                        {{searchData.check_in}}
                    </span>
                </div>
                <div class="mb-3 mb-0 col-12 d-flex mt-3" style="justify-content: space-between;">
                    <span>
                        Check - Out
                    </span>
                    <span>
                        {{searchData.check_out}}
                    </span>
                </div>
                <div class="mb-3 mb-0 col-12 d-flex mt-3" style="justify-content: space-between;">
                    <span>
                        No of Nights
                    </span>
                    <span>
                        {{numberOfNights}}
                    </span>
                </div>
                <div class="mb-3 mb-0 col-12 d-flex mt-3" style="justify-content: space-between;">
                    <span>
                        Original Price
                    </span>
                    <span>
                        {{rateRechekResponse.currency}} {{rateRechekResponse.totalOriginalPrice | number : '1.2-2'}}
                    </span>
                </div>
                <div class="mb-3 mb-0 col-12 d-flex mt-3" style="justify-content: space-between;">
                    <span>
                        Discount
                    </span>
                    <span>
                        {{rateRechekResponse.currency}} {{rateRechekResponse.discountPrice | number : '1.2-2'}}
                    </span>
                </div>
                <div class="mb-3 mb-0 col-12 d-flex mt-3" style="justify-content: space-between;">
                    <span>
                        Sub Total Price
                    </span>
                    <span>
                        {{rateRechekResponse.currency}} {{rateRechekResponse.subtotalPrice | number : '1.2-2'}}
                    </span>
                </div>
                <div class="mb-3 mb-0 col-12 d-flex mt-3" style="justify-content: space-between;">
                    <span>
                        Total Price
                    </span>
                    <span>
                        {{rateRechekResponse.currency}} {{rateRechekResponse.totalPayable | number : '1.2-2'}}
                    </span>
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>