import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hotel-booking-summary',
  templateUrl: './hotel-booking-summary.component.html',
  styleUrls: ['./hotel-booking-summary.component.scss']
})
export class HotelBookingSummaryComponent implements OnInit {

  searchData;
  hotelData;
  rateRechekResponse;

  numberOfNights = '';

  constructor() { }

  ngOnInit(): void {
    this.searchData = JSON.parse(localStorage.getItem('hotel-search'));
    this.hotelData = JSON.parse(localStorage.getItem('hotelDetails'));
    this.rateRechekResponse = JSON.parse(localStorage.getItem('rateRecheckResponse'));
    var d = new Date();
    let departure = new Date(this.searchData.check_in);
    let arrival = new Date(this.searchData.check_out);
    var timeDiff = Math.abs(arrival.getTime() - departure.getTime());
    this.numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24)).toString();
  }

  /**
   * Star filter build
   * @param i 
   * @returns 
   */
   counter(i: number) {
    return new Array(i);
  }    


}
