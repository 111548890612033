import { Component, OnInit, Output, EventEmitter, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { AuthenticationService } from '../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
import { environment } from '../../../environments/environment';

import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/core/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { apiEndPoints } from 'src/app/core/helpers/api-endpoints';
import { interval, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { redirectUrls } from 'src/app/core/helpers/redirect-url';
import { Platform } from '@angular/cdk/platform';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMap, mergeMapTo, switchMap } from 'rxjs/operators';
import * as myGlobals from 'src/app/core/helpers/globals'; //<==== this one (**Updated**)
import { GlobalService } from 'src/app/core/services/global.service';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {

  element;
  configData;
  cookieValue;
  flagvalue: any='assets/images/flags/sl.png';
  countryName: any='Sri Lanka';
  countryCode;
  valueset;
  user:any;
  subscription:Subscription[] = [];
  token;
  showBanner = false;
  priceDesc;
  notifyCount;
  quotesCount;
  notification = [];
  loading;
  remainingNotifyCount;
  isTest = false;
  isBadgeEnable = false;
  isShowUpdate = false;
  fbUrl:SafeResourceUrl;
  isQuotes = false;
  isReloadTopBar = false;
  actions;
  timeLeft: number = 3;
  interval;
  isZoomLoading = false;
  userName;
  userRole;

  constructor(@Inject(DOCUMENT) private document: any,
    private router: Router,
    private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    private apiService:ApiService,
    private spinner: NgxSpinnerService,
    private route:ActivatedRoute,
    private platform: Platform,
    private afMessaging: AngularFireMessaging,
    private globalSrv: GlobalService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    protected _sanitizer: DomSanitizer
    ) {
      if(localStorage.getItem('access-token') == null || localStorage.getItem('access-token') != undefined){
        this.route.queryParams.subscribe(
          params => {
            this.token = params.token
          }
        );
      }
      
      if(localStorage.getItem('actions')){
        this.actions = JSON.parse(localStorage.getItem('actions')).userActions; 
      }
      var quotes = [];
      if(localStorage.getItem('quotes') != null){
        quotes = JSON.parse(localStorage.getItem('quotes'));
      }
      this.globalSrv.QuotesValue = (quotes.length).toString();
      globalSrv.itemValue.subscribe((nextValue) => {
        this.notifyCount = nextValue;
      })
      globalSrv.badgeStatus.subscribe((nextValue) => {
        this.isBadgeEnable = JSON.parse(nextValue);
      });   
      globalSrv.quotesValueStatus.subscribe((nextValue) => { 
        this.quotesCount = JSON.parse(nextValue);
        if(this.quotesCount > 0){
          this.timeLeft = 3;
          this.isZoomLoading = true;
          this.startTimer();
        }
      });    
      globalSrv.quotesStatus.subscribe((nextValue) => {
        this.isQuotes = JSON.parse(nextValue);
      })
      globalSrv.isReloadTopBar.subscribe((nextValue) => {
        if(localStorage.getItem('actions')){
          this.actions = JSON.parse(localStorage.getItem('actions')).userActions; 
        }
        this.isReloadTopBar = JSON.parse(nextValue);
      })
      globalSrv.nameValueStatus.subscribe((nextValue) => { 
        this.userName = nextValue;
      });  
      globalSrv.roleValueStatus.subscribe((nextValue) => { 
        this.userRole = nextValue;
      }); 

      this.checkLogin();
  }


  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.isZoomLoading = false;
        clearInterval(this.interval);
      }
    },1000)
  }  

  listLang = [
    { text: 'Sri Lanka', flag: 'assets/images/flags/sl.png', lang: 'en' },
    { text: 'Australia', flag: 'assets/images/flags/au.png', lang: 'en' },
    // { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    // { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    // { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();
  @ViewChild('notify') notify:NgbDropdown;
  @ViewChild("fb", { static: false }) private fbEmbedded;

  ngOnInit() {
    this.openMobileMenu = false;
    this.checkPlatForm();
    this.element = document.documentElement;
    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3
    };
    if(localStorage.getItem('country') != null){
      if(localStorage.getItem('country') == "Australia"){
        this.flagvalue = 'assets/images/flags/au.png';
        this.countryName = 'Australia';
        this.globalSrv.MobileCodeUpdate = 'au';
      }else{
        this.flagvalue = 'assets/images/flags/sl.png';
        this.countryName = 'Sri Lanka';       
        this.globalSrv.MobileCodeUpdate = 'lk'; 
      }
      sessionStorage.setItem('country',this.countryName);
    }else{
      localStorage.setItem('country',this.countryName);
      sessionStorage.setItem('country',this.countryName);
      this.flagvalue = 'assets/images/flags/sl.png';
      this.countryName = 'Sri Lanka';
      this.globalSrv.MobileCodeUpdate = 'lk';
    }
    // this.cookieValue = this._cookiesService.get('lang');
    // const val = this.listLang.filter(x => x.lang === this.cookieValue);
    // this.countryName = val.map(element => element.text);
    // if (val.length === 0) {
    //   if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/sl.png'; }
    // } else {
    //   this.flagvalue = val.map(element => element.flag);
    // }
  }

  checkLogin(){
    var value = localStorage.getItem('access-token');
    if(value == null || value == 'undefined'){
      localStorage.setItem("access-token",this.token);
      this.subscription.push(this.apiService.apiAuthGet(apiEndPoints.getUserData).subscribe(res => {
        localStorage.setItem("user",JSON.stringify(res));
        this.user = JSON.parse(localStorage.getItem("user")); 
        this.checkinUserActive();
      },error=>{
      }))
    }else{
      this.user = JSON.parse(localStorage.getItem("user")); 
      this.refreshToken();
      this.checkinUserActive();
    }
  };

  refreshToken() {
    this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.eventPing,{}).subscribe(res => {
      if(res.data['3'] > environment.version){
        this.isShowUpdate = true;
      }else{
        this.isShowUpdate = false;
      }

      /** */
      if(localStorage.getItem('4')){
        if(res.data['4'] > localStorage.getItem('4')){ 
          localStorage.setItem('4',res.data['4']);
          this.getJazeeraCities();
        }
      }else{
        localStorage.setItem('4',res.data['4']);
        this.getJazeeraCities();
      }

      /** */
      if(localStorage.getItem('5')){
        if(res.data['5'] > localStorage.getItem('5')){ 
          localStorage.setItem('5',res.data['5']);
          this.getFlyDubaiCities();
        }
      }else{
        localStorage.setItem('5',res.data['5']);
        this.getFlyDubaiCities();
      }

      /** */
      if(localStorage.getItem('6')){
        if(res.data['6'] > localStorage.getItem('6')){ 
          localStorage.setItem('6',res.data['6']);
          this.getNDCCities();
        }
      }else{
        localStorage.setItem('6',res.data['6']);
        this.getNDCCities();
      }

      /** */
      if(localStorage.getItem('7')){
        if(res.data['7'] > localStorage.getItem('7')){ 
          localStorage.setItem('7',res.data['7']);
          this.getFitsAirCities();
        }
      }else{
        localStorage.setItem('7',res.data['7']);
        this.getFitsAirCities();
      }

      if(res.data['8'] != null){
        // this.fbUrl = res.data['8'];
        this.fbUrl = this._sanitizer.bypassSecurityTrustResourceUrl(res.data['8'])
        localStorage.setItem('8',res.data['8']);
         if(localStorage.getItem('latestFBURLDateTime') != null){
          var startTime = new Date(localStorage.getItem('latestFBURLDateTime'));
          var endTime = new Date()
          var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
          var resultInSeconds = Math.round(difference / 1000);
          if(resultInSeconds > 86400){
            let dateTime = new Date();
            localStorage.setItem('latestFBURLDateTime',dateTime.toUTCString());
            setTimeout(()=>{
              this.modalService.open(this.fbEmbedded)
             },2000)                   
          }
         }else{
          setTimeout(()=>{
            this.modalService.open(this.fbEmbedded)
           },2000)            
          let dateTime = new Date();
          localStorage.setItem('latestFBURLDateTime',dateTime.toUTCString());
         }
      }
    }))
  };



  /**
   * Close alert on click
   */
  closeAlert(){
    this.isShowUpdate = false;
  }  

  /**
   * refrsh dashboard
   */
   refreshDashboard(){
    this.isShowUpdate = false;
    location.reload();
  }    

  /**
   * This will get the available cities for jazeera
   */
  getJazeeraCities(){
    this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.eventJazeera,{}).subscribe(res => {
      if(res.code == 1){
        localStorage.setItem('J9cities',JSON.stringify(res.data));
      }
    }))    
  }

  /**
   * This will get the available cities for fly dubai
   */
  getFlyDubaiCities(){
    this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.eventFlyDubai,{}).subscribe(res => {
      if(res.code == 1){
        localStorage.setItem('FZcities',JSON.stringify(res.data));
      }
    }))    
  }

  /**
   * This will get the available cities for NDC
   */
   getNDCCities(){
    this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.eventNDC,{}).subscribe(res => {
      if(res.code == 1){
        localStorage.setItem('NDCcities',JSON.stringify(res.data));
      }
    }))    
  }  

  /**
   * This will get the available cities for FitsAir
   */
   getFitsAirCities(){
    this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.eventFits,{}).subscribe(res => {
      if(res.code == 1){
        localStorage.setItem('FITScities',JSON.stringify(res.data));
      }
    }))    
  }    

  userActive(){
    if(localStorage.getItem('latestApiDateTime')){
      var startTime = new Date(localStorage.getItem('latestApiDateTime'));
      var endTime = new Date()
      var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
      var resultInSeconds = Math.round(difference / 1000);
      if(resultInSeconds > 60){
        return '1';
      }else{
        return '0';
      }
    }else{
      return '1';
    }
  }

  /**
    * Send api request background every 10 minute
    */  
   checkinUserActive(){
    const intervalTime = 1000;
    interval(intervalTime).pipe(switchMap(() => this.userActive())).subscribe((val)=>{
      if(val == '1'){
        let dateTime = new Date();
        localStorage.setItem('latestApiDateTime',dateTime.toUTCString());
        this.refreshToken();
      }
    });
  }   

  // login(){
  //   this.spinner.show();
  //   var data = {
  //     "email":"yashas@crazyjets.com",
  //     "password":"9z8ErHhopS"
  //   }
  //   this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.login,data).subscribe(res => {
  //     // var response = JSON.parse(res);
  //     this.spinner.hide();
  //     sessionStorage.setItem("user",JSON.stringify(res.user));
  //     this.user = JSON.parse(sessionStorage.getItem('user'));
  //     // alert("success")
  //   },error=>{
  //     // alert("FAILED")
  //   }))
  // }

  /**
   * Language set
   * @param text 
   * @param lang 
   * @param flag 
   */
  setLanguage(text: string, lang: string, flag: string) {
    this.languageService.setLanguage(lang);
    if(localStorage.getItem('country')){
      if(text != localStorage.getItem('country')){
        if (confirm("Changing region? Changes you made in all open tabs may not be saved.")) {
          if(localStorage.getItem('country') == "Australia"){
            this.globalSrv.MobileCodeUpdate = 'au';
          }else{     
            this.globalSrv.MobileCodeUpdate = 'lk'; 
          }
          localStorage.setItem('country',text);
          sessionStorage.setItem('country',text);
          localStorage.removeItem('quotes')
          this.countryName = text;
          this.flagvalue = flag;
          this.cookieValue = lang;
          this.router.navigateByUrl("account/welcome");
        //   setTimeout(()=>{                           // <<<---using ()=> syntax
        //     window.location.reload();
        // }, 1000);
          // window.location.href = '/dashboard',
          // window.location.reload();
          return true;
        } else {
          return false;
        }
      }
    }
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }
  

  /**
   * Logout the user
   */
  logout() {
    Swal.fire({
      showCloseButton: true,
      title: 'Logout',
      text: 'Are you sure you want to logout?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#f46a6a',
      cancelButtonColor:'#495057',
      confirmButtonText: 'Yes, Logout',
      cancelButtonText:'Cancel',
      allowOutsideClick:false
    }).then(result => {
      if (result.value) {
         var fcmToken = localStorage.getItem('fcm');
         var cjToken = localStorage.getItem('access-token1')
         this.spinner.show();
         var data = {
          "firebase_token":fcmToken != null ?fcmToken:"",
          "cj_token":cjToken != null ?cjToken:""
         }
         this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.logout,data).subscribe(res => {
            if(res['code'] == 1){
              sessionStorage.clear();
              localStorage.clear();
              this.spinner.hide();
              const url = redirectUrls.redirectLogin;
              window.location.href = url;             
            }else{
              this.spinner.hide();
              this.toastr.error(res.message,'Error')                    
            }
          },error=>{
          })) 
        }
      })   
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    }
  }

  //on view click action, check the platform and redirect to the store url
  onViewClick(){
    if (this.platform.IOS) {
      const url = "https://apps.apple.com/app/id1526102889"
      window.location.href = url;        
    }else if(this.platform.ANDROID || this.platform.BLINK){
      const url = "https://play.google.com/store/apps/details?id=com.inq.ticketgadget"
      window.location.href = url;  
    }
  }

  onClickOnClose(){
    this.showBanner = false;
    sessionStorage.setItem('showBanner',JSON.stringify(this.showBanner))
  }

  checkPlatForm(){
    if (this.platform.IOS || this.platform.ANDROID || this.platform.BLINK){
      this.showBanner = true;
      let bannerStatus = JSON.parse(sessionStorage.getItem('showBanner')); 
      if(bannerStatus != null){
        this.showBanner = bannerStatus;
      }
    }
    if(this.platform.IOS){
      this.priceDesc = "FREE - On the App Store"
    }else if(this.platform.ANDROID || this.platform.BLINK){
      this.priceDesc = "FREE - In Google Play"
    }
  };

  /**
   * Get Latest notificatons
   */
  getLatestNotification(){
  this.loading = true;
  this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.latestNotification,{}).subscribe(res => {
    this.spinner.hide();
    if(res.code == 1){
      this.notification = res.data.recentNotifications;
      this.globalSrv.theItem = res.data.unreadNotificationsCount;
      for(let i = 0; i < this.notification.length; i++){
        this.notification[i]['expanded'] = false;
      }
      this.remainingNotifyCount = res.data.remainingNotificationsCount;
      this.loading = false;
    }
  },error=>{
  }))
  }   

  /**
   * This function will change the unread status to read
   */
  changeNotificationStatus(id,index){
    this.isTest =true;
    if(!this.notification[index]['expanded']){
      this.notification[index]['expanded'] = true;
    }else{
      this.notification[index]['expanded'] = false;
    }
    if(this.notification[index]['notification_inbox_status'] == 0){
      this.spinner.show();
      var data = {
        "notificationId":id
      }
      this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.chnageStatus,data).subscribe(res => {
        this.spinner.hide();
        if(res.code == 1){
          this.notification[index].notification_inbox_status = 1;
          this.globalSrv.theItem = res.data.unreadNotificationsCount;
        }
        // alert("success")
      },error=>{
        // alert("FAILED")
      })) 
    }    
  }  

  /**
   * 
   */
  onExpanded(isOpen){
    // console.log(this.notify.isOpen());
    // this.notify
    // if(!this.notify.isOpen){
    //   console.log(this.notify.isOpen);
    //   this.getLatestNotification();
    // }
    if(isOpen == true){
      this.notify.open();
      console.log(this.notify.isOpen())
      // if(!this.notify.isOpen()){
        this.getLatestNotification();
      // }
    }else{

    }
  } 

   //this function will download the pdf
   donwloadBooking(id){
    let token = localStorage.getItem('access-token')
    var pdfUrl = apiEndPoints.donwloadTicket+"?ref="+id+"&token="+token;
    window.open(pdfUrl,"_blank")    
   }

   //
   issueTicket(id,provider){
    sessionStorage.setItem('pnr',id);
    if(provider == "Jazeera" || provider == "FlyDubai"){
      sessionStorage.setItem('bookingType','2');
      if(provider == "Jazeera"){
        sessionStorage.setItem('isJazeeraBooking','1');
      }else if(provider == "FlyDubai"){
        sessionStorage.setItem('isFlyDubai','1');
      }
    }else{
      sessionStorage.setItem('bookingType','1');
    }
    this.router.navigateByUrl('ticketmanagement/issue-ticket');
    this.notify.close();
  }


  voidTicket(ticketNumber){
    Swal.fire({
      title: 'Void Confirmation',
      text: 'Are you sure you want to void the selected ticket?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#f46a6a',
      cancelButtonColor: '#495057',
      confirmButtonText: 'Void Ticket',
      cancelButtonText:'Do Not Void',
      allowOutsideClick:false
    }).then(result => {
      if (result.value) {
        this.spinner.show();
        // let b_id = this.bookingList.find(x=>x.booking_reference === id)
        var data = {
          "id":ticketNumber,
          "retrieveInstanceId":"",
        }
        this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.voidTicket,data).subscribe(async res => {
          this.spinner.hide();
          if(res.code == 1){
            this.toastr.success("","Voided!");                      
          }else{
            this.toastr.error(res['AirError'],'Error');
          }
        },error=>{
        })
        )  
      }
    });      
  }

  donwloadTicket(id){
    let bookingId = btoa(id);
    let token = localStorage.getItem('access-token')
    var pdfUrl = apiEndPoints.donwloadTicket1+"?ref="+bookingId+"&token="+token;
    window.open(pdfUrl,"_blank")
  }

  //This function will fetch the pagination data from api
  getPaginationDataByApi(){
    // SharedPreferences prefs = await SharedPreferences.getInstance();
    this.spinner.show();
    var data ={
      "startNotificationId":this.notification[this.notification.length - 1]['id']
    };
    this.subscription.push(this.apiService.apiAuthPost(apiEndPoints.latestNotification,data).subscribe(res => {
      this.spinner.hide();
      if(res.code == 1){
        var resNotification = res['data']['recentNotifications'];
        for(var i = 0; i < resNotification.length; i++){
          resNotification[i]['expanded'] = false;
        }
        this.remainingNotifyCount = res.data.remainingNotificationsCount;
        this.notification.push(...resNotification);
        this.globalSrv.theItem = res.data.unreadNotificationsCount;
        this.loading = false;
      }
      // alert("success")
    },error=>{
      // alert("FAILED")
    }))
  }   

  markAllAsRead(){
    for(let i = 0; i < this.notification.length; i++){
      this.notification[i]['notification_inbox_status'] == 0;
    }    
    this.globalSrv.theItem = '0';
  }

  /**
   * When user click on cart icon this will trigger
   */
  onQuotes(){
    this.router.navigateByUrl('dashboards/quotes-cart');
  }
  // this.router.navigateByUrl('ticketmanagement/issue-ticket');
  onCloseSmsModel(){
    this.modalService.dismissAll();
  }
}