import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NgWizardConfig, NgWizardService, StepChangedArgs, StepValidationArgs, STEP_STATE, THEME } from 'ng-wizard';
import { of, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-hotel-home',
  templateUrl: './hotel-home.component.html',
  styleUrls: ['./hotel-home.component.scss']
})
export class HotelHomeComponent implements OnInit {
  currentStep = '1';
  selectedIndex = 0;
  status = 1;
  canEnter = true;
  stepsCompleted;
  step1States;
  step2States;
  step3States;
  step4States;
  step5States;
  loading = true;
  activeStep;
  routerSubscription: Subscription;
  
  constructor(
    private ngWizardService: NgWizardService,
    private location: LocationStrategy,
    private router:Router    
  ) { 
    var steps = {
      "step1":false,
      "step2":false,
      "step3":false,
      "step4":false,
      "step5":false
   }
   sessionStorage.setItem('steps-completed', JSON.stringify(steps));
   sessionStorage.setItem('activeStep','1');    
  }

  ngOnInit(): void {
    let stepCompleted = sessionStorage.getItem('steps-completed');
    this.stepsCompleted = JSON.parse(stepCompleted);
    sessionStorage.setItem('step','1');
    this.routerSubscription = this.router.events
    .pipe(filter(event => event instanceof NavigationStart))
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger == "popstate" && event.restoredState) {
      } else {
      }

    });    
  }

  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden
  };


  config: NgWizardConfig = {
    selected: 0,
  };  

  wizardConfig: NgWizardConfig = {
    selected: this.selectedIndex,
    theme: THEME.circles,
    toolbarSettings: {
      showNextButton:false,
      showPreviousButton:false,
      // toolbarExtraButtons: [
      //   { text: 'Finish', class: 'btn btn-info', event: () => { alert("Finished!!!"); } },
      // ],
    },
  };

  showPreviousStep(event?: Event) {
    this.ngWizardService.previous();

  }

  showNextStep(event?: Event) {
    var steps = JSON.parse(sessionStorage.getItem('steps-completed'));
    if(!steps.step1){
      let index = sessionStorage.getItem('step')
      if(index == '0'){
        this.step1States = STEP_STATE.normal
      }else{
        this.step1States = STEP_STATE.disabled
      }
    }else{
      this.step1States = STEP_STATE.normal
    }
    if(!steps.step2){
      let index = sessionStorage.getItem('step')
      if(index == '1'){
        this.step2States = STEP_STATE.normal
      }else{
        this.step2States = STEP_STATE.disabled
      }
      // this.step2States = STEP_STATE.disabled
    }else{
      this.step2States = STEP_STATE.normal
    }
    if(!steps.step3){
      let index = sessionStorage.getItem('step')
      if(index == '2'){
        this.step3States = STEP_STATE.normal
      }else{
        this.step3States = STEP_STATE.disabled
      }
      // this.step3States = STEP_STATE.disabled
    }else{
      this.step3States = STEP_STATE.normal
    }
    if(!steps.step4){
      let index = sessionStorage.getItem('step')
      if(index == '3'){
        this.step4States = STEP_STATE.normal
      }else{
        this.step4States = STEP_STATE.disabled
      }
      // this.step4States = STEP_STATE.disabled
    }else{
      this.step4States = STEP_STATE.normal
    }
    this.ngWizardService.next();


  }

  resetWizard(event?: Event) {
    this.ngWizardService.reset();
  }

  setTheme(theme: THEME) {
    this.ngWizardService.theme(theme);
  }

  stepChanged(args: StepChangedArgs) {
    var steps = JSON.parse(sessionStorage.getItem('steps-completed'));
    if(steps.step4){
      this.canEnter = false;
    }
    // args.step.state = this.stepStates.disabled;
    let index = sessionStorage.getItem('step')
    // if(index != '5'){
      if(args.step.index == 0){
        sessionStorage.setItem('step','1');
        this.currentStep = '1'
      }
      if(args.step.index == 1){
        sessionStorage.setItem('step','2');
        this.currentStep = '2'
      }
      if(args.step.index == 2){
        sessionStorage.setItem('step','3');
        this.currentStep = '3'
      }
      if(args.step.index == 3){
        sessionStorage.setItem('step','4');
        this.currentStep = '4'
      }
      if(args.step.index == 4){
        sessionStorage.setItem('step','5');
        this.currentStep = '5'
      }
    // }
    // else{
    //   this.currentStep = '5'
    // }
  }


  onStepHeaderClick(id){
    let stepCompleted = JSON.parse(sessionStorage.getItem('steps-completed')); 
    var activeStep = sessionStorage.getItem('activeStep');
    if(activeStep != "5"){
      if((id == "1" && stepCompleted.step1) || ( id == "1" &&  activeStep == "1")){
        sessionStorage.setItem('step','1');
        this.changeIndex();
      }
      if((id == "2" && stepCompleted.step2) || ( id == "2" &&  activeStep == "2")){
        sessionStorage.setItem('step','2');
        this.changeIndex();
      }
      if((id == "3" && stepCompleted.step3) || ( id == "3" &&  activeStep == "3")){
        sessionStorage.setItem('step','3');
        this.changeIndex();
      }
      if((id == "4" && stepCompleted.step4) || ( id == "4" &&  activeStep == "4")){
        sessionStorage.setItem('step','4');
        this.changeIndex();
      }
      if((id == "5" && stepCompleted.step5) || ( id == "5" &&  activeStep == "5")){
        sessionStorage.setItem('step','5');
        this.changeIndex();
      }
    }
  }

  changeIndex(){
    let index = sessionStorage.getItem('step');
    let stepCompleted = sessionStorage.getItem('steps-completed');
    this.stepsCompleted = JSON.parse(stepCompleted);
    this.currentStep = index;
    this.activeStep = sessionStorage.getItem('activeStep');
  }

  isValidTypeBoolean: boolean = true;

  isValidFunctionReturnsBoolean(args: StepValidationArgs) {
    return true;
  }

  isValidFunctionReturnsObservable(args: StepValidationArgs) {
    return of(true);
  }    

}