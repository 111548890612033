import { url } from "inspector";
import { environment } from "src/environments/environment";


const base_url = environment.base_url
const reissue_url = environment.reissue_url
const donwloadTicket = environment.donwloadTicket
const downloadQuote = environment.downloadQuotation
const gds = environment.gds
const donwloadTicket1 = environment.donwloadTicket1
const doc_url = environment.base_url_documents
const base_url_ticket_image  = environment.base_url_ticket_image
const base_url_event = environment.base_url_event
const base_url_ndc = environment.base_url_ndc
const base_url_credit_card = environment.base_url_credit_card
const base_url_fits_air_tg = environment.base_url_fits_air_tg
const base_url_fz = environment.base_url_fz
const base_url_jazeera = environment.base_url_jazeera_tg;
const base_url_quotes = environment.base_url_quotes;
const base_url_ndc_tg = environment.base_url_ndc_tg;
const base_url_galelio_post_booking = environment.base_url_galelio_post_booking;
const base_url_generate_pdf = environment.base_url_generate_pdf;
const base_url_sq_ndc_tg = environment.base_url_sq_ndc_tg;
const base_url_policy = environment.base_url_policy;
const base_url_sq_ndc = environment.base_url_sq_ndc;
const base_url_generate_ticket_pdf = environment.base_url_generate_ticket_pdf;
const base_url_ek_ndc_tg = environment.base_url_ek_ndc_tg;


export const apiEndPoints = {
    //Download ticket 
    donwloadTicket:`${donwloadTicket}`,
    donwloadTicket1:`${donwloadTicket1}`,
    downloadQuote:`${downloadQuote}`,
    gdsLink:`${gds}`,
    createBooking:`${base_url}save/booking/reference`,

    //login
    login:`${base_url}login`,
    getUserData:`${base_url}user`,
    getCjwToken:`${base_url}get/cjw/token`,
    fcmSave:`${base_url}save/firebase/token`,
    fcmDelete:`${base_url}delete/firebase/token`,
    logout:`${base_url}logout/user/v2`,
    checkValidation:`${base_url}user/email-phone/validation`,
    sendEmailOtp:`${base_url}v2/email/otp-send`,
    sendMobileOtp:`${base_url}v2/phone/otp-send`,
    resendEmailOtp:`${base_url}v2/email/otp-resend`,
    resendMobileOtp:`${base_url}v2/phone/otp-resend`,
    verifyEmail:`${base_url}v2/email/otp-verification`,
    verifyMobile:`${base_url}v2/phone/otp-verification`,
    changeNumber:`${base_url}changetel`,
    
    //Dashboard endpoints
    details:`${base_url}dashboard/details/v2`,
    transactionDetails:`${base_url}auth/user/transactions`,
    graphUpdate:`${base_url}issued/tickets/graph/data`,
    resendSms:`${base_url}resend/sms`,
    checkTime:`${base_url}resend/remaining/time`,
    verifySms:`${base_url}verify/sms`,
    promoData:`${base_url}top-deals`,
    userStatus:`${base_url}user-statuses`,
    latestNotification:`${base_url}notifications/recent-notifications`,
    chnageStatus:`${base_url}notifications/update-notification-status`,
    transactionOverview:`${base_url}latest/transaction/overview`,
    transactionOverviewv2:`${base_url}latest/transaction/overview/v2`,
    createAuAccount:`${base_url}create/inqbaytor/pay/account`,

    //User
    getuserdetails:`${base_url}user/details`,
    updateuser:`${base_url}update/user`,
    changepassword:`${base_url}changepassworddeails`,
    updateFooterNote:`${base_url}add/quotation/footer-note`,
    removeUserSession:`${base_url}revove/user/session`,

    //PCC
    getallproviders:`${base_url}all/providers`,
    getallpcc:`${base_url}all/pccs`,
    addpcc:`${base_url}request/pccs`,

    //flightSection
    sentOtp:`${base_url}auth/user/phonenumber`,
    getInstanceId:`${base_url}save/client/data`,
    getSearchResults:`${base_url}tg/search/results`,
    getBookingListDetails:`${base_url}get/flight/booking/details`,
    getBookingListMoreDetails:`${base_url}more/booking/data`,
    cancelBooking:`${base_url_galelio_post_booking}cancel/flight/booking/v2`,
    cancelBookingDefault:`${base_url}cancel/flight/booking/v2`,
    changeOwnership:`${base_url_galelio_post_booking}change/booking/ownership`,
    retreiveBooking:`${base_url}retrive/booking/data`,
    getPsgData:`${base_url_galelio_post_booking}get/booking/travelers`,
    split:`${base_url_galelio_post_booking}split/pnr`,
    checkRedisToken:`${base_url}check/redis/user`,
    remarkTicket:`${base_url_galelio_post_booking}get/virtual/remarks`,
    getCancelBookingSegments:`${base_url_galelio_post_booking}get/cancel/booking/segments`,
    getCancelBookingSegmentsDefault:`${base_url}get/cancel/booking/segments`,
    cancelBookingV2:`${base_url_galelio_post_booking}cancel/flight/booking/v2`,
    cancelBookingV2Default:`${base_url}cancel/flight/booking/v2`,
    getFareRulesDetails:`${base_url_galelio_post_booking}booking/fare/details`,
    getFareRules:`${base_url_galelio_post_booking}booking/fare/rules`,
    deleteFare:`${base_url_galelio_post_booking}delete/filed/fare`,
    getSpecialServiceData:`${base_url_galelio_post_booking}init/data/special-services`,
    getMealsCodeFromCarrier:`${base_url_galelio_post_booking}get/meal-codes`,
    addSpecialServices:`${base_url_galelio_post_booking}add/special-services`,
    getLoyaltyCardData:`${base_url_galelio_post_booking}init/data/loyalties`,
    removeMeal:`${base_url_galelio_post_booking}remove/special-services`,
    addLoyalty:`${base_url_galelio_post_booking}add/frequent/flyer`,
    removeLoyalty:`${base_url_galelio_post_booking}remove/frequent/flyer`,


    //SSR
    getSsrDetails:`${base_url_galelio_post_booking}booking/ssr-details`,
    addSsr:`${base_url_galelio_post_booking}booking/modify-ssr`,

    //ticket issue flow
    getAllMyPcc:`${base_url}my/pccs`,
    getExpireTime:`${base_url}booking/get-expiry-details`,
    request1:`${base_url}retrieve/booking/request1`,
    request2:`${base_url}retrieve/booking/request2`,
    request3:`${base_url}retrieve/booking/request3`,
    requestPrices:`${base_url}retrieve/booking/request-prices`,
    retrievePricing:`${base_url}retrieve/pricing`,
    inqbaytorPay:`${base_url}inqbaytor/pay`,
    startTimer:`${base_url}start/timer`,
    verifyCodes:`${base_url}inqbaytor/verify-codes`,
    request4:`${base_url}retrieve/booking/request4`,
    paymentStarus:`${base_url}check/payment/status`,
    issueTicket:`${base_url}issue/ticket`,
    getFareQuote:`${base_url}get/fare/quote`,
    getCardPaymentData:`${base_url}card/payment/methods`,
    initiateCardPayment:`${base_url}initiate/card/payment`,
    getRequiredDataAfterPayment:`${base_url}ui/required/data`,

    //Issue ticket list
    issuedTickets:`${base_url}issued-tickets/latest`,
    voidTicket:`${base_url_galelio_post_booking}void/ticket`,
    voidTicketDefault:`${base_url}void/ticket`,
    todayTickets:`${base_url}issued-tickets/today`,
    lastMonthTicket:`${base_url}issued-tickets/last-month`,
    dateRangeTicket:`${base_url}issued-tickets/date-range`,
    refundRequest:`${base_url}ticket-refund-requests/save-v2`,
    getTicketDetails:`${base_url}ticket-refund-requests/ticket-details`,
    getRefundServiceFee:`${base_url}ticket-refund-requests/service-fee`,

    //Queued ticket list
    queuedTickets:`${base_url}queued/bookings`,

    //refund request list
    refundRequestList:`${base_url}ticket-refund-requests/latest`,
    refundRequestListV2:`${base_url}ticket-refund-requests/latest-v2`,
    pendingApproval:`${base_url}ticket-refund-requests/user-approval-pending`,
    approveRefundRequest:`${base_url}ticket-refund-requests/approve`,
    getExistingRefundRequest:`${base_url}ticket-refund-requests/existing-refund-requests`,
    getRefundsByStatus:`${base_url}ticket-refund-requests/by/status`,
    getRefundsByStatusV2:`${base_url}ticket-refund-requests/by/status-v2`,
    declineRefundRequests:`${base_url}ticket-refund-requests/decline`,


    //report section
    getTransactions:`${base_url}auth/user/transactions`,
    getStatements:`${base_url}get/statement/date/details`,
    getSummaryDetails:`${base_url}statement/summary-details`,

    //topUp section
    createRequest:`${base_url}topup/request`,
    latestRequests:`${base_url}latest/topup/requests`,

    //credit card payment
    createPaymentRequest1:`${base_url}card-payment/retrieve-booking/request1`,
    createPaymentRequest2:`${base_url}card-payment/retrieve-booking/request2`,
    createPaymentRequest3:`${base_url}card-payment/retrieve-booking/request3`,
    // cardPaymentLinks:`${base_url}card-payment/payment-links`,
    createPaymentLink:`${base_url_credit_card}card-payment-link/create`,
    cardPaymentLinks:`${base_url_credit_card}card-payment-link/link-by-user`,

    //overdraft section
    overdraftRequest:`${base_url}overdraft-requests/save`,
    overdraftLatest:`${base_url}overdraft-requests/latest`,
    getBalance:`${base_url}overdraft-requests/current-limit`,
    getAllowedBalance:`${base_url}user/inq-pay/account-balance`,

    //Fees section
    getUserTariffs:`${base_url}user-tariffs`,

    //Reissue section
    reissueRequest:`${base_url}reissue-quote-requests/save`,
    reissueRequestV2:`${base_url}reissue-quote-requests/save-v2`,
    getreissueRequests:`${base_url}reissue-quote-requests/latest`,
    approveReissue:`${base_url}reissue-quote-requests/approve`,
    validateTicketNo:`${base_url}validate-ticket-no`,
    getServiceFeeType:`${base_url}reissue-quote-requests/service-fee`,

    //Badges section
    getBadges:`${base_url}badge/get-all`,
    badgeRequest:`${base_url}user/badge/request`,
    badgeStatus:`${base_url}user/badge/get-status`,
    cancelBadgeRequest:`${base_url}user/badge/request-cancel`,
    badgeVerification:`${base_url}user/badge/validation`,

    //Announcement section
    getAnnouncementData:`${base_url}announcement/get/all`,
    getAnnoucementById:`${base_url}announcement/get/details`,

    //Faqs section
    getFaqsData:`${base_url}faq/get/all`,
    getFaqsByKey:`${base_url}faq/search`,
    getFaqsBySuggetions:`${base_url}faq/suggestion`,

    //Dashboard v3 update 
    sectionOneData:`${base_url}dashboard/basic-details`,
    sectionTwoData:`${base_url}latest/transaction/overview`,
    sectionThreeData:`${base_url}dashboard/other-details`,
    sectionFourData:`${base_url}dashboard/latest-transactions`,
    sectionFiveData:`${base_url}dashboard/latest-tickets`,

    //OSI Remarks
    getOsiRemarkDetails:`${base_url_galelio_post_booking}booking/osi-remark-details`,   
    addOsiRemark:`${base_url_galelio_post_booking}booking/modify-osi-remark`, 

    //inqbaytor accounts
    getAccountsData:`${base_url}get/users/inqbaytorpay/accounts`,
    getAllowedAccountData:`${base_url}user/provider-allowed-balances`,
    getInqPayAllowedBalance:`${base_url}user/inq-pay/provider-allowed-balances`,

    //secondary user updates
    createSecondaryUser:`${base_url}create/secondary/user`,
    getUserList:`${base_url}secondary/user/list`,
    resetUserPassword:`${base_url}update/secondar/user/password`,


    ///reissue stepper flow
    checkValidity:`${reissue_url}ticket/check`,
    retriveSegments:`${reissue_url}reissue-request-1`,
    checkPnrValidity:`${reissue_url}reissue-request-2`,
    saveSegments:`${reissue_url}reissue-request-3`,
    passengerMapping:`${reissue_url}reissue-request-4`,
    reissueSubmision:`${reissue_url}reissue-request-5`,
    reissueRequests:`${reissue_url}get/reissue-requests`,
    reissueQuotes:`${reissue_url}get/reissue-request-quotes`,
    requestTransaction:`${reissue_url}request-transaction`,
    resendCodes:`${reissue_url}resendCodes`,
    getServiceFee:`${reissue_url}get/service-charge`,
    getRequestById:`${reissue_url}get/single-reissue-request`,
    reissueVerifyCodes:`${reissue_url}verifycodes`,
    reissueTicket:`${reissue_url}reissue-ticket`,
    getQuoteById:`${reissue_url}get/reissue-request/reissue-request-quotes`,
    getBaggagesById:`${reissue_url}get/baggage-details`,
    approveReissueById:`${reissue_url}user/approval`,


    ///Issued Ticket documents flow
    getDocumentType:`${doc_url}document/get/types`,
    createDocRequest:`${doc_url}document/upload`,
    getDocumentByTicketNumber:`${doc_url}ticket/documents/get`,
    getDocumentByUser:`${doc_url}user/documents/get`,
    getAttachmentById:`${doc_url}get/user/document-url`,

    ///Adm 
    getAdmTransaction:`${base_url}get/adm/transactions`,


    ///agent logo
    agentLogoSubmit:`${base_url}save/ticket/logo`,

    ///ticket pass
    getTicketPassData:`${base_url_ticket_image}ticket/image`,
    initiatePayment:`${base_url_ticket_image}ticket/pass/request-transaction`,
    verifyTicketPassOtp:`${base_url_ticket_image}ticket/pass/verify-codes`,
    resendTicketPassOtp:`${base_url_ticket_image}ticket/pass/resend-codes`,

    ///Event
    eventPing:`${base_url_event}event/ping`,
    eventJazeera:`${base_url_event}J9`,
    eventFlyDubai:`${base_url_event}FZ`,
    eventNDC:`${base_url_event}NDC`,
    eventFits:`${base_url_event}8D`,
    
    ///NDC 
    getSearchResultsNdc:`${base_url_ndc}tg/low/fare/search/request`,
    getFlightDataByIdNdc:`${base_url_ndc}selected/fare/result`,
    getDataBySelectedIndexNdc:`${base_url_ndc}tg/offer/price/request`,
    onBookingValidateNdc:`${base_url_ndc}order/create/request`,    

    ///User guideline
    getUserGuidelines:`${base_url}user-guidelines`,

    ///Fits Air Endpoints
    cancelBookingFitsAir:`${base_url_fits_air_tg}8d/cancel/flight/booking`,
    getCancelBookingSegmentsFitsAir:`${base_url_fits_air_tg}8d/get/cancel/booking/segments`,
    getSegmentAndFairFitsAir:`${base_url_fits_air_tg}8d/retrieve/pricing`,
    getPsgDataFitsAir:`${base_url_fits_air_tg}8d/get/booking/travelers`,
    splitFitsAir:`${base_url_fits_air_tg}8d/split/pnr`,
    getFareRulesDetailsFitsAir:`${base_url_fits_air_tg}8d/get/fare-rules`,
    getTTLdata:`${base_url_fits_air_tg}8d/get/ttl`,
    fitsAirVoid:`${base_url_fits_air_tg}8d/ticket/void`,

    ///Fly dubai Endpoints
    fzGetTTLdata:`${base_url_fz}fz/get/ttl`,
    fzCancelSegments:`${base_url_fz}fz/get/cancel/booking/segments`,
    fzCancelBooking:`${base_url_fz}fz/cancel/flight/booking`,
    fzSegmentAndFare:`${base_url_fz}fz/retrieve/pricing`,

    ///Jazeera Endpoints
    jazeeraGetTTLdata:`${base_url_jazeera}j9/get/ttl`,
    jazeeraCancelSegments:`${base_url_jazeera}j9/get/cancel/booking/segments`,
    jazeeraCancelBooking:`${base_url_jazeera}j9/cancel/flight/booking`,
    jazeeraSegmentAndFare:`${base_url_jazeera}j9/retrieve/pricing`,    

    ///Quotes Endpoints
    createQuotes:`${base_url_quotes}quotation/create`,
    getQuotes:`${base_url_quotes}quotation/get`,
    updateQuotes:`${base_url_quotes}quotation/update`,
    generateQuote:`${base_url_quotes}quotation/get-by-id-v2`,

    ///NDC tg Endpoints
    ndcGetTTLdata:`${base_url_ndc_tg}ndc/get/ttl`,
    ndcCancelSegments:`${base_url_ndc_tg}ndc/get/cancel/booking/segments`,
    ndcCancelBooking:`${base_url_ndc_tg}ndc/cancel/flight/booking`,
    ndcVoid:`${base_url_ndc_tg}ndc/void/flight/booking`,
    ndcVoidValidation:`${base_url_ndc_tg}ndc/void/validation`,

    ///SQNDC tg Endpoints
    sqNdcGetTTLdata:`${base_url_sq_ndc_tg}ttl`,
    sqNdcCancelSegments:`${base_url_sq_ndc_tg}get/cancel/booking/segments`,
    sqNdcCancelBooking:`${base_url_sq_ndc_tg}cancel/flight/booking`,
    sqNdcVoid:`${base_url_sq_ndc_tg}void/flight/booking`,
    sqNdcVoidValidation:`${base_url_sq_ndc_tg}void/validation`,
    sqNdcFareRules:`${base_url_sq_ndc_tg}get/fare-rules`,
    sqNdcRemarks:`${base_url_sq_ndc_tg}get/virtual/remarks`,
    sqNdcGetPsgData:`${base_url_sq_ndc_tg}get/booking/travelers`,
    sqNdcSplit:`${base_url_sq_ndc_tg}split/pnr`,
    sqNdcReprice:`${base_url_sq_ndc_tg}reprice/booking`,
    sqNdcGetSpecialServiceData:`${base_url_sq_ndc_tg}init/data/special-services`,
    sqNdcAddSpecialServices:`${base_url_sq_ndc_tg}add/special-services`,

    ///EKNDC tg Endpoints
    ekNdcGetTTLdata:`${base_url_ek_ndc_tg}ttl`,
    ekNdcCancelSegments:`${base_url_ek_ndc_tg}get/cancel/booking/segments`,
    ekNdcCancelBooking:`${base_url_ek_ndc_tg}cancel/flight/booking`,
    ekNDCgetFareRulesDetails:`${base_url_ek_ndc_tg}booking/fare/details`,
    ekNdcgetFareRules:`${base_url_ek_ndc_tg}booking/fare/rules`,
    ekNdcVoid:`${base_url_ek_ndc_tg}void/flight/booking`,
    ekNdcVoidValidation:`${base_url_ek_ndc_tg}void/validation`,
    ekNdcReprice:`${base_url_ek_ndc_tg}reprice/booking`,
    ekNdcRemarks:`${base_url_ek_ndc_tg}get/virtual/remarks`,
    ekNdcGetPsgData:`${base_url_ek_ndc_tg}get/booking/travelers`,
    ekNdcSplit:`${base_url_ek_ndc_tg}split/pnr`,
    ekGetSsrDetails:`${base_url_ek_ndc_tg}booking/ssr-details`,

    ///Insurance Policy Endpoints
    policySearch:`${base_url_policy}insurance/search/quotation`,
    policyPurchase:`${base_url_policy}insurance/policy-purchase`,
    policyHolders:`${base_url_policy}insurance/policy-holder`,
    policyRequestTransaction:`${base_url_policy}request-transaction`,
    policyOtpResend:`${base_url_policy}resendCodes`,
    policyOtpVerification:`${base_url_policy}verifycodes`,
    policyStatus:`${base_url_policy}insurance/policies-status/v2`,
    policyPurchaseList:`${base_url_policy}insurance/policies-purchase-list/v2`,
    policyPdf:`${base_url_policy}insurance/policies/pdf-download`,
    policyRequestForApproval:`${base_url_policy}insurance/pay/request/for/approval`,
    policyApprovalRequests:`${base_url_policy}insurance/request/approval/list`,
    declineApprovalRequest:`${base_url_policy}insurance/cancel/request/approval`,

    ///User Roles Endpoints
    getUserRoles:`${base_url}get/sub/user/roles`,
    createSubUserAccount:`${base_url}create/sub/user/account`,
    getSubUserList:`${base_url}get/sub/user/list`,
    changeSubUserpassword:`${base_url}reset/sub/user/generated/password`,
    requestForApproval:`${base_url}pay/request/for/approval`,
    requestForApprovalList:`${base_url}request/approval/list`,
    updateSecondaryuser:`${base_url}update/sub/user/names`,
    checkSubUserLink:`${base_url}valid/complete/sub/user/link`,
    completeSubUser:`${base_url}complete/sub/user/details`,
    updateSubUserDetails:`${base_url}update/sub/user/details`,
    deleteSubUser:`${base_url}delete/sub/user`,
    disableSubUser:`${base_url}disable/sub/user`,
    enableSubUser:`${base_url}enable/sub/user`,
    getBoookingListByType:`${base_url}get/flight/booking/details`,
    declineRequestForApprovalBooking:`${base_url}cancel/request/approval`,
    resendEmail:`${base_url}resend/sub/user/email/verification`,
    getSubUserCompletionDetails:`${base_url}sub/user/name/details`,

    ///APi PDF
    generatePdfByPaymentId:`${base_url_generate_pdf}generate-pdf-by-payment-id`,
    generatePdfByTicketId:`${base_url_generate_pdf}generate-pdf-by-ticket-id`,
    generateItenararyPdf:`${base_url_generate_ticket_pdf}generate-itinerary-pdf`,
    generateTicketPdf:`${base_url_generate_ticket_pdf}generate-ticket-pdf`,
  
}