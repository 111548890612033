<ngx-spinner></ngx-spinner>
<div class="container-fluid">
    <div class="card" *ngIf="!loading" (click)="changeStep()" style="cursor: pointer;">
        <div class="row m-0 p-2">
            <div class="col-md-4">
                <i class="fas fa-map-marker-alt"></i> {{searchedData.destination_name}}
            </div>
            <div class="col-md-4">
                <span>
                    <i class="fas fa-calendar-alt"></i> {{searchedData.check_in}}
                </span>
                -
                <span>
                    <i class="fas fa-calendar-alt"></i> {{searchedData.check_out}}
                </span>
            </div>
            <div class="col-md-3">
                <span>
                    <i class="fas fa-male"></i> {{searchedData.adultCount}}
                </span>
                <span>
                    <i class="fas fa-child"></i> {{searchedData.childCount}}
                </span>
                <span>
                    <i class="fas fa-bed"></i> {{searchedData.roomCount}}
                </span>
                <span>
                    <i class="fas fa-moon"></i> {{searchedData.noOfNights}}
                </span>
            </div>
            <div class="col-md-1 d-flex justify-content-end text-purple">
                <i class="far fa-edit font-size-20"></i>
            </div>
        </div>
    </div>
    <app-page-title title="Hotel Results"></app-page-title>
    <app-hotel-result-shimmer *ngIf="loading"></app-hotel-result-shimmer>
    <!-- <div *ngIf='!loading' class="mb-3">
        <carousel [objectFit]="'fill'" [cellsToShow]="8">
            <div class="carousel-cell" *ngFor="let bankImage of promoDetails; let i = index" [ngClass]="selectedPromoIndex == i ? 'custom-carousel1' : 'custom-carousel'" >
                <img  (click)="onPromo(bankImage.pid,i)"  src="{{bankImage.promoLog}}">
            </div>
        </carousel>
    </div> -->
    <div class="row" *ngIf="!loading">
        <div class="col-xl-3 col-lg-4">
            <div class="card" style="padding-right: 20px;padding-left: 20px;">
                <div class="bg-transparent border-bottom">
                    <h5 class="mb-0">Filters</h5>
                </div>
                <div class="pt-3 border-bottom">
                    <h5 class="font-size-14 mb-3 text-purple">{{searchResult.currency}} Price</h5>
                    <ng5-slider [(value)]="value" [(highValue)]="highValue" [options]="options"
                        (userChangeEnd)="getPriceFilter($event)"></ng5-slider>
                </div>
                <div class="pt-3 border-bottom">
                    <div>
                        <h5 class="font-size-14 mb-0"><a (click)="collapse.toggle()" href="javascript:void(0);"
                                class="d-block text-purple">Star <i
                                    class="mdi mdi-chevron-up float-end accor-down-icon"></i></a></h5>
                        <div class="collapse show" id="filterprodductcolor-collapse" [(ngbCollapse)]="colorCollapse1"
                            #collapse="ngbCollapse">
                            <div class="mt-3">
                                <div class="form-check"
                                    *ngFor="let star of searchResult.filter_star.filterData; let i = index">
                                    <input (change)="starChange(star)" class="form-check-input" type="checkbox"
                                        id="star-{{i}}" [checked]="getStarCodeStates(star)">
                                    <label class="form-check-label" for="star-{{i}}">
                                        <span *ngFor="let s of counter(star); let i = index"
                                            style="font-weight: 400;">
                                            <span *ngIf="star != 0"><i class="fas fa-star"
                                                    style="color: #F19C4F;"></i></span>
                                        </span>
                                        <span *ngIf="star == 0" style="font-weight: 400;"><i
                                                class="far fa-star" style="color: #F19C4F;"></i> Other</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pt-3 border-bottom">
                    <div>
                        <h5 class="font-size-14 mb-0"><a (click)="collapse2.toggle()" href="javascript:void(0);"
                                class="d-block text-purple">Accommodation <i
                                    class="mdi mdi-chevron-up float-end accor-down-icon"></i></a></h5>
                        <div class="collapse show" id="filterprodductcolor-collapse" [(ngbCollapse)]="colorCollapse2"
                            #collapse2="ngbCollapse">
                            <div class="mt-3">
                                <div class="form-check"
                                    *ngFor="let accommodation of hotelFileters.filter_accommodation.filterData; let i = index">
                                    <input (change)="accommodationChange(accommodation)" class="form-check-input"
                                        type="checkbox" id="accommodation-{{i}}"
                                        [checked]="getaccommodationCodeStates(accommodation)">
                                    <label class="form-check-label" for="accommodation-{{i}}">
                                        <span style="font-weight: 400;">{{accommodation}}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pt-3 border-bottom">
                    <div>
                        <h5 class="font-size-14 mb-0"><a (click)="collapse3.toggle()" href="javascript:void(0);"
                                class="d-block text-purple">Hotel Facilities<i
                                    class="mdi mdi-chevron-up float-end accor-down-icon"></i></a></h5>
                        <div class="collapse show" id="filterprodductcolor-collapse" [(ngbCollapse)]="colorCollapse3"
                            #collapse3="ngbCollapse">
                            <div class="mt-3">
                                <div class="form-check"
                                    *ngFor="let facilities of hotelFileters.filter_room.filterData; let i = index">
                                    <input (change)="facilitiesChange(facilities)" class="form-check-input"
                                        type="checkbox" id="facilities-{{i}}"
                                        [checked]="getfacilitiesCodeState(facilities)">
                                    <label class="form-check-label" for="facilities-{{i}}">
                                        <span style="font-weight: 400;">{{facilities}}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pt-3 border-bottom">
                    <div>
                        <h5 class="font-size-14 mb-0"><a (click)="collapse4.toggle()" href="javascript:void(0);"
                                class="d-block text-purple">Meal<i
                                    class="mdi mdi-chevron-up float-end accor-down-icon"></i></a></h5>
                        <div class="collapse show" id="filterprodductcolor-collapse" [(ngbCollapse)]="colorCollapse4"
                            #collapse4="ngbCollapse">
                            <div class="mt-3">
                                <div class="form-check"
                                    *ngFor="let meal of hotelFileters.filter_meal.filterData; let i = index">
                                    <input (change)="mealChange(meal)" class="form-check-input" type="checkbox"
                                        id="meal-{{i}}" [checked]="getamealCodeState(meal)">
                                    <label class="form-check-label" for="meal-{{i}}">
                                        <span style="font-weight: 400;">{{meal}}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pt-3 border-bottom" *ngIf="hotelFileters.filter_board.filterData.length > 0">
                    <div>
                        <h5 class="font-size-14 mb-0"><a (click)="collapse5.toggle()" href="javascript:void(0);"
                                class="d-block text-purple">Board<i
                                    class="mdi mdi-chevron-up float-end accor-down-icon"></i></a></h5>
                        <div class="collapse show" id="filterprodductcolor-collapse" [(ngbCollapse)]="colorCollapse5"
                            #collapse5="ngbCollapse">
                            <div class="mt-3">
                                <div class="form-check"
                                    *ngFor="let board of hotelFileters.filter_board.filterData; let i = index">
                                    <input (change)="boardChange(board)" class="form-check-input" type="checkbox"
                                        id="board-{{i}}" [checked]="getaboardCodeState(board)">
                                    <label class="form-check-label" for="board-{{i}}">
                                        <span style="padding-left: 5px;font-weight: 400;">{{board}}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-9 col-lg-8">
            <div class="card">
                <div class="card-body">
                    <div *ngIf="filterLoading">
                        <div class="box shine"></div>
                        <div class="box shine"></div>
                        <div class="box shine"></div>
                        <div class="box shine"></div>
                        <div class="box shine"></div>
                        <div class="box shine"></div>
                        <div class="box shine"></div>
                        <div class="box shine"></div>
                        <div class="box shine"></div>
                        <div class="box shine"></div>
                    </div>
                    <div *ngIf="!filterLoading">
                        <div class="row mt-2 m-0 p-0"
                            *ngFor="let hotel of totalHotels| paginate: { itemsPerPage: 20, currentPage:currentPage }"
                            id="hotel-{{hotel.code}}">
                            <div class="col-lg-12 p-3 card main-card">
                                <div class="row">
                                    <div class="col-md-5">
                                        <img style="border-radius: 2px; object-fit: fill;" src="https://photos.hotelbeds.com/giata/{{hotel.other.image}}" alt=""
                                            height="180px" width="100%" onerror="this.style.display='none'" />
                                    </div>
                                    <div class="col-md-7">
                                        <h5 class="hotelName">{{hotel.name}}</h5>
                                        <p class="mb-0"> 
                                            <span *ngFor="let s of counter(hotel.starRating); let i = index"
                                                [ngClass]="i == 0 ? 'starPaddingDisable':'starPaddingActive'">
                                                <span *ngIf="star != 0"><i class="fas fa-star"
                                                        style="color: #F19C4F;"></i>
                                                </span>
                                            </span>
                                        </p>
                                        <p class="mb-0" style="white-space: nowrap;text-overflow:ellipsis;overflow: hidden;">{{hotel.city}} / {{hotel.zoneName}}</p>
                                        <p class="mb-0">{{hotel.terminal == '' ? '-':hotel.terminal}}</p>
                                        <p class="mb-0">
                                            <span *ngFor="let fIcon of hotel.facilities"
                                                class="crazy-hotel-text-align-justify">
                                                <i class="crazy-hotel-facility {{fIcon.class}}"></i>
                                            </span>
                                        </p>
                                        <div *ngIf="!priceLoading" class="col-md-12"
                                            style="display: flex;justify-content: space-between;align-items: end">
                                            <div>
                                                <h6 class="originalPrice bg-soft-dark">{{searchResult.currency}}
                                                    {{hotel.price.originalPrice | number : '1.2-2'}}</h6>
                                            </div>
                                            <div>
                                                <h6 class="discountedPrice  bg-soft-success">{{searchResult.currency}}
                                                    {{hotel.price.discountPrice | number : '1.2-2'}}</h6>
                                            </div>
                                            <div>
                                                <button style="margin-left: 12px;" (click)="onResultSelect(hotel.code)"
                                                    type="submit" class="btn btn-primary btn-theme me-1">
                                                    See Rooms
                                                </button>
                                            </div>
                                        </div>
                                        <div *ngIf="priceLoading" class="row">
                                            <div class="col-xl-3 col-lg-4 lines shine"></div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <hr> -->
                                <!-- <hr style="color:#07253f;height: 0.5px;">
                                <div *ngIf="!priceLoading" class="col-md-12"
                                    style="display: flex;justify-content: end;align-items: end;">
                                    <div>
                                        <h6 class="originalPrice bg-soft-dark">{{searchResult.currency}}
                                            {{hotel.price.originalPrice | number : '1.2-2'}}</h6>
                                    </div>
                                    <div>
                                        <h6 class="discountedPrice  bg-soft-success">{{searchResult.currency}}
                                            {{hotel.price.discountPrice | number : '1.2-2'}}</h6>
                                    </div>
                                    <div>
                                        <button style="margin-left: 12px;" (click)="onResultSelect(hotel.code)"
                                            type="submit" class="btn btn-primary me-1">
                                            See Rooms
                                        </button>
                                    </div>
                                </div>
                                <div *ngIf="priceLoading" class="row">
                                    <div class="col-xl-3 col-lg-4 lines shine"></div>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-12 text-center mt-2">
                            <pagination-controls maxSize="9" class="my-pagination" (pageChange)="pageChanged($event)"
                                autoHide="true" screenReaderCurrentLabel="You're on page" screenReaderPageLabel="page">
                            </pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>