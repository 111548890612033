// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { redirectUrls } from "src/app/core/helpers/redirect-url";

export const environment = {
  production: false,
  version:'1.2.50',
  server:redirectUrls.currentServer, 

  ENCRYPT_KEY1: "efa475c7cf87fc1252b8fa9092b51fb37e347384fcb2c0a04e2616b67e4f69ca",
  ENCRYPT_KEY2: "161f792db2475b5d164b6eea39067e31ac44d481b911a2a45c6b85aeb1c76baf",
  
  //*Ticket Gadget Api Endpoints
  // base_url :"http://localhost:8000/PRE-PRODUCTION/ticketgadget-api/localapi.ticketgadget.com/public/api/",
  // base_url :"https://ticketgadgetlk-api.inqbaytor.co/api/",
  base_url :"https://ticketgadgetlk-api.inqbaytor.com.au/api/",
  reissue_url : "http://reissue.inqbaytor.co/api/",
  donwloadTicket : "https://pdf.inqbaytor.com/#//booking",
  downloadQuotation :"https://pdf.inqbaytor.com/#//quote",
  gds : 'https://advancesearch.inqbaytor.co/#/NDCSimulator',
  donwloadTicket1 : "https://pdf.inqbaytor.com/#/",
  
  //*CrazyJets Api Endpoints
  base_url_cjw :"https://cjwapi.inqbaytor.co/api/",

  //*FlyDubai Api Endpoints
  base_url_fly_dubai:"https://fzapi.inqbaytor.com/api/",

  //*Jazeera Api Endpoints
  base_url_jazeera:"https://j9api.inqbaytor.co/api/",

  //*Document upload Api Endpoints
  base_url_documents:"https://document.inqbaytor.co/api/",

  //*Ticket image Api Endpoints
  base_url_ticket_image:"https://ticketimage.inqbaytor.co/api/",

  //*event image Api Endpoints
  base_url_event:"https://events.inqbaytor.co/api/",
  
  //*Hote; Api Endpoints
  base_url_hotel:"https://hotelsapi.crazyjets.com.au/api/",  

  //*NDC Api Endpoints
  base_url_ndc : "https://wyndc.inqbaytor.com/api/",

  //*Payment Link new
  base_url_credit_card : "https://paymentlink.inqbaytor.co/api/",

  //*FIts Air Api Endpoints
  base_url_fits_air : "https://8d.inqbaytor.co/api/",

  //*Fits Air Api Endpoints TG
  base_url_fits_air_tg : "https://8d-pbs.inqbaytor.co/api/",

  //*Fly dubai api enpoint
  base_url_fz : "https://fz-pbs.inqbaytor.co/api/",

  //*Jazeera api endpoints
  base_url_jazeera_tg :"https://j9-pbs.inqbaytor.co/api/",

  //*Quotes api endpoints
  base_url_quotes:"https://quoteapi.inqbaytor.co/api/",

  //*Ndc api endpoints
  base_url_ndc_tg:"https://n182pbs.inqbaytor.co/api/",

  //*SQNdc api endpoints
  base_url_sq_ndc_tg:"https://n181pbs.inqbaytor.co/api/sqndc/",

  //*SQNdc api endpoints
  base_url_ek_ndc_tg:"https://n172pbs.inqbaytor.co/api/ekndc/",

////////////////////////////////////////////////////////////////
  //*CrazyJets Api Endpoints AU
  base_url_cjw_au :"https://cjwauapi.inqbaytor.co/api/",

  //*Insurance policy api endpoints
  base_url_policy:"https://insurance.inqbaytor.co/api/",

  //*Galelio Post booking
  base_url_galelio_post_booking:"https://1g-pbs.inqbaytor.co/api/1G/",

  //*API PDF
  base_url_generate_pdf:"https://paymentinvoice.inqbaytor.co/api/",

  //*Sq ndc policy api endpoints
  base_url_sq_ndc:"https://wyndc.inqbaytor.co/api/",

  //*EK ndc policy api endpoints
  base_url_ek_ndc:"https://n172.inqbaytor.co/api/",  

  //*G9 policy api endpoints
  base_url_g9:"https://aro1.inqbaytor.co/api/",   

  //*ticket pdf api endpoints
  base_url_generate_ticket_pdf:'https://pdf-apiv2.inqbaytor.co/api/',

  valueCj : 'https://cjwapi.inqbaytor.co/api/tg/search/results',
  valueJ9 : 'https://j9api.inqbaytor.co/api/availability/request',
  valueFz : 'https://fzapi.inqbaytor.com/api/tg/flight-search',
  valueWy : 'https://wyndc.inqbaytor.co/api/tg/low/fare/search/request',
  valueSq : 'https://wyndc.inqbaytor.com/api/tg/low/fare/search/request',
  valueFits : 'https://8d.inqbaytor.co/api/tg/low/fare/search/request',
  isEventUrl : 'https://events.inqbaytor.co/api/event/ping',
  
  defaultauth: 'fackbackend',
  firebase: {
    apiKey: "AIzaSyCPYIQ6MvKfpjggwhHJqDH3XE2yvQJK2ng",
    authDomain: "tgapp-aa244.firebaseapp.com",
    databaseURL: "https://tgapp-aa244.firebaseio.com",
    projectId: "tgapp-aa244",
    storageBucket: "tgapp-aa244.appspot.com",
    messagingSenderId: "204974528182",
    appId: "1:204974528182:web:869d0f1c2b8a9e08937817",
    measurementId: "G-FS07BQGF53"
  }

};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
