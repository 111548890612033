//localhost
// const url = 'https://tghome.inqbaytor.me/login';
// const durl = 'https://tghome.inqbaytor.me/dashboard';

// localhost
// const url = 'http://localhost:5000/login';
// const durl = 'http://localhost:5000/dashboard';
// const furl = 'http://localhost:4200'

//pp
// const url = 'https://tguihomepp.enabledbeings.com/login';
// const durl = 'https://tguihomepp.enabledbeings.com/dashboard';
// const furl = 'http://localhost:4200'

// // //pp tguiv5pp
// const url = 'https://tguiv3pp.enabledbeings.com/login';
// const durl = 'https://tguiv3pp.enabledbeings.com/dashboard';
// const furl = 'https://tguihomepp.enabledbeings.com'

//test
// const url = 'https://tguihome.enabledbeings.com/login';
// const durl = 'https://tguihome.enabledbeings.com/dashboard';

//live
// const url = 'https://ticketgadget.com/login';
// const durl = 'https://ticketgadget.com/dashboard';

//paymentTest
// const url = 'https://paymentupdateuilogin.enabledbeings.com/login';
// const durl = 'https://paymentupdateuilogin.enabledbeings.com/dashboard';


//liveTesting
const url = 'https://tghome.inqbaytor.me/login';
const durl = 'https://tgapp.inqbaytor.me/dashboard';
const furl = 'https://tgapp.inqbaytor.me/'


export const redirectUrls = {
    redirectLogin : `${url}`,
    redirectDashboard:`${durl}`,
    currentServer:`${furl}`,
}