<ngx-spinner></ngx-spinner>
<div class="container-fluid">
    <div class="card" (click)="changeStep()" style="cursor: pointer;">
        <div class="row m-0 p-2">
            <div class="col-md-4">
                <i class="fas fa-map-marker-alt"></i> {{searchedData.destination_name}}
            </div>
            <div class="col-md-4">
                <span>
                    <i class="fas fa-calendar-alt"></i> {{searchedData.check_in}}
                </span>
                -
                <span>
                    <i class="fas fa-calendar-alt"></i> {{searchedData.check_out}}
                </span>
            </div>
            <div class="col-md-3">
                <span>
                    <i class="fas fa-male"></i> {{searchedData.adultCount}}
                </span>
                <span>
                    <i class="fas fa-child"></i> {{searchedData.childCount}}
                </span>
                <span>
                    <i class="fas fa-bed"></i> {{searchedData.roomCount}}
                </span>
            </div>
            <div class="col-md-1 d-flex justify-content-end text-orange">
                <i class="far fa-edit font-size-20"></i>
            </div>
        </div>
    </div>
    <app-page-title title="Hotel Details"></app-page-title>
    <div *ngIf="roomLoading || hotelLoading || promoloading">
        <div class="box shine"></div>
        <div class="box shine"></div>
        <div class="box shine"></div>
        <div class="box shine"></div>
        <div class="box shine"></div>
        <div class="box shine"></div>
        <div class="box shine"></div>
        <div class="box shine"></div>
        <div class="box shine"></div>
        <div class="box shine"></div>
    </div>

    <div class="row">
        <!-- <div class="col-md-7">
            <ngb-carousel style="display:unset" [showNavigationIndicators]="true" #myCarousel="ngbCarousel">
                <ng-template ngbSlide *ngFor="let item of images;let i = index" id="slideId-{{i}}">
                    <img src="{{item}}" style="object-fit: contain;max-height: 500px;" alt="" class="animated fadeIn img-fluid">
                </ng-template>
            </ngb-carousel>
        </div> -->
        <div class="col-md-12">
            <div *ngIf="!roomLoading && !hotelLoading && !promoloading">
                <ngx-gallery id="gallery" (change)="onChangeImage(i)" [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery>
            </div>
        </div>
    </div>
    <div class="card main-card p-3" *ngIf='!promoloading && !roomLoading && !hotelLoading && roomData.length > 0'>
        <div class="row">
            <div class="col-md-4 roomCardContain">
                No of Rooms Selected : {{finalSelectedRoomCount}}
            </div>
            <div class="col-md-4 roomCardContain">
                Total amount : {{roomData[0].rates[0].currencyCode}} {{finalSelectedTotalAmount | number : '1.2-2'}}
            </div>
            <div class="col-md-4 roomCardContain">
                <button (click)="onBooking()" style="margin-left: 12px;" type="submit" class="btn btn-primary btn-theme me-1">
                    Book
                </button>
            </div>
        </div>
    </div>
    <div class="col-lg-12" *ngIf='!roomLoading && !hotelLoading && !promoloading'>
        <div class="card">
            <div class="card-body">
                <ul ngbNav #nav="ngbNav" [activeId]="2" class="nav-tabs nav-tabs-custom nav-justified">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>
                            <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                            <span class="d-none d-sm-block">Overview</span>
                        </a>
                        <ng-template ngbNavContent>
                            <ng-template [ngTemplateOutlet]="TabContent1"></ng-template>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink>
                            <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                            <span class="d-none d-sm-block">Rooms</span>
                        </a>
                        <ng-template ngbNavContent>
                            <ng-template [ngTemplateOutlet]="TabContent2"></ng-template>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3">
                        <a ngbNavLink>
                            <span class="d-block d-sm-none"><i class="far fa-envelope"></i></span>
                            <span class="d-none d-sm-block">Hotel Facilities</span>
                        </a>
                        <ng-template ngbNavContent>
                            <ng-template [ngTemplateOutlet]="TabContent3"></ng-template>
                        </ng-template>
                    </li>
                    <li *ngIf="this.hotelDetails.terminal" [ngbNavItem]="4">
                        <a ngbNavLink>
                            <span class="d-block d-sm-none"><i class="fas fa-cog"></i></span>
                            <span class="d-none d-sm-block">Near By</span>
                        </a>
                        <ng-template ngbNavContent>
                            <ng-template [ngTemplateOutlet]="TabContent4"></ng-template>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav"></div>
            </div>
        </div>
    </div>
</div>

<!-- Overview Content -->
<ng-template #TabContent1>
    <div class="col-md-12 m-3">
        <h5 class="hotelName">{{hotelDetails.name}}
            <span *ngFor="let s of counter(hotelDetails.starRating); let i = index"
                style="padding-left: 5px;font-weight: 400;">
                <span *ngIf="hotelDetails.starRating != 0"><i class="fas fa-star"
                        style="color: #F19C4F;"></i></span>
            </span>
        </h5>
        <p class="mb-0">{{hotelDetails.categoryDescription}}</p>
        <p class="mb-0">{{hotelDetails.countryDescription}}</p>
        <p>
            <span *ngFor="let fIcon of hotelFacilityIcon" class="crazy-hotel-text-align-justify">
                <i class="crazy-hotel-facility {{fIcon.class}}"></i>
            </span>
        </p>
        <p class="mt-1">{{hotelDetails.description}}</p>
    </div>
</ng-template>

<!-- Rooms Content -->
<ng-template #TabContent2>
    <div class="col-md-12 m-3">
        <div class="row" *ngFor="let room of roomData; let r = index">
            <div class="col-md-4">
                <ngx-gallery [options]="galleryOptionRooms" [images]="roomImages[r]" class="ngx-gallery"></ngx-gallery>
            </div>
            <div class="col-md-8">
                <h5>{{room.name}}</h5>
                <div class="accordionpage">
                    <ngb-accordion #acc="ngbAccordion" [closeOthers]="true">
                      <ngb-panel id="static-1">
                        <ng-template ngbPanelTitle >
                            <div class="row">
                                <div class="col-6 d-flex align-items-center text-left"
                                    style="justify-content: flex-start;">
                                    <h6>Room Facilities</h6>
                                </div>
                                <div class="col-6 d-flex align-items-center text-right"
                                    style="justify-content: flex-end;">
                                    <i class="fas fa-chevron-down"></i>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div>
                                <p><i class="fas fa-bed"></i> {{room.roomstay.BED[1]}}</p>
                            </div>
                        </ng-template>
                      </ngb-panel>
                    </ngb-accordion>
                </div>
                <!-- <h6>Show room facilities <i class="fas fa-angle-down"></i></h6> -->

                <div class="row board" *ngFor="let rates of room.rates; let rateIndex = index">
                    <div class="col-md-4">
                        <p class="mb-0">{{rates.boardName}}</p>
                        <span *ngFor="let s of counter(rates.maxAdult); let i = index"
                            style="padding-left: 5px;font-weight: 400;">
                            <span *ngIf="rates.maxAdult != 0"><i class="fas fa-user" style="color: #f1734f;"></i></span>
                        </span>
                        <span *ngFor="let s of counter(rates.maxchild); let i = index"
                            style="padding-left: 5px;font-weight: 400;">
                            <span *ngIf="rates.maxchild != 0"><i class="fas fa-user"
                                    style="color: #f1734f;font-size: 9px;"></i></span>
                        </span>
                    </div>
                    <div class="col-md-4">
                        <h6 class="originalPrice bg-soft-dark mb-1">{{rates.currencyCode}} {{rates.originalPrice | number
                            : '1.2-2'}}
                        </h6>
                        <h6 class="discountedPrice bg-soft-success mb-0">{{rates.currencyCode}} {{rates.discountPrice |
                            number :'1.2-2'}}
                        </h6>
                    </div>
                    <div class="col-md-4">
                        <select class="form-select" placeholder="Select No of Rooms"
                            (change)="onRoomChange($event.target.value,r,rateIndex)"
                            (focus)="onClickOnSelect($event.target.value,r,rateIndex)"
                            >
                            <option *ngFor="let count of roomCounter(room.rates[rateIndex].availableRoomCount+1);let i = index"
                                value="{{i}}" [selected]="i == room.rates[rateIndex].selectedRoomCount?true:false">{{i}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <hr>
        </div>
    </div>
</ng-template>

<!-- Rooms Content -->
<ng-template #TabContent3>
    <div class="col-md-12 m-3">
        <div class="row">
            <div class="col-md-6">
                <div *ngFor="let facility of hotelDetails.facilities;odd as isOdd;">
                    <div *ngIf="isOdd">
                        <h6>{{facility.name}}</h6>
                        <div class="p-3">
                            <p class="mb-0" *ngFor="let inclusions of facility.facilities.inclusions">
                                {{inclusions.facilityDescription}} <span *ngIf="inclusions.number">{{inclusions.number}}</span>
                            </p>
                            <div>
                                <h6 class="mt-2" *ngIf="facility.facilities.exclusions.length > 0">Exclusions</h6>
                                <p class="mb-0" *ngFor="let inclusions of facility.facilities.exclusions">
                                    {{inclusions.facilityDescription}} <span *ngIf="inclusions.number">{{inclusions.number}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div *ngFor="let facility of hotelDetails.facilities;even as isEven;">
                    <div *ngIf="isEven">
                        <h6>{{facility.name}}</h6>
                        <div class="p-3">
                            <p class="mb-0" *ngFor="let inclusions of facility.facilities.inclusions">
                                {{inclusions.facilityDescription}} <span *ngIf="inclusions.number">{{inclusions.number}}</span>
                            </p>
                            <div>
                                <h6 class="mt-2" *ngIf="facility.facilities.exclusions.length > 0">Exclusions</h6>
                                <p class="mb-0" *ngFor="let inclusions of facility.facilities.exclusions">
                                    {{inclusions.facilityDescription}} <span *ngIf="inclusions.number">{{inclusions.number}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Rooms Content -->
<ng-template #TabContent4>
    <div class="col-md-12 m-3">
        <h4>Nearest Points</h4>
        <div class="terminal">
            <p *ngFor="let terminal of this.hotelDetails.terminal" class="mb-0">&#x2022; {{(terminal.distance/1000)}} Km
                from {{terminal.name}}</p>
        </div>
        <h4 *ngIf="this.hotelDetails.interestPoints != null && this.hotelDetails.interestPoints.length > 0">Interest
            Points</h4>
        <div class="terminal">
            <p *ngFor="let interest of this.hotelDetails.interestPoints" class="mb-0">&#x2022;
                {{(interest.distance/1000)}} Km from {{interest.name}}</p>
        </div>
    </div>
</ng-template>