import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {Location} from '@angular/common'; 


@Injectable({
    providedIn: 'root'
})
export class FlightSearchService {
    constructor(
        private http: HttpClient,
        private location: Location,
        ) { 
            location.replaceState('flightmanagement/flight-search')
        }

    getGithubAccounts(term: string = null) {
        if (term) {
            return this.http.get<any>(`https://api.github.com/search/users?q=${term}`).pipe(map(rsp => rsp.items));
        } else {
            return of([]);
        }
    }

    getAlbums() {
        return this.http.get<any[]>('https://jsonplaceholder.typicode.com/albums');
    }

    getPhotos() {
        return this.http.get<any[]>('https://jsonplaceholder.typicode.com/photos');
    }

    getLeavingfromData(term: string = null): Observable<any[]> {
        let tempitems = require('./airplaneDetail.json');
        var items = tempitems.dataAirport;
        if (term) {
            for(let i=0; i<items.length;i++){
                if(items[i]['city'] != ""){
                    items[i]['key'] = items[i]['airport_name']+","+items[i]['city']+","+items[i]['country']+" ("+items[i]['airport_code']+")";
                }else{
                    items[i]['key'] = items[i]['airport_name']+","+items[i]['country']+" - All Airports ("+items[i]['airport_code']+")";
                }
            }
            items = items.filter(x => x['key'].toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
        }
        return of(items).pipe(delay(0));
    }

    cleardata():Observable<any[]>{
        var items1 = []
        return of(items1).pipe(delay(0));
    }
}