<ngx-spinner></ngx-spinner>
<div class="container-fluid">
    <!-- start page title -->
    <app-page-title title="Hotel Search"></app-page-title>
    <!-- end page title -->

    <div class="card">
        <!-- <ngx-intl-tel-input [preferredCountries]="['lk']" [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true" name="phoneNumber">
        </ngx-intl-tel-input> -->
        <div class="card-body">
            <form (ngSubmit)="typeSubmit()" [formGroup]="searchHotelForm">
                <div>
                    <div class="row d-flex justify-content-between mt-3">
                        <div class="col-md-6">
                            <h6>Destination or Hotel Name</h6>
                            <div class="input-group">
                                <ng-select style="width: 100%" (add)="onSelectOrigin($event)"
                                    (search)="changeWhereToFrom($event)" (focus)="changeWhereToFrom('focus')"
                                    [clearable]="true" placeholder="City / Hotel" formControlName="whereTo"
                                    [closeOnSelect]="true" [ngClass]="{'is-invalid': typesubmit && type.whereTo.errors}"
                                    bindLabel="name" [minTermLength]="3"
                                    typeToSearchText="Please enter 2 or more characters" [hideSelected]="false"
                                    [loading]="leavingFromLoading" [typeahead]="leavingFromInput"
                                    (clear)="clearleavingFromValues()" [clearOnBackspace]="true"
                                    [items]="leavingFromValues" [editableSearchTerm]="true">
                                </ng-select>
                            </div>
                            <div *ngIf="typesubmit && type.whereTo.errors" class="invalid-feedback">
                                <span *ngIf="type.whereTo.errors.required">This value is required.</span>
                            </div>
                        </div>
                        <div class="mb-3 col-md-6">
                            <h6>Travellers & Rooms</h6>
                            <div class="input-group">
                                <div class="input-group-text"><i class="fas fa-clipboard-list"></i></div>
                                <input style="width: 88%;" (click)="openModal(content)"
                                    formControlName="travelersDetails"
                                    [ngClass]="{'is-invalid': typesubmit && type.travelersDetails.errors}"
                                    placeholder="Add Cabin class & Travellers" type="text" class="form-control"
                                    readonly />
                            </div>
                            <div *ngIf="typesubmit && type.travelersDetails.errors" class="invalid-feedback">
                                <span *ngIf="type.travelersDetails.errors.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3 d-flex justify-content-between">
                        <div class="mb-3 col-md-6" *ngIf="!isOnewaySelected">
                            <h6>Check In - Check Out    <span *ngIf="numberOfNights">({{numberOfNights}} <span *ngIf="numberOfNights == 1">night</span><span *ngIf="numberOfNights != 1">nights</span>)</span></h6>
                            <div class="input-group">
                                <div class="input-group-text"><i class="fas fa-calendar-alt"></i></div>
                                <input placeholder="Select Date Range" style="width: 88%;" type="text"
                                    class="form-control date" formControlName="dateRange"
                                    [ngClass]="{'is-invalid': typesubmit && type.dateRange.errors}"
                                    (click)="hidden=!hidden" name="daterange" autocomplete="off" [(ngModel)]="selected"
                                    readonly />
                            </div>
                            <div *ngIf="typesubmit && type.dateRange.errors" class="invalid-feedback">
                                <span *ngIf="type.dateRange.errors.required">This value is required.</span>
                            </div>

                            <ngb-datepicker (blur)="testing()" class="ngb-datepicker" #dp
                                (dateSelect)="onDateSelection($event)" [displayMonths]="2" [autoClose]="'outside'"
                                [minDate]="minDate" [dayTemplate]="t" outsideDays="hidden" [hidden]="hidden">
                            </ngb-datepicker>
                        </div>
                    </div>
                    <div class="mb-3 mb-0 col-12 d-flex justify-content-end">
                        <div>
                            <button [disabled]="!searchHotelForm.valid" type="submit" class="btn btn-primary btn-theme me-1">
                                Search Hotels
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Canbin & passengers Modal -->
<ng-template #content role="document" let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0">Travelers & Rooms</h5>
        <button type="button" class="btn-close" aria-hidden="true" (click)="closeModal()"></button>
    </div>
    <form (ngSubmit)="travellersSubmit()" [formGroup]="travelersForm">
        <div class="modal-body">
            <label class="mt-2">ADULT [ * ≥ 12 ]</label>
            <div class="input-group" style="width: 150px;">
                <div (click)="adultDecrement()" class="input-group-text"><i class="fas fa-minus"></i></div>
                <input readonly style="width:30px;" type="text" class="form-control date" autocomplete="off"
                    formControlName="adults" [ngClass]="{'is-invalid':travelerssubmit && travelers.adults.errors}" />
                <div (click)="adultIncrement()" class="input-group-text"><i class="fas fa-plus"></i></div>
            </div>
            <label class="mt-2">CHILD [* < 12]</label>
                    <div class="input-group" style="width: 150px;">
                        <div (click)="childrentDecrement()" class="input-group-text"><i class="fas fa-minus"></i></div>
                        <input readonly style="width:30px;" type="text" class="form-control date" autocomplete="off"
                            formControlName="children"
                            [ngClass]="{'is-invalid':travelerssubmit && travelers.children.errors}" />
                        <div (click)="childrentIncrement()" class="input-group-text"><i class="fas fa-plus"></i></div>
                    </div>
                    <div style="width: 150px;" class="row mt-1" style="display:flex">
                        <select name="age" [ngModelOptions]="{standalone: true}" style="width:150px" class="form-select m-1"
                            placeholder="Select Age" *ngFor="let a of counter(travelersForm.value.children);let b = index"  [(ngModel)]="selectedAge[b]" (change)="onChange(b)">
                            <option value="0" disabled selected>Select Age
                            </option>
                            <option selected *ngFor="let option of counter(11);let p = index" [value]="p+1">
                                {{p+1}}
                            </option>
                        </select>
                    </div>
                    <label class="mt-1" *ngIf="travellersInvalid" style="color: red;">The total number of passengers
                        should not
                        exceed 9.</label>
                    <label class="mt-2">ROOMS</label>
                    <div class="input-group" style="width: 150px;">
                        <div (click)="roomsDecrement()" class="input-group-text"><i class="fas fa-minus"></i></div>
                        <input readonly style="width:30px;" type="text" class="form-control date" autocomplete="off"
                            formControlName="room"
                            [ngClass]="{'is-invalid':travelerssubmit && travelers.room.errors}" />
                        <div (click)="roomsIncrement()" class="input-group-text"><i class="fas fa-plus"></i></div>
                    </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light" (click)="closeModal()">Close</button>
            <button type="submit" class="btn btn-primary">Save Changes</button>
        </div>
    </form>
</ng-template>

<!-- Date range picker template -->
<ng-template #t let-date let-focused="focused">
    <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
        {{ date.day }}
    </span>
</ng-template>


<!-- sms Modal -->
<ng-template #otpmodel role="document" let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0">Enter the 6-digit code</h5>
        <button type="button" class="btn-close" aria-hidden="true" (click)="onCloseSmsModel()"></button>
    </div>
    <form (ngSubmit)="onOtpSubmit()" [formGroup]="optForm">
        <div class="modal-body">
            <p>Please enter the 6-digit code which was sent to you by SMS to verify your authenticity.</p>
            <div class="col-12 mb-3">
                <label for="horizontal-firstname-input" class="col-sm-6 col-form-label">Code<span class="r-star">
                        *</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" formControlName="smsCode"
                        [ngClass]="{'is-invalid': smsSubmit && smstype.smsCode.errors}" placeholder="123456" />
                    <div *ngIf="smsSubmit && smstype.smsCode.errors" class="invalid-feedback">
                        <span *ngIf="smstype.smsCode.errors.required">This value is
                            required.</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light" (click)="onCloseSmsModel()">Close</button>
            <button type="submit" class="btn btn-primary">Continue</button>
        </div>
    </form>
</ng-template>