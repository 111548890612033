<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                © inqbaytor Pty Ltd {{year}} | <span style="cursor: pointer;" (click)="onClickOnUserGuide()">User Guidelines</span> | <span style="cursor: pointer;" (click)="onClickOnPriceMatch()">Price Match Guarantee</span>
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-flex" style="justify-content: flex-start;">
                        <div class="avatar-xs">
                            <a href="https://www.facebook.com/ticketgadget" target="_blank" class="avatar-title rounded-circle bg-primary font-size-16">
                                <i class="mdi mdi-facebook text-white"></i>
                            </a>
                        </div>
                        <div class="avatar-xs">
                            <a href="https://www.linkedin.com/company/ticketgadget/" target="_blank" class="avatar-title rounded-circle bg-gradient-info font-size-16">
                                <i class="mdi mdi-linkedin text-white"></i>
                            </a>
                        </div>
                </div>
            </div>
        </div>
    </div>
</footer>