import { Location,LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ChangeContext, Options } from 'ng5-slider';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Subscription } from 'rxjs';
import { apiEndPointsCjw } from 'src/app/core/helpers/api-endpoints-cjw';
import { ApiCjwService } from 'src/app/core/services/apicjw.service';

@Component({
  selector: 'app-hotel-results',
  templateUrl: './hotel-results.component.html',
  styleUrls: ['./hotel-results.component.scss']
})
export class HotelResultsComponent implements OnInit {
  loading: Boolean = true;
  searchedData;
  searchResult;
  promoDetails;
  hotelFileters;
  promoPrices;
  hotelList;
  hotelSearchId;
  pIndex;
  sessionIndex;
  totalHotelCount;
  hotelCode;
  promoImages = [];
  value: number = 0;
  highValue: number = 0;
  options: Options = {
    floor: 0,
    ceil: 0,
  };
  hotels = [];
  totalHotels = [];
  pricingHotelCode = [];
  priceLoading: Boolean = false;
  subscription: Subscription[] = [];
  currentPage = 1;
  accommodationFilter = [];
  boardFiler = [];
  mealFilter = [];
  pRange = {};
  roomFilter = [];
  starFilter = [];
  globalHotels = [];
  globalHotelCodes = [];
  selectedPromo = 1;
  selectedPromoIndex = 0;
  filterLoading = false;
  

  @Output() changeIndex = new EventEmitter<string>();
  @Output() changeStepFunction = new EventEmitter<string>();

  constructor(
    private spinner: NgxSpinnerService,
    private apiServiceCjw: ApiCjwService,
    private router: Router,
    private toastr: ToastrService,
    private location: Location,
    private http: HttpClient,
    private location1: LocationStrategy    
  ) {
    location.replaceState('hotel-bookings/hotel-results')
   }

  ngOnInit(): void {
    this.fetchResultData();
  }

  changeStep() {
    this.changeStepFunction.next('id');
  }

  /**
   * Star filter build
   * @param i 
   * @returns 
   */
  counter(i: number) {
    return new Array(i);
  }  

  /**
   * Get hotel search results
   */  
  fetchResultData(){
    this.loading = true;
    let data = JSON.parse(localStorage.getItem('hotel-search'));
    localStorage.setItem('hotel-search', JSON.stringify(data));
    this.searchedData = data;
    let searchApi =  this.apiServiceCjw.apiAuthPost(apiEndPointsCjw.getHotelSearchResults, data);
    let getPromo = this.apiServiceCjw.apiAuthGet(apiEndPointsCjw.getPromo);
    let availableApiList = [];
    availableApiList.push(searchApi);
    availableApiList.push(getPromo);
    // https://hotelsapi.crazyjets.com.au/api/getPromo
    forkJoin(availableApiList).subscribe(results=>{
      if(results[0]['code'] == 1) {
        this.searchResult = results[0]['response'];
        sessionStorage.setItem('fileName',this.searchResult.filename);
        sessionStorage.setItem('hotelSearchId',this.searchResult.hotel_search_id);
        sessionStorage.setItem('sessionIndex',this.searchResult.session_index);
        this.hotelCode = [];
        this.globalHotelCodes = [];
        for(var i = 0; i < this.searchResult['hotels'].length; i++) {
          this.hotelCode.push(this.searchResult['hotels'][i]['code']);
          this.globalHotelCodes.push(this.searchResult['hotels'][i]['code']);
        }
        this.value = this.searchResult.hotel_prices.min;
        this.highValue = this.searchResult.hotel_prices.max;
        this.options = {
          floor: this.searchResult.hotel_prices.min,
          ceil: this.searchResult.hotel_prices.max,
          translate: (value: number): string => {
            return this.searchResult.currency + value;
          },
        }

        this.hotels = [];
        this.totalHotels = [];
        this.globalHotels = [];
        this.globalHotels = this.searchResult.hotels;
        this.totalHotels =  this.searchResult.hotels;
        this.totalHotels.forEach((element,index) => {
          const data = this.searchResult.otherDetails[element.code]
          const latestTerminal = this.searchResult.lastTerminals[element.code]
          this.totalHotels[index]['other'] = data;
          this.totalHotels[index]['terminal'] = latestTerminal;
          this.globalHotels[index]['other'] = data;
          this.globalHotels[index]['terminal'] = latestTerminal;        
        });
        this.getOtherDetails();
      }else{
        this.toastr.error(results[0]['message'],"Error");
        this.changeStep()

      }

      ///if it conatains atleast one other api response
      if (results.length > 1) {
        let res1 = results[1];
        this.promoDetails = res1['response'];
        localStorage.setItem('promoDetails',JSON.stringify(this.promoDetails));
        this.selectedPromo = this.promoDetails[0].pid;
        this.getHotelPrices();
      }
    })
  }

  /**
   * Get hotel filter details and promo prices
   */
  getOtherDetails(){
    var data = {
      "hotel_code":this.hotelCode
    };
    var pricingData = {
      "hotelCode": this.pricingHotelCode,
      "hotel_search_id":this.searchResult.hotel_search_id,
      "promo_code": 1,
      "sessionIndex": this.searchResult.session_index,
      "type": "i",
      "source":"TG"
    }

    let hotelFilters =  this.apiServiceCjw.apiAuthPost(apiEndPointsCjw.hotelFilters, data);
    // let getPromoPrices = this.apiServiceCjw.apiAuthPost(apiEndPointsCjw.getPromoPrices, pricingData);
    let availableApiList = [];
    availableApiList.push(hotelFilters);
    // availableApiList.push(getPromoPrices);

    forkJoin(availableApiList).subscribe(results=>{
      if(results[0]['code'] == 1) {
        this.hotelFileters = results[0]['response'];
        this.hotels.forEach((element,index) => {
          const facilities = this.hotelFileters.selectedFacilities[element.code]
          this.hotels[index]['facilities'] = facilities;
        });
      }

      ///if it conatains atleast one other api response
      // if(results.length > 1) {
      //   let res1 = results[1];
      //   this.promoPrices = res1['response'];
      //   this.hotels.forEach((element,index) => {
      //     const data = this.promoPrices[element.code]
      //     this.hotels[index]['price'] = data;
      //   });
      // }

      this.loading = false;

    })    
  }

  /**
   * This api will get the hotel prices by set of 20
   */
  getHotelPrices(){
    this.priceLoading = true;
    let page = this.currentPage*20;
    this.hotels =  [];
    this.hotels = this.totalHotels.slice(page-20, page);
    this.pricingHotelCode = [];
    for(var i = 0; i < this.hotels.length; i++) {
      this.pricingHotelCode.push(this.hotels[i]['code']);
    }
    var pricingData = {
      "hotelCode": this.pricingHotelCode,
      "hotel_search_id":this.searchResult.hotel_search_id,
      "promo_code": this.selectedPromo,
      "sessionIndex": this.searchResult.session_index,
      "type": "i",
      "source":"TG"
    }

    this.subscription.push(this.apiServiceCjw.apiAuthPost(apiEndPointsCjw.getPromoPrices, pricingData).subscribe(res => {
        if(res.code == 1){
          this.promoPrices = res['response'];
          this.hotels.forEach((element,index) => {
            const data = this.promoPrices[element.code]
            this.totalHotels[(page-20)+index]['price'] = data;
          });
          this.priceLoading = false;
        }else{
          this.toastr.error(res.message,"Error")
        }
      }, error => {
    })
    )
  }


  /**
   * On pagination changed
   * @param event 
   */
  pageChanged(event){
    console.log(event);
    this.currentPage = event;
    this.getHotelPrices();
  }

  //default star selected
  getStarCodeStates(key) {
    if (this.accommodationFilter.includes(key)) {
      return true;
    } else {
      return false;
    }
  }  

  //default accomodation selected
  getaccommodationCodeStates(key) {
    if (this.accommodationFilter.includes(key)) {
      return true;
    } else {
      return false;
    }
  }

  //default facilities selected
  getfacilitiesCodeState(key) {
    if (this.roomFilter.includes(key)) {
      return true;
    } else {
      return false;
    }
  }  

  //default facilities selected
  getamealCodeState(key) {
    if (this.mealFilter.includes(key)) {
      return true;
    } else {
      return false;
    }
  }    

  //default facilities selected
  getaboardCodeState(key) {
    if (this.boardFiler.includes(key)) {
      return true;
    } else {
      return false;
    }
  }  

  /**
   * Get price filter
   */
   getPriceFilter(e:ChangeContext){
    this.pRange = {
      "maxValue":e.highValue,
      "minValue":e.value
    }
    this.filterHotelData();
   }

  /**
   * 
   * @param key 
   Accomodation filter by key
   */
   starChange(key){
    if(this.starFilter.includes(key)){
      const index: number = this.starFilter.indexOf(key);
      this.starFilter.splice(index, 1);
    }else{
      this.starFilter.push(key)
    }
    this.filterHotelData();
  }


  /**
   * 
   * @param key 
   Accomodation filter by key
   */
   accommodationChange(key){
    if(this.accommodationFilter.includes(key)){
      const index: number = this.accommodationFilter.indexOf(key);
      this.accommodationFilter.splice(index, 1);
    }else{
      this.accommodationFilter.push(key)
    }
    this.filterHotelData();
  }

  /**
   * 
   * @param key 
   * Meal Key by filter
   */
   mealChange(key){
    if(this.mealFilter.includes(key)){
      const index: number = this.mealFilter.indexOf(key);
      this.mealFilter.splice(index, 1);
    }else{
      this.mealFilter.push(key)
    }
    this.filterHotelData();
  }

  /**
   * 
   * @param key 
   * Get facilities filter by key
   */
   facilitiesChange(key){
    if(this.roomFilter.includes(key)){
      const index: number = this.roomFilter.indexOf(key);
      this.roomFilter.splice(index, 1);
    }else{
      this.roomFilter.push(key)
    }
    this.filterHotelData();
  }

  /**
   * 
   * @param key 
   * Get board filter by key
   */   
   boardChange(key){
    if(this.boardFiler.includes(key)){
      const index: number = this.boardFiler.indexOf(key);
      this.boardFiler.splice(index, 1);
    }else{
      this.boardFiler.push(key)
    }  
    this.filterHotelData(); 
  }

  /**
   * Filter hotel data
   */
  filterHotelData(){
    this.currentPage = 1;
    this.filterLoading = true;
    var data = {
      "accommodation": this.accommodationFilter,
      "board":this.boardFiler,
      "hotel_search_id":this.searchResult.hotel_search_id,
      "meal":this.mealFilter,
      "pRange":this.pRange,
      "room":this.roomFilter,
      "sessionIndex": this.searchResult.session_index,
      "star": this.starFilter
    }
    this.subscription.push(this.apiServiceCjw.apiAuthPost(apiEndPointsCjw.getFilterdData, data).subscribe(res => {
        if(res.code == 1){
          this.filterLoading = true;
          let filteredHotels = res.response;
          let tempFilterdHotels = [];
          filteredHotels.forEach(element => {
            let item = this.globalHotels.find(i=>i.code == element)
            tempFilterdHotels.push(item);
          });
          this.totalHotels = [];
          this.totalHotels = tempFilterdHotels;
          this.filterLoading = false;
          this.getHotelPrices();
        }else{
          this.toastr.error(res.message,"Error")
        }
      }, error => {
    })
    )    
  }

  /**
   * 
   */
   onPromo(id,i){
    this.selectedPromo = id;
    this.selectedPromoIndex = i;
    this.getHotelPrices();
   }

   /**
    * On see rooms 
    */
   onSeeRooms(searchResult){
    sessionStorage.setItem('selectedHotel',searchResult);
   }


   /**
    * This will send the seleccted hotel data to step 3
    */
    onResultSelect(hotelCode){
      var steps = {
        "step1": true,
        "step2": true,
        "step3": false,
        "step4": false,
        "step5": false
      }
      sessionStorage.setItem('steps-completed', JSON.stringify(steps))
      sessionStorage.setItem('step', '3');
      sessionStorage.setItem('activeStep', '3');
      sessionStorage.setItem('step3Response', null);
      this.changeIndex.next();   
      // this.router.navigateByUrl('hotel-bookings/hotel-book');
      localStorage.setItem('selectedHotelCode',hotelCode);
    }
}
